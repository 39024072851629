<template>
  <div class="gridmap vh-100" style="overflow-y: auto">
    <TabMenu
        :model="itemsTab"
        class="mt-4 bg-white rounded"
        :pt="{action: { class: 'text-decoration-none', style: {color: 'black'} }}"
        :active-index="this.activeTabIndex"
        @tab-change="($event) => { this.activeTabIndex = $event.index }"
    />

    <div class="mt-4">
      <div v-if="this.activeTabIndex === 0">
        <div class="">
          <div class="" v-for="integration in this.availableIntegrations" :key="integration.uuid">
            <div class="custom-border bg-white p-2 rounded mb-4">
              <h5 class="fw-bold">{{integration.name}}</h5>

              <p class="text-break text-muted">{{ integration.description }}</p>

              <div class="d-flex justify-content-end flex-wrap">
                <router-link :to="{name: integration.route_name, params: {integration_type: integration.type}}">
                  <Button
                      label="Подключить"
                      icon="pi pi-check"
                      class="rounded"
                      severity="secondary"
                  />
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="this.activeTabIndex === 1">

        <div v-for="(integration, integrationIndex) in this.usingIntegrations" class="bg-white p-2 rounded mb-2">
          <h5 class="fw-bold">{{ integration.name }}</h5>
          <p class="text-muted">{{ integration.type_print }}</p>

          <div class="d-flex justify-content-end">
            <router-link :to="{name: 'update_integration', params: {integration_uuid: integration.uuid, scenario_uuid: this.scenario_uuid}}">
              <Button
                  severity="secondary"
                  label="Перейти"
                  class="rounded m-1"
                  icon="pi pi-file-edit"
              />
            </router-link>

            <Button
                severity="danger"
                class="rounded m-1"
                icon="pi pi-trash"
                label="Удалить"
                outlined
                @click="() => {
                   this.selectIntegrationIndex = integrationIndex
                }"
            />
          </div>
        </div>

      </div>
    </div>

    <Dialog v-model:visible="this.isShowDialogRemoveIntegration" header="Вы уверены что хотите удалить интеграцию?" modal :draggable="false">
      <Button
        label="Удалить!"
        severity="danger"
        class="rounded"
        :loading="this.isRemoving"
        @click="() => {
          this.isRemoving = true

          removeIntegration(this.usingIntegrations[this.selectIntegrationIndex].uuid)
            .then(() => {
              this.usingIntegrations.splice(this.selectIntegrationIndex, 1)
              this.selectIntegrationIndex = null
            })
            .finally(() => {this.isRemoving = false})
        }"
      />
    </Dialog>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import TabMenu from "primevue/tabmenu";
import Button from "primevue/button";
import Card from "primevue/card";
import Divider from "primevue/divider";
import {
  integrationGetForList,
  IntegrationItem,
  IntegrationType,
  removeIntegration
} from "@/api/integration/integration";
import Dialog from "primevue/dialog";

export default defineComponent({
  name: "IntegrationGetForList",
  methods: {removeIntegration},
  components: {
    TabMenu,
    Button,
    Card,
    Divider,
    Dialog,
  },
  props: {
    scenario_uuid: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      activeTabIndex: 0,
      itemsTab: [
        { label: 'Доступные' },
        { label: 'Используемые' },
      ],

      availableIntegrations: [
        {
          name: 'Битрикс 24',
          icon: 'bx bx-calendar-event',
          description: 'CRM система для автоматизации бизнес процессов',
          route_name: 'create_integration',
          type: IntegrationType.bitrix24,
        },
      ],

      isRemoving: false,
      selectIntegrationIndex: null as number|null,
      usingIntegrations: [] as IntegrationItem[],
    }
  },
  created() {
    integrationGetForList(this.scenario_uuid).then((r) => {
      this.usingIntegrations = r.items
    })
  },
  computed: {
    isShowDialogRemoveIntegration: {
      get: function () {
        return this.selectIntegrationIndex !== null
      },
      set: function () {
        this.selectIntegrationIndex = null
      }
    }
  }
})
</script>