<template>
  <div>
    <div class="p-2 bg-white pb-4">
      <h5 class="fw-bold">Подключение к Битрикс24</h5>

      <div class="mt-4">
        <p class="m-0 required">Название подключения</p>
        <InputText v-model="this.card.name"/>
        <p v-if="this.errors['name']" class="p-error">{{this.errors['name']}}</p>
      </div>

      <div v-if="this.card instanceof Bitrix24IntegrationCommand">
        <div class="mt-2">
          <p class="m-0 required">Входящий вебхук</p>
          <InputText v-model="this.card.token" :disabled="this.card.uuid !== null"/>
          <p v-if="this.errors['token']" class="p-error">{{this.errors['token']}}</p>
        </div>
      </div>

      <div class="d-flex justify-content-end mt-2">
        <div class="me-1">
          <slot name="footer"/>
        </div>

        <Button
            label="Сохранить"
            icon="pi pi-check"
            class="rounded"
            severity="contrast"
            :loading="this.isLoadingSave"
            @click="() => {
              this.isLoadingSave = true

              const promise = this.card.uuid ?
                updateIntegration(this.card) :
                createIntegration(this.card)

              promise
                .then(() => { this.$emit('was-saved') })
                .finally(() => { this.isLoadingSave = false })
                .catch((error: ErrorResponse) => {
                  this.errors = error.getValidationErrors()
                })
            }"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import InputText from "primevue/inputtext";
import {
  CreateIntegration,
  createIntegration,
  updateIntegration,
  Bitrix24IntegrationCommand,
} from "@/api/integration/integration";
import Button from "primevue/button";
import {ErrorResponse} from "@/api/common";

export default defineComponent({
  name: "IntegrationForm",
  computed: {
    ErrorResponse() {
      return ErrorResponse
    },
    Bitrix24IntegrationCommand() {
      return Bitrix24IntegrationCommand
    }
  },
  methods: {updateIntegration, createIntegration},
  components: {
    InputText,
    Button,
  },
  props: {
    scenario_uuid: {
      type: String,
      required: true,
    },
    value: {
      required: true,
      type: Object as PropType<CreateIntegration>,
    },
  },
  data: function () {
    return {
      card: this.value,
      errors: [],
      isLoadingSave: false,
    }
  },
  emits: [
    'value:update',
    'was-saved',
  ],
  watch: {
    card: function (val) {
      this.$emit('value:update', val)
    },

    value: function (val) {
      this.card = val
    },
  },
})
</script>