import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "gridmap vh-100",
  style: {"overflow-y":"auto"}
}
const _hoisted_2 = { class: "mt-4" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "" }
const _hoisted_5 = { class: "custom-border bg-white p-2 rounded mb-4" }
const _hoisted_6 = { class: "fw-bold" }
const _hoisted_7 = { class: "text-break text-muted" }
const _hoisted_8 = { class: "d-flex justify-content-end flex-wrap" }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "bg-white p-2 rounded mb-2" }
const _hoisted_11 = { class: "fw-bold" }
const _hoisted_12 = { class: "text-muted" }
const _hoisted_13 = { class: "d-flex justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabMenu = _resolveComponent("TabMenu")!
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TabMenu, {
      model: _ctx.itemsTab,
      class: "mt-4 bg-white rounded",
      pt: {action: { class: 'text-decoration-none', style: {color: 'black'} }},
      "active-index": this.activeTabIndex,
      onTabChange: _cache[0] || (_cache[0] = ($event) => { this.activeTabIndex = $event.index })
    }, null, 8, ["model", "active-index"]),
    _createElementVNode("div", _hoisted_2, [
      (this.activeTabIndex === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.availableIntegrations, (integration) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "",
                  key: integration.uuid
                }, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("h5", _hoisted_6, _toDisplayString(integration.name), 1),
                    _createElementVNode("p", _hoisted_7, _toDisplayString(integration.description), 1),
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_router_link, {
                        to: {name: integration.route_name, params: {integration_type: integration.type}}
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Button, {
                            label: "Подключить",
                            icon: "pi pi-check",
                            class: "rounded",
                            severity: "secondary"
                          })
                        ]),
                        _: 2
                      }, 1032, ["to"])
                    ])
                  ])
                ]))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true),
      (this.activeTabIndex === 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.usingIntegrations, (integration, integrationIndex) => {
              return (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createElementVNode("h5", _hoisted_11, _toDisplayString(integration.name), 1),
                _createElementVNode("p", _hoisted_12, _toDisplayString(integration.type_print), 1),
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_router_link, {
                    to: {name: 'update_integration', params: {integration_uuid: integration.uuid, scenario_uuid: this.scenario_uuid}}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Button, {
                        severity: "secondary",
                        label: "Перейти",
                        class: "rounded m-1",
                        icon: "pi pi-file-edit"
                      })
                    ]),
                    _: 2
                  }, 1032, ["to"]),
                  _createVNode(_component_Button, {
                    severity: "danger",
                    class: "rounded m-1",
                    icon: "pi pi-trash",
                    label: "Удалить",
                    outlined: "",
                    onClick: () => {
                   this.selectIntegrationIndex = integrationIndex
                }
                  }, null, 8, ["onClick"])
                ])
              ]))
            }), 256))
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_Dialog, {
      visible: this.isShowDialogRemoveIntegration,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((this.isShowDialogRemoveIntegration) = $event)),
      header: "Вы уверены что хотите удалить интеграцию?",
      modal: "",
      draggable: false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Удалить!",
          severity: "danger",
          class: "rounded",
          loading: this.isRemoving,
          onClick: _cache[1] || (_cache[1] = () => {
          this.isRemoving = true

          _ctx.removeIntegration(this.usingIntegrations[this.selectIntegrationIndex].uuid)
            .then(() => {
              this.usingIntegrations.splice(this.selectIntegrationIndex, 1)
              this.selectIntegrationIndex = null
            })
            .finally(() => {this.isRemoving = false})
        })
        }, null, 8, ["loading"])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}