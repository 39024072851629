<template>
  <div class="p-2 rounded">
    <TabView>
    <TabPanel header="Настройка">
      <ForwarderForm
        :value="this.requestForwardUpdate"
        :scenario_uuid="this.scenario_uuid"
        @update:value="($event) => { this.requestForwardUpdate = $event }"
      />
    </TabPanel>
<!--    <TabPanel header="Фильтры">-->

<!--    </TabPanel>-->

<!--    <TabPanel header="Опубликованные">-->

<!--    </TabPanel>-->

<!--    <TabPanel header="Отклоненные">-->

<!--    </TabPanel>-->

<!--&lt;!&ndash;    todo: перенести в ForwarderList &ndash;&gt;-->
<!--    <TabPanel header="Журнал ">-->

<!--    </TabPanel>-->
  </TabView>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Button from "primevue/button";
import PickList from "primevue/picklist";
import {
  getForwarderForCard,
  GetPostingResult,
  SourceItemResult,
  UpdateForwardDialogCmd,
} from "@/api/forwarder";
import Dialog from "primevue/dialog";
import Badge from "primevue/badge";
import AutoComplete from "primevue/autocomplete";
import Checkbox from "primevue/checkbox";
import DialogTagPanel from "@/components/view/Forward/DialogTagPanel.vue";
import {DialogType} from "@/api/dialog";
import InputText from "primevue/inputtext";
import ForwarderForm from "@/components/view/Forward/ForwarderForm.vue";

export default defineComponent({
  name: "Forwarder",
  computed: {
    DialogType() {
      return DialogType
    }
  },
  components: {
    ForwarderForm,
    InputText,
    DialogTagPanel,
    AutoComplete,
    Dialog,
    Button,
    TabPanel,
    TabView,
    DataTable,
    Column,
    PickList,
    Badge,
    Checkbox,
  },
  props: {
    scenario_uuid: {
      required: true,
      type: String,
    },
    forwarder_uuid: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      isLoadingSaveSources: false,
      dialogs: [] as SourceItemResult[],
      requestForwardUpdate: new UpdateForwardDialogCmd(this.scenario_uuid, this.forwarder_uuid),
      isShowDialogRemoveForwarder: false,
    }
  },
  created() {
    getForwarderForCard(this.forwarder_uuid).then((result: UpdateForwardDialogCmd) => {
      this.requestForwardUpdate = result
    })
  },
})
</script>