import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "p-2 gridmap",
  style: {"overflow-y":"auto","height":"100vh"}
}
const _hoisted_2 = { class: "mt-4 bg-white p-2 rounded" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "d-inline-block fw-bold" }
const _hoisted_5 = { class: "mt-1" }
const _hoisted_6 = { class: "text-muted m-0" }
const _hoisted_7 = { class: "d-flex justify-content-end flex-wrap" }
const _hoisted_8 = {
  key: 0,
  class: "text-muted"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!
  const _component_HelpInfo = _resolveComponent("HelpInfo")!
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Breadcrumbs, {
      items: this.breadcrumbs,
      class: "bg-white"
    }, null, 8, ["items"]),
    _createVNode(_component_HelpInfo, {
      "route-doc": null,
      class: "mt-3"
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Здесь отображаются Telegram группы в которых присутствует бот, если группа не отображается - напишите в ней что нибудь, бот увидит сообщение и отобразит группу. ")
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.items, (group, groupIndex) => {
        return (_openBlock(), _createElementBlock("div", {
          key: group.uuid,
          class: "content-group"
        }, [
          _createElementVNode("div", null, [
            (group.icon_image)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: group.icon_image,
                  height: "60px",
                  class: "rounded-circle me-2",
                  alt: ""
                }, null, 8, _hoisted_3))
              : _createCommentVNode("", true),
            _createElementVNode("h6", _hoisted_4, _toDisplayString(group.name), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("p", _hoisted_6, "Триггеров: " + _toDisplayString(group.triggers_count), 1)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_router_link, {
              to: {name: 'group_card', params: {
                  scenario_uuid: this.scenario_uuid,
                  dialog_uuid: group.uuid,
                }
              }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Button, {
                  icon: "pi pi-pencil",
                  label: "Открыть",
                  severity: "secondary",
                  outlined: "",
                  class: "m-1 rounded"
                })
              ]),
              _: 2
            }, 1032, ["to"]),
            _createVNode(_component_Button, {
              icon: "pi pi-sign-out",
              label: "Выйти",
              loading: this.leaveDialogUuid === group.uuid,
              severity: "warning",
              outlined: "",
              class: "m-1 rounded",
              onClick: () => {
                    this.leaveDialogUuid = group.uuid

                    _ctx.leaveGroup(group.uuid)
                      .then(() => { this.items.splice(groupIndex, 1) })
                      .finally(() => { this.leaveDialogUuid = null })
                  }
            }, null, 8, ["loading", "onClick"])
          ])
        ]))
      }), 128)),
      (this.items.length === 0)
        ? (_openBlock(), _createElementBlock("p", _hoisted_8, "Список пуст"))
        : _createCommentVNode("", true)
    ])
  ]))
}