<template>
  <div class="gridmap">
    <div class="container pt-4">
      <!-- Описание сервиса -->
      <div class="bg-white p-4 rounded">
        <div class="text-center">
          <h3 class="mb-2">
            <span class="fw-bold">Ninja-bot</span> - бесплатный конструктор <span class="">Telegram ботов</span> без рекламы</h3>
        </div>

        <div class="text-center mt-4">

          <!--       todo: Если уже есть валидный токен, то сразу направлять на главную страницу   -->

          <router-link :to="{name: 'bots_list'}">
            <Button
                label="Личный кабинет"
                icon="pi pi-home"
                class="rounded m-1"
            />
          </router-link>

          <router-link :to="{name: 'doc_get_started'}">
            <Button
                label="Документация"
                class="rounded m-1"
                icon="pi pi-book"
                severity="secondary"
            />
          </router-link>

          <a href="https://t.me/ninja_bot_ru"  target="_blank">
            <Button
                label="Техническая поддержка"
                class="rounded m-1"
                icon="pi pi-telegram"
                severity="secondary"
            />
          </a>

          <p class="text-muted">Если вам не хватает какой-то функции, напишите нам в тех.поддержку, я обязательно ее рассмотрю</p>
        </div>
      </div>

      <div class="mt-4">
        <div class="bg-light pt-lg p-4 rounded">
          <h5 class="text-center fw-bold">Почему сервис бесплатный и нет рекламы?</h5>
          <Divider/>
          <div class="container">
            <div class="row">
              <p>
                Сервис находится в процессе разработки, допиливаются старые функции, вводятся новые, идет набор аудитории.
                Сейчас абсолютно все функции бесплатные. Функции которые в будущем будут как-то лимитированны для бесплатной версии будут помечаться определенной иконкой
                с поясняющим текстом что функционал станет платным.
              </p>
              <p>
                Сервис разрабатывается одним человеком, поэтому даже с вводом платного функционала - он будет дешевле аналогов.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Функции для Telegram групп -->
      <div class="mt-4">
        <div class="bg-light pt-lg p-4 rounded">
          <h5 class="text-center fw-bold">Функции для Telegram групп</h5>
          <Divider/>
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-lg-4 mb-2 mt-2 text-center" v-for="feature in this.featuresForTelegramGroups">
                <div>
                  <span :class="feature.icon" class="me-3"/>
                  <h5 class="d-inline fw-bold">{{ feature.name }}</h5>
                </div>
                <p class="mt-2 text-muted">{{ feature.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Функции для Telegram каналов -->
      <div class="mt-4">
        <div class="bg-light pt-lg p-4 rounded">
          <h5 class="text-center fw-bold">Функции для Telegram каналов</h5>
          <Divider/>
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-lg-4 mb-2 mt-2 text-center" v-for="feature in this.featuresForTelegramChannels">
                <div>
                  <span :class="feature.icon" class="me-3"/>
                  <h5 class="d-inline fw-bold">{{ feature.name }}</h5>
                </div>
                <p class="mt-2 text-muted">{{ feature.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Функции для приватных чатов Telegram -->
      <div class="mt-4">
        <div class="bg-light pt-lg p-4 rounded">
          <h5 class="text-center fw-bold">Режимы работы бота в личных сообщениях</h5>
          <Divider/>
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-lg-4 mb-2 mt-2 text-center" v-for="feature in this.featuresForTelegramBots">
                <div>
                  <span :class="feature.icon" class="me-3"/>
                  <h5 class="d-inline fw-bold">{{ feature.name }}</h5>
                </div>
                <p class="mt-2 text-muted">{{ feature.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Видео -->
      <div class="text-center mt-4">
        <iframe style="width: 100%" height="500 " src="https://www.youtube.com/embed/Al3zjTThLr4?si=5XLpRAyCdPrcio65?"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>

      <!--  Примеры использования бота, ссылки на реальных ботов  -->
      <div class="mt-4 p-4 bg-white">
        <img src="/scenario.png" style="width: 100%" class="p-4" alt="">
      </div>

    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {useAuthStorage} from "@/store";
import Menubar from "primevue/menubar";
import InputText from "primevue/inputtext";
import Avatar from "primevue/avatar";
import Badge from "primevue/badge";
import Button from "primevue/button";
import Divider from "primevue/divider";

export default defineComponent({
  name: 'HomePage',
  components: {
    Menubar,
    Avatar,
    InputText,
    Badge,
    Button,
    Divider,
  },
  data: function () {
    return {
      featuresForTelegramGroups: [
        {
          name: 'Периодические сообщения',
          icon: 'bx bx-calendar-event',
          description: 'Периодическая отправка сообщений в чат, например правил сообщества',
        },
        {
          name: 'Команды ручной модерации',
          icon: 'bx bx-check-shield',
          description: 'Используйте /kick, /ban, /mute, /mute_media и т.д для быстрой ликвидации нарушителя',
        },
        {
          name: 'Капча',
          icon: 'pi pi-shield',
          description: 'Отсеивайте ботов с помощью разных видов капчи - эмодзи, текст с картинки, проверка подписки на каналы',
        },
        {
          name: 'Приветствие и прощание',
          icon: 'pi pi-sign-in',
          description: 'Приветствие новых и прощание с старыми участниками чата',
        },
        {
          name: 'Фильтры сообщений',
          icon: 'bx bx-block',
          description: 'Защита от распространение ссылок, telegram ссылок, флуда, использование мата',
        },
        {
          name: 'Прием заявок на вступление',
          icon: 'pi pi-check',
          description: 'Бот автоматически примет всех пользователей в закрытую группу',
        },
        {
          name: 'Триггеры',
          icon: 'bx bx-check-shield',
          description: 'Задайте собственные условия по которым бот выполнит необходимые действия, например по наличию в сообщении "ищу работу" - выгонять из чата',
        },
        {
          name: 'Статистика',
          icon: 'pi pi-chart-bar',
          description: 'Анализируйте количество пришедших и ушедших подписчиков',
        },
        {
          name: 'Часы работы',
          icon: 'pi pi-chart-pie',
          description: 'Закрывайте и открывайте чат во расписанию',
        },
      ],

      featuresForTelegramChannels: [
        {
          name: 'Отложенные публикации',
          icon: 'bx bx-calendar-event',
          description: 'Настройте дату публикации, нужно ли закреплять пост, когда открепить, через сколько удалить, куда переслать, количество повторов',
        },
        {
          name: 'Очереди публикаций',
          icon: 'pi pi-calendar-times',
          description: 'Задайте параметры периодичности публикации, система сама рассчитает когда необходимо выпустить следующий пост из очереди, для равномерного выхода контента',
        },
        {
          name: 'Перенаправление контента',
          icon: 'pi pi-arrow-right-arrow-left',
          description: 'Пересылайте контент в свой канал',
        },
      ],

      featuresForTelegramBots: [
        {
          name: 'Встроенный конструктор ботов',
          icon: 'pi pi-cog',
          description: 'Создайте бота который будет вести себя именно так как вы запланировали используя наш no-code графический редактор',
        },
        {
          name: 'Бот обратной связи',
          icon: 'pi pi-send',
          description: 'Принимайте и отвечайте на обращения клиентов через бота, не раскрывая личностей менеджеров',
        },
        {
          name: 'Рассылка',
          icon: 'pi pi-envelope',
          description: 'Производите рассылку по заготовленной базе пользователей',
        },
      ]
    }
  }
})
</script>