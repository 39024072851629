<template>
  <div class="p-2 vh-100 gridmap" style="overflow-y: auto;">
    <Breadcrumbs :items="this.breadcrumbs" class="bg-white"/>

    <TabMenu
        :model="this.itemsTab"
        class="mt-4 bg-white rounded"
        :pt="{action: { class: 'text-decoration-none', style: {color: 'black'} }}"
        :active-index="this.activeTabIndex"
        @tab-change="($event) => { this.activeTabIndex = $event.index }"
    />

    <div v-if="this.activeTabIndex === 0">
      <div class="mt-2 p-2 bg-light rounded" v-for="(command, index) in this.card.user_commands">
        <BotCommandItem
            :item="this.card.user_commands[index]"
            @update:item="($event) => { this.card.user_commands[index] = $event }"
        />
      </div>
    </div>

    <div v-if="this.activeTabIndex === 1">
      <div class="mt-2 p-2 bg-light rounded" v-for="(command, index) in this.card.admin_commands">
        <BotCommandItem
            :item="this.card.admin_commands[index]"
            @update:item="($event) => { this.card.admin_commands[index] = $event }"
        />
      </div>
    </div>

    <div class="d-flex justify-content-end m-3">
      <Button
        label="Сохранить"
        severity="secondary"
        class="rounded"
        :loading="this.isLoadingSave"
        @click="() => {
          this.isLoadingSave = true

          updateCommandsSettingsCard(this.card)
            .finally(() => { this.isLoadingSave = false })
        }"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import Breadcrumbs from "@/components/common/Breadcrumbs.vue";
import BotCommandItem from "@/components/view/GroupComponents/BotCommands/BotCommandItem.vue";
import {CommandsSettingsGetForCard, getCommandsSettingsCard, updateCommandsSettingsCard} from "@/api/commandsSettings";
import Button from "primevue/button";
import FilterAction from "@/components/view/GroupComponents/FilterAction.vue";
import FilterActionWrapper from "@/components/view/GroupComponents/FilterActionWrapper.vue";
import Filter from "@/components/view/GroupComponents/Filter.vue";
import {BreadcrumbItem} from "@/api/breadcrumbs";
import TabMenu from "primevue/tabmenu";

export default defineComponent({
  name: "CommandsSettings",
  methods: {updateCommandsSettingsCard},
  components: {
    TabMenu,
    Filter,
    FilterActionWrapper,
    FilterAction,
    BotCommandItem,
    Breadcrumbs,
    Button,
  },
  data: function () {
    return {
      breadcrumbs: [],
      card: new CommandsSettingsGetForCard(),
      isLoadingSave: false,

      activeTabIndex: 0,
      itemsTab: [
        { label: 'Команды для пользователей' },
        { label: 'Команды для админов' },
      ],
    }
  },
  props: {
    scenario_uuid: {
      required: true,
      type: String,
    },
    dialog_uuid: {
      required: true,
      type: String,
    },
  },
  created() {
    getCommandsSettingsCard(this.dialog_uuid).then((res) => {
      this.card = res

      this.breadcrumbs = [
        new BreadcrumbItem('Telegram группы', 'bot_groups', 'bot_groups', {scenario_uuid: this.scenario_uuid}),
        new BreadcrumbItem(res.dialog_name, '', 'group_card', {scenario_uuid: this.scenario_uuid, dialog_uuid: this.dialog_uuid}),
        new BreadcrumbItem('Команды управления ботом'),
      ]
    })
  },
})
</script>