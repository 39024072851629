import client from "./client";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import {plainToClass, plainToInstance, Type} from "class-transformer";
import {SearchDialogItem} from "./edge";


export class SourceItemResult
{
    uuid: string
    name: string
    link: string
}

export class GetPostingResult
{
    items: SourceItemResult[] = [];
}

export function getPostingSources(scenarioUuid: string, searchName: string|null): Promise<GetPostingResult>
{
    // @ts-ignore
    return client
        .post('/api/v1/forward/get-sources', {scenario_uuid: scenarioUuid, search_name: searchName})
        .then((response: AxiosResponse) => {
            return plainToClass(GetPostingResult, response.data);
        })
}

// принимаю более широкую структуру чтобы не играться с наследованием или урезанием интерфейса данной функции
export function updatePostingSources(cmd: GetPostingResult): Promise<GetPostingResult>
{
    // @ts-ignore
    return client
        .post('/api/v1/forward/update-sources', cmd)
        .then((response: AxiosResponse) => {
            return plainToClass(GetPostingResult, response.data);
        })
}

export function createForwarder(cmd: CreateForwardCmd): Promise<AxiosRequestConfig>
{
    return client.post('/api/v1/forward/create', cmd)
}

export class CreateForwardCmd
{
    scenario_uuid: string

    name: string
    active: boolean = false

    @Type(() => SearchDialogItem)
    sources: SearchDialogItem[] = [];

    @Type(() => SearchDialogItem)
    targets: SearchDialogItem[] = [];

    copy: boolean = true

    constructor(scenario_uuid: string) {
        this.scenario_uuid = scenario_uuid;
    }
}

export class ForwardedForList
{
    items: ForwardedForListItem[] = []
}

export class ForwardedForListItem
{
    uuid: string
    name: string
    link: string
    active: boolean
}

export function getForwarderItemsForList(scenarioUuid: string): Promise<ForwardedForList>
{
    return client
        .post('/api/v1/forward/get-for-list', {scenario_uuid: scenarioUuid})
        .then((response: AxiosResponse) => {
            return plainToInstance(ForwardedForList, response.data);
        })
}

export class UpdateForwardDialogCmd extends CreateForwardCmd
{
    uuid: string

    constructor(scenario_uuid: string, uuid: string) {
        super(scenario_uuid);
        this.uuid = uuid;
    }
}

export function getForwarderForCard(forwarderUuid: string): Promise<UpdateForwardDialogCmd>
{
    return client
        .post('/api/v1/forward/get-for-card', {forwarder_uuid: forwarderUuid})
        .then((response: AxiosResponse) => {
            return plainToInstance(UpdateForwardDialogCmd, response.data);
        })
}

export function updateForwarderDialogs(updateCmd: UpdateForwardDialogCmd): Promise<AxiosRequestConfig>
{
    return client.post('/api/v1/forward/update', updateCmd);
}

export function removeForwarder(forwarderUuid: string): Promise<AxiosRequestConfig>
{
    return client.post('/api/v1/forward/remove', {forwarder_uuid: forwarderUuid});
}