<template>
  <div>
    <MailingForm
      :scenario_uuid="this.scenario_uuid"
      :mailing="this.mailing"
      @mailing:update="($event) => { this.mailing = $event }"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import {getMailingForCard, MailingUpdate} from "@/api/mailing";
import MailingForm from "@/components/view/Mailing/MailingForm.vue";

export default defineComponent({
  name: "MailingEdit",
  components: {
    MailingForm
  },
  props: {
    scenario_uuid: {
      type: String,
      required: true,
    },
    mailing_uuid: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      mailing: new MailingUpdate(this.scenario_uuid, this.mailing_uuid)
    }
  },
  created() {
    getMailingForCard(this.mailing_uuid)
        .then((r) => {
          this.mailing = r
        })
  }
})
</script>