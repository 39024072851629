<template>
  <div class="d-flex bd-highlight">
    <div class="bd-highlight w-25" >
      <div style="overflow-y: scroll; height: 100vh;">
        <div class="list-group rounded-0" >

          <div v-for="dialog in this.dialogs.dialogs" @click="this.selectDialog = dialog" class="m-2">
            <a class="list-group-item list-group-item-action rounded" :class="{ 'active text-white': this.currentDialogUuid == dialog.uuid, 'list-group-item-light': this.currentDialogUuid != dialog.uuid }">
              <div>
                <h6 class="mb-0 fw-bold">{{dialog.name}}</h6>
                <p class="font-italic mt-2 mb-2 text-muted">{{dialog.last_message}}</p>
                <small class="small font-weight-bold">{{dialog.last_message_time}}</small>
              </div>
            </a>
          </div>

        </div>
      </div>
    </div>

    <div class="w-100 bd-highlight"  style="background: linear-gradient(90deg, #413f3d, #5f5e5e);">
      <div class="" style="display: flex; flex-direction: column; height: 100vh;">
        <div
            class="p-3 text-white fw-bold text-center"
            v-show="this.selectDialog?.name"
            style="background-color: #0e1525A0"
        >
          {{this.selectDialog?.name}}
        </div>


        <div class="p-3" ref="message_scroll" style="flex-grow: 1; overflow-y: scroll;">
          <div v-for="message in this.messages.messages" :class="{ 'd-flex justify-content-end': !message.from_bot }">
            <div class="w-50 text-break">
              <div class="media-body ml-3">
                <div class="rounded py-2 px-3 mb-2" :class="{'bg-primary': message.from_bot, 'bg-light': !message.from_bot }">
<!--                  <img src="https://bootstrapious.com/i/snippets/sn-chat/avatar.svg" alt="user" height="50" class="rounded-circle">-->

                  <div class="text-small mb-0 mt-2 message-text" :class="{'text-white': message.from_bot, 'text-muted': !message.from_bot}">
                    {{message.message}}

                    <div v-for="file in message.files">
<!--  todo: из-за того что изображение выходит за пределы родительского блока (сообщения) - появляется горизонтальный скрол в диалоге                      -->
                      <Image
                          v-if="file.type == 'image'"
                          :src="file.link"
                          alt="Image"
                          height="300"
                          preview
                          :image-style="{maxWidth: '100%'}"
                      />
                    </div>
                  </div>
                </div>
                <p class="small text-muted">{{message.date}}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="p-1 d-flex" style="background-color: #0e1525A0" @keyup.enter="this.sendMessage">
          <Textarea
              placeholder="Сообщение"
              class="rounded p-2 flex-grow-1 bg-transparent text-white"
              :auto-resize="true"
              :rows="3"
              v-model="this.sendMsgContent"
              :disabled="this.currentDialogUuid === null"
          />

          <button
              class="pi pi-send border-0 bg-transparent text-white align-top"
              @click="this.sendMessage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import client, {getToken} from "@/api/client";
import {
  DialogForCardItem, DialogForListItem, FetchDialogFilter,
  getDialogForCard,
  getDialogForList,
  GetForCardResult,
  GetForListResult,
  sendMessage
} from "@/api/dialog";
import {defineComponent, PropType} from "vue";
import {Centrifuge} from "centrifuge";
import MultiSelect from 'primevue/multiselect';
import {fetchScenarioList, Scenario} from "@/api/scenario";
import {useAuthStorage} from "@/store";
import Image from 'primevue/image';
import Textarea from "primevue/textarea";
import Divider from "primevue/divider";
import {error} from "@/api/ToastService";

export default defineComponent({
  name: 'DialogList',
  components: {
    MultiSelect,
    Image,
    Textarea,
    Divider,
  },
  props: {
    dialogUuid: {
      type: [String, null],
      required: false,
      default: null,
    },
    scenario_uuid: {
      required: true,
      type: String,
    },
  },
  data: function () {
    return {
      selectedFilters: new FetchDialogFilter([this.scenario_uuid]),
      dialogs: new GetForListResult,
      selectedFilterByBots: [],

      // todo: удалить!
      currentDialogUuid: null as string|null,

      botsOptions: [] as Scenario[],
      messages: new  GetForCardResult,
      sendMsgContent: '',
      centrifugeClient: null as Centrifuge,

      selectDialog: null as DialogForListItem|null,
    }
  },
  methods: {
    getFilterOptions: function () {
      return [
        {
          code: 'unread',
          option: 'Отвеченные ботом',
        },
        {
          code: 'read',
          option: 'Не отвеченные ботом',
        },
        {
          code: 'need_attention',
          option: 'Требуется внимание модератора',
        },
      ]
    },

    // Открытие диалога
    openDialogCard: function (dialogUuid: string|null) {
      if (
          !dialogUuid ||
          this.currentDialogUuid === dialogUuid
      ) {
        return
      }

      this.currentDialogUuid = dialogUuid;
      const scrollMessages = this.$refs.message_scroll as Element;

      // todo: нужен механизм отображения загрузки
      getDialogForCard(dialogUuid).then((result: GetForCardResult) => {
        this.messages = result;

        this.$nextTick(function () {
          scrollMessages.scrollTo({top:  scrollMessages.scrollHeight});
        })
      })

      if (this.centrifugeClient.getSubscription('dialog.' + this.currentDialogUuid) !== null) {
        return
      }

      const sub = this.centrifugeClient.newSubscription("dialog." + this.currentDialogUuid);

      sub.on('publication',  (ctx) => {
        const isScrolledToBottom = Math.abs(scrollMessages.scrollHeight - scrollMessages.scrollTop - scrollMessages.clientHeight) < 1
        const data = ctx.data as DialogForCardItem;
        this.messages.messages.push(data)

        // todo: не самый оптимальный путь обновления, но пока сойдет
        this.dialogs.dialogs.forEach((dialog: DialogForListItem) => {
          if (dialog.uuid === data.dialog_uuid) {
            dialog.last_message_time = data.date
            dialog.last_message = data.message
          }
        })

        console.log('пришёл само сообщение по веб сокету ' + Date.now())

        if (isScrolledToBottom) {
          this.$nextTick(function () {
            scrollMessages.scrollTo({top:  scrollMessages.scrollHeight, behavior: 'smooth'});
          })
        }
      }).on('subscribing', function (ctx) {
        console.log(`subscribing: ${ctx.code}, ${ctx.reason}`);
      }).on('subscribed', function (ctx) {
        console.log('subscribed', ctx);
      }).on('unsubscribed', function (ctx) {
        console.log(`unsubscribed: ${ctx.code}, ${ctx.reason}`);
      }).subscribe();
    },

    sendMessage: function (event: KeyboardEvent) {
      // При зажиме шифта для перевода строки - не отправляем сообщение
      if (event.shiftKey) {
        return
      }

      if (!this.sendMsgContent) {
        return
      }

      const msg = this.sendMsgContent
      this.sendMsgContent = ''

      sendMessage(
          this.currentDialogUuid as string,
          msg,
      ).then((res) => {
        console.log('пришёл uuid сообщения ' + Date.now())
        console.log(res)
      })
      .catch((responseError) => {
        error(responseError.error.message, '', 5000)
      })
    },

    fetchDialogs: function () {
      getDialogForList(this.selectedFilters).then((result: GetForListResult) => {
        this.dialogs = result
      })
    },
  },
  created() {
    this.centrifugeClient = new Centrifuge(process.env.VUE_APP_BACKEND_WEBSOCKET, {
      token: this.authStore.getToken,
      getToken: getToken,
    });

    // todo: обработка ошибок
    this.centrifugeClient.on('connecting', function (ctx) {
      console.log(`connecting: ${ctx.code}, ${ctx.reason}`);
    }).on('connected', function (ctx) {
      console.log(`connected over ${ctx.transport}`);
    }).on('disconnected', function (ctx) {
      console.log(`disconnected: ${ctx.code}, ${ctx.reason}`);
    }).connect();

    if (this.centrifugeClient.getSubscription('dialog_was_created.' + this.authStore.getUserUuid) !== null) {
      return
    }

    const sub = this.centrifugeClient.newSubscription("dialog_was_created." + this.authStore.getUserUuid);

    sub.on('publication',  (ctx) => {
      const data = ctx.data as DialogForCardItem;
      this.dialogs.dialogs.push(data)

    }).on('subscribing', function (ctx) {
      console.log(`subscribing: ${ctx.code}, ${ctx.reason}`);
    }).on('subscribed', function (ctx) {
      console.log('subscribed', ctx);
    }).on('unsubscribed', function (ctx) {
      console.log(`unsubscribed: ${ctx.code}, ${ctx.reason}`);
    }).subscribe();
  },
  mounted() {
    this.openDialogCard(this.$route.query.dialog_uuid)
    this.fetchDialogs()

    fetchScenarioList().then((result: Scenario[]) => {
      this.botsOptions = result
    })
  },
  setup() {
    const authStore = useAuthStorage()
    return {authStore}
  },
  watch: {
    selectDialog: function (dialog: DialogForListItem|null) {
      const searchURL = new URL(window.location);
      searchURL.searchParams.set('dialog_uuid', dialog.uuid);
      window.history.pushState({}, '', searchURL);

      this.openDialogCard(dialog.uuid)
    },

    selectedFilters: {
      handler(newVal, oldVal) {
        const searchURL = new URL(window.location);
        searchURL.searchParams.delete('bot_uuids')

        newVal.bot_uuids.forEach((item: string) => {
          searchURL.searchParams.append('bot_uuids', item);
        })

        window.history.pushState({}, '', searchURL);
      },
      deep: true,
    },
  }
})
</script>

<style scoped>
.message-text {
  white-space: pre-wrap;
}
</style>
