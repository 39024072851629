import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_IntegrationForm = _resolveComponent("IntegrationForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (this.card !== null)
      ? (_openBlock(), _createBlock(_component_IntegrationForm, {
          key: 0,
          value: this.card,
          scenario_uuid: this.scenario_uuid
        }, {
          footer: _withCtx(() => [
            _createVNode(_component_Button, {
              label: "Обновить справочные данные",
              icon: "pi pi-refresh",
              class: "rounded",
              loading: this.isRefreshingDictionaryData,
              onClick: _cache[0] || (_cache[0] = () => {
              this.isRefreshingDictionaryData = true

              _ctx.pullDictionaryDataForIntegration(this.integration_uuid)
                .finally(() => { this.isRefreshingDictionaryData = false })
            })
            }, null, 8, ["loading"])
          ]),
          _: 1
        }, 8, ["value", "scenario_uuid"]))
      : _createCommentVNode("", true)
  ]))
}