<template>
  <div class="p-2">
    <SubscribersTable
      :list="this.subscribers"
      :scenario_uuid="this.scenario_uuid"
    />
  </div>
</template>

<script lang="ts">
import {getForCardSubscriber, getForListSubscribers, SubscriberForCard, SubscriberForListItem} from "@/api/subscriber";
import {defineComponent} from "vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import {fetchScenarioList, Scenario} from "@/api/scenario";
import MultiSelect from 'primevue/multiselect';
import SubscribersTable from "@/components/view/Subscriber/SubscribersTable.vue";

export default defineComponent({
  name: 'SubscribersLists',
  components: {
    SubscribersTable,
    MultiSelect,
    DataTable,
    Column,
    Button,
    Dialog,
  },
  props: {
    scenario_uuid: {
      required: true,
      type: String,
    },
  },
  data: function () {
    return {
      dialogVisible: false,
      dialogSubscriber: {
        isVisible: false as boolean,
        subscriber: null as SubscriberForCard|null,
        dialogUuid: null as string|null,
      },
      subscribers: [] as SubscriberForListItem[],
      selectedSubscriberUuidLoading: null as null|string,
    }
  },
  created() {
    getForListSubscribers(this.scenario_uuid).then((res) => {
      this.subscribers = res.items;
    })
  },
  methods: {
    showSubscriberCard: function(subscriber: SubscriberForListItem) {
      this.selectedSubscriberUuidLoading = subscriber.uuid

      getForCardSubscriber(subscriber.uuid, this.scenario_uuid)
          .then((subscriberForCard: SubscriberForCard) => {
            this.dialogSubscriber.subscriber = subscriberForCard
            this.dialogSubscriber.isVisible = true
          })
          .finally(() => {
            this.selectedSubscriberUuidLoading = null
          })
    },
    closeDialogSubscriber: function () {
      this.dialogSubscriber.isVisible = false
      this.dialogSubscriber.subscriber = null
    },
  }
})
</script>
