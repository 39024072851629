import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MailingForm = _resolveComponent("MailingForm")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_MailingForm, {
      scenario_uuid: this.scenario_uuid,
      mailing: this.mailing,
      "onMailing:update": _cache[0] || (_cache[0] = ($event) => { this.mailing = $event }),
      onSaved: _cache[1] || (_cache[1] = () => {
          this.$router.push({name: 'mailing'})
        })
    }, null, 8, ["scenario_uuid", "mailing"])
  ]))
}