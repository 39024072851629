<template>
    <div class="gridmap vh-100" style="overflow-y: auto">
      <div id="bots-list" class="bg-white p-2">
        <div class="d-flex justify-content-between flex-wrap m-2">
          <h5 class="m-0 text-break">Список ботов</h5>

          <div>
            <Button
                id="button-add-scenario"
                class="rounded"
                type="button"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                label="Добавить бота"
                severity="contrast"
                icon="pi pi-plus"
                @click="() => { this.isShowCreateModalWindow = true }"
            />
          </div>
        </div>

        <div v-for="bot in this.bot_list" :key="bot.uuid" class="content-group">
          <div>
            <h5 class="fw-bold d-inline-block align-middle me-2 text-break" >{{bot.name}}</h5>
            <Tag class="align-top" :value="bot.is_active ? 'Активен' : 'Не активен'" :severity="bot.is_active ? 'success' : 'danger'" />
          </div>

          <p class="text-muted text-break">{{bot.description}}</p>

          <div class="d-flex justify-content-end flex-wrap">
            <router-link :to="{name: 'about_bot', params: {scenario_uuid: bot.uuid}}">
              <Button icon="pi pi-pencil" label="Открыть" severity="contrast" outlined class="m-1 rounded"/>
            </router-link>

            <Button
                icon="pi pi-trash"
                label="Удалить"
                :loading="this.removeBotIsLoading"
                severity="danger"
                outlined
                class="rounded m-1"
                @click="() => {
                  this.dialogRemoveScenarioUuid = bot.uuid
                }"
            />
          </div>
        </div>

        <p v-if="this.bot_list.length === 0" class="text-muted">Нет добавленных ботов</p>
      </div>

      <Dialog v-model:visible="this.isShowDialogRemoveBot" header="Вы уверены что хотите удалить бота?" modal :draggable="false">
          <Button
              severity="danger"
              label="Уверен, удалить!"
              class="rounded"
              :loading="this.removeBotIsLoading"
              @click="this.removeBot(this.dialogRemoveScenarioUuid)"
          />
      </Dialog>

      <Dialog v-model:visible="this.isShowCreateModalWindow" header="Добавление бота в платформу" modal :draggable="false" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
        <div class="mt-2 mb-2">
          <HelpInfo>
            Необходимо добавить вашего Telegram бота в платформу, чтобы платформа могла им управлять.
            Как это сделать подробнее описываем в документации <router-link :to="{name: 'doc_how_to_add_bot'}" target="_blank">«Добавление бота»</router-link>
          </HelpInfo>

          <div class="mt-3">
            <p class="required m-0">Токен</p>
            <InputText v-model="this.formDataCreateBot.bot_token" class="me-2" id="bot_token" style="max-width: 80%"/>

            <a href="https://t.me/BotFather" target="_blank">
              <Button label="Получить токен" id="telegram-get-token" class="rounded"/>
            </a>

            <p class="p-error">{{ this.errors['bot_token'] }}</p>
          </div>

          <div class="mt-1">
            <p class="m-0">Описание</p>
            <InputText type="text" size="small" v-model="this.formDataCreateBot.description" style="max-width: 80%"/>
          </div>

          <div class="mt-2">
            <p class="m-0">Часовой пояс</p>
            <Dropdown
                v-model="this.formDataCreateBot.time_zone"
                option-label="label"
                option-value="value"
                :options="getTimeZones()"
                scroll-height="300px"
                id="time_zone"
            />
          </div>
        </div>

        <template #footer>
          <Button
              id="button-save-scenario"
              label="Создать"
              class="rounded"
              severity="contrast"
              :loading="this.createBotIsLoading"
              @click="this.createBot"
          />
        </template>
      </Dialog>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import {
  createScenario, CreateScenarioCommand,
  fetchScenarioList,
  getTimeZones,
  removeScenario,
  Scenario
} from '@/api/scenario'
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import Multiselect from "@vueform/multiselect";
import Dropdown from "primevue/dropdown";
import {ErrorResponse, } from "@/api/common";
import DataTable from "primevue/datatable";
import Tag from "primevue/tag";
import Column from "primevue/column";
import Vue3Tutorial from 'vue3-tutorial';
import {right} from "@popperjs/core";
import {InteractiveLessons, TutorialEnum} from "@/api/interactiveLessons";
import Menu from "primevue/menu";
import HelpInfo from "@/components/view/HelpInfo.vue";

export default defineComponent({
  name: 'BotList',
  components: {
    HelpInfo,
    Menu,
    Column, Tag, DataTable,
    Dropdown,
    Button,
    InputText,
    Dialog,
    Multiselect,
    Vue3Tutorial,
  },
  data: function () {
    return {
      openTutorial: true,

      bot_list: [] as Scenario[],
      isShowCreateModalWindow: false as boolean,
      formDataCreateBot: new CreateScenarioCommand(),

      items: [
        {
          label: 'Удалить',
          icon: 'pi pi-trash',
          command: function () {
            console.log('REFRESH PRESSED')
          }
        },
        {
          label: 'Создать копию',
          icon: 'pi pi-copy',
          command: function () {
            console.log('COPY PRESSED')
          },
        },
        {
          label: 'Информация',
          icon: 'pi pi-question-circle',
          command: function () {
            // todo: отобразить информацию о владельце бота, дате создания, количестве подписчиков и т.д
            // todo: подумать, точно ли нужно
          }
        },
        {
          label: 'Остановить',
          icon: 'pi pi-pause',
        },
        {
          label: 'Отредактировать',
          icon: 'pi pi-file-edit',
          // todo: поменять название бота (что ещё?)
        },
        {
          label: 'Перейти к боту в Telegram',
          icon: 'pi pi-telegram'
        }

      ],
      item: '',
      errors: [],
      createBotIsLoading: false,
      removeBotIsLoading: false,
      dialogRemoveScenarioUuid: null as null|string,
    }
  },
  mounted() {
    this.renderBotsList()
  },
  methods: {
    getTimeZones,
    renderBotsList: function () {
      fetchScenarioList().then((items) => {
        this.bot_list = items;
      })
    },

    createBot: function () {
      this.createBotIsLoading = true
      createScenario(this.formDataCreateBot).then(() => {
        this.renderBotsList()
        this.isShowCreateModalWindow = false;
        this.formDataCreateBot = new CreateScenarioCommand()
      }).catch((error: ErrorResponse) => {
        this.errors = error.getValidationErrors()
      })
      .finally(() => {
        this.createBotIsLoading = false
      });
    },

    // Удаление бота
    removeBot: function (scenarioUuid: string) {
      this.removeBotIsLoading = true
      removeScenario(scenarioUuid)
        .then(() => {
          this.bot_list.forEach((item, index) => {
            if (item.uuid === scenarioUuid) {
              this.bot_list.splice(index, 1)
              return
            }
          })
        })
        .finally(() => {
          this.removeBotIsLoading = false
          this.dialogRemoveScenarioUuid = null
        })
    },
    toggle: function (event: any) {
      // @ts-ignore
      this.$refs.menu[0].toggle(event)
    },
  },
  computed: {
    CreateScenarioCommand() {
      return CreateScenarioCommand
    },
    isShowDialogRemoveBot: {
      get: function (): boolean {
        return this.dialogRemoveScenarioUuid !== null
      },
      set: function (newVal) {
        if (newVal === false) {
          this.dialogRemoveScenarioUuid = null
        }
      }
    }
  },
  watch: {
    isShowCreateModalWindow: function (val: boolean) {
      if (val === false) {
        this.formDataCreateBot = new CreateScenarioCommand()
        this.errors = []
      }
    }
  }
})
</script>