import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-2 rounded" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ForwarderForm = _resolveComponent("ForwarderForm")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_TabView = _resolveComponent("TabView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TabView, null, {
      default: _withCtx(() => [
        _createVNode(_component_TabPanel, { header: "Настройка" }, {
          default: _withCtx(() => [
            _createVNode(_component_ForwarderForm, {
              value: this.requestForwardUpdate,
              scenario_uuid: this.scenario_uuid,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event) => { this.requestForwardUpdate = $event })
            }, null, 8, ["value", "scenario_uuid"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}