import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "m-0 fw-bold text-white text-center bg-transparent",
  style: {"min-height":"1.5em"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_NodeToolbar = _resolveComponent("NodeToolbar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_NodeToolbar, {
      "is-visible": this.selectedNodeUuid === this.nodeUuid,
      position: this.nodePosition
    }, {
      default: _withCtx(() => [
        _withDirectives(_createVNode(_component_Button, {
          icon: "pi pi-trash",
          severity: "danger",
          class: "rounded",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (this.$emit('pressed-remove')))
        }, null, 512), [
          [_vShow, !this.graphConfigStorage.isSharedTemplate]
        ])
      ]),
      _: 1
    }, 8, ["is-visible", "position"]),
    _createElementVNode("div", {
      class: "p-2 rounded-top text-white",
      style: _normalizeStyle({backgroundColor: this.color})
    }, [
      _createElementVNode("p", _hoisted_1, _toDisplayString(this.nodeName), 1)
    ], 4)
  ]))
}