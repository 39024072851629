<template>
  <div class="">
    <div>
      <h4 class="m-1">Ежедневный отчет присылаемый ботом</h4>

      <div class="mt-4">
        <div>
          <div>
            <InputSwitch class="me-2 align-middle" v-model="this.card.active"/>
            <span class="align-middle">Активность</span>
          </div>

          <div class="d-flex justify-content-between mt-1">
            <div>
              <div>
                <InputSwitch
                    class="me-2 align-middle"
                    v-model="this.sendReportToServiceGroup"
                    @update:model-value="($event) => {
                      if ($event) {
                        this.card.report_dialog = null
                      }
                    }"
                />
                <span class="align-middle">Отправлять отчет в сервисную группу</span>
              </div>

              <DialogTagPanel
                  class="mt-1"
                  v-show="this.sendReportToServiceGroup === false"
                  :dialog-types="[DialogType.group, DialogType.private, DialogType.channel]"
                  :scenario-uuid="this.scenario_uuid"
                  :model-value="this.reportDialog"
                  @model-value:update-value="($event) => { this.reportDialog = $event }"
                  :disabled="this.reportDialog.length > 0"
              />
            </div>

            <div>
              <p class="m-0 mt-1 required">Во сколько присылать отчет:</p>
              <TimeInput class="" v-model="this.card.send_report_at_time"/>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-3">
        <h5>Данные отображаемые в отчете</h5>

        <div class="content-group">
          <div class="mt-1">
            <InputSwitch class="me-2 align-middle" v-model="this.card.captcha_counter_success"/>
            <span class="align-middle">Количество прошедших капчу</span>
          </div>

          <div class="mt-1">
            <InputSwitch class="me-2 align-middle" v-model="this.card.captcha_counter_fail"/>
            <span class="align-middle">Количество непрошедших капчу</span>
          </div>

          <div class="mt-1">
            <InputSwitch class="me-2 align-middle" v-model="this.card.filter_counter_abuse"/>
            <span class="align-middle">Количество срабатываний фильтра мата</span>
          </div>

          <div class="mt-1">
            <InputSwitch class="me-2 align-middle" v-model="this.card.filter_counter_link"/>
            <span class="align-middle">Количество срабатываний фильтра ссылок</span>
          </div>

          <div class="mt-2">
            <p class="m-0">Количество срабатываний триггеров</p>

            <AutoComplete
                placeholder="Выберите триггер"
                dropdown
                :suggestions="this.triggersOptions"
                option-label="name"
                data-key="uuid"
                @complete="this.searchTrigger"
                @item-select="($event) => {
                  this.card.triggers.push($event.value)
                }"
            />

            <div class="mt-2">
              <Chip
                  v-for="(trigger, index) in this.card.triggers"
                  :label="trigger.name"
                  removable
                  class="me-1 mb-1"
                  :key="trigger.uuid"
                  @remove="() => {
                    this.card.triggers.splice(index, 1)
                  }"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end">
        <Button
            class="me-2 rounded"
            label="Сохранить"
            :loading="this.isLoading"
            severity="secondary"
            @click="() => {
              this.isLoading = true

              updateStatsReport(this.card)
                .finally(() => { this.isLoading = false })
            }"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import Chart from "primevue/chart";
import DateInput from "@/components/common/DateInput.vue";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Toolbar from "primevue/toolbar";
import {
  getGroupsForCard,
  getStatistics,
  GroupForCard,
  GroupsListItemResult,
  QueryGetStatistics,
  StatisticsResult
} from "@/api/group";
import {BreadcrumbItem} from "@/api/breadcrumbs";
import InputSwitch from "primevue/inputswitch";
import TimeInput from "@/components/common/TimeInput.vue";
import Chip from "primevue/chip";
import AutoComplete from "primevue/autocomplete";
import SearchDialog from "@/components/common/SearchDialog.vue";
import {getStatsReport, GroupStatsReportGetForCard, updateStatsReport} from "@/api/groupStatsReport";
import {getScenarioTriggerItemsForList, ScenarioTriggerForListItem} from "@/api/scenarioTrigger";
import {SearchDialogItem, SearchDialogRes} from "@/api/edge";
import DialogTagPanel from "@/components/view/Forward/DialogTagPanel.vue";
import {DialogType} from "@/api/dialog";
import Button from "primevue/button";

export default defineComponent({
  name: "GroupStatsReport",
  computed: {
    DialogType() {
      return DialogType
    },

    reportDialog: {
      set: function (val: SearchDialogItem[]) {
        if (val) {
          this.card.report_dialog = val[0]
        } else {
          this.card.report_dialog = null
        }
      },
      get: function () {
        if (this.card.report_dialog) {
          return [this.card.report_dialog]
        }

        return []
      },
    },
  },
  components: {
    Button,
    DialogTagPanel,
    SearchDialog,
    TimeInput,
    InputSwitch,
    Toolbar,
    DataTable,
    Column,
    DateInput,
    Chart,
    Chip,
    AutoComplete,
  },
  props: {
    scenario_uuid: {
      required: true,
      type: String,
    },
    dialog_uuid: {
      required: true,
      type: String,
    },
  },
  data: function () {
    return {
      card: new GroupStatsReportGetForCard(),
      triggers: [] as ScenarioTriggerForListItem[],
      triggersOptions: [] as ScenarioTriggerForListItem[],
      sendReportToServiceGroup: false,
      isLoading: false,
    }
  },
  created() {
    getScenarioTriggerItemsForList(this.dialog_uuid).then((r) => {
      this.triggers = r.items
    })

    getStatsReport(this.dialog_uuid).then((r) => {
      this.card = r
      this.sendReportToServiceGroup = this.card.report_dialog === null
    })
  },
  methods: {
    updateStatsReport,
    searchTrigger: function () {
      // Не отображаем уже выбранные триггеры
      const tmp = []
      this.triggers.forEach((item: ScenarioTriggerForListItem) => {
        const isExists = this.card.triggers.find((findItem: ScenarioTriggerForListItem) => {
          return findItem.uuid === item.uuid
        })

        if (!isExists) {
          tmp.push(item)
        }
      })

      this.triggersOptions = tmp
    }
  },
})
</script>