<template>
  <div class="text-center pb-4 rounded-top node_bg_custom" style="width: 600px;">
    <TitleNode
        :node-uuid="this.data.uuid"
        :node-name="this.data.name"
        :node-position="this.data.position"
        :items="this.items"
        color="#cf2a2a"
        @pressed-remove="() => { this.$emit('remove', this.data.uuid, true) }"
    />

    <div class="content-trigger-group">
      <CommandItem
          class="mt-3"
          v-for="(element, index) in this.data.commands"
          :key="element.uuid"
          :command_group_uuid="this.data.uuid"
          v-model="this.data.commands[index]"
          @pressed-button-remove="this.data.commands.splice(index, 1); this.isShowAddCommandButton = true"
          @completed-filled-command-item="this.isShowAddCommandButton = true"
          :read-only="this.readOnly"
          :context-options="this.contextOptions"
          :is-edit-mode="!this.readOnly"
          :command-options="this.graphConfigStorage.getCommandTypes"
          :read-context-options="[]"
      />
    </div>

    <SourceHandle/>
    <TargetHandle/>
  </div>
</template>

<script lang="ts">
import {Handle, Position} from "@vue-flow/core";
import CommandItem from "@/components/view/Bots/Command/CommandItem.vue";
import {defineComponent, PropType} from "vue";
import {CommandGroupNode} from "@/api/graph";
import TitleNode from "@/components/view/Bots/TitleNode.vue";
import EditableContent from "@/components/view/Bots/EditableContent.vue";
import {MenuItem} from "primevue/menuitem";
import TargetHandle from "@/components/view/Bots/TargetHandle.vue";
import SourceHandle from "@/components/view/Bots/SourceHandle.vue";
import {ContextItem, ContextItemForBuilder} from "@/api/context";
import {uuid} from "vue-uuid";
import Button from "primevue/button";
import {useGraphConfigStorage} from "@/stores/GraphConfigStorage";

export default defineComponent({
  name: 'CommandGroup',
  props: {
    data: {
      type: Object as PropType<CommandGroupNode>,
      required: true,
    },
    contextOptions: {
      required: true,
      type: Array as PropType<ContextItemForBuilder[]>,
    },
  },
  emits: [
    'remove',
  ],
  setup() {
    const graphConfigStorage = useGraphConfigStorage()
    return {graphConfigStorage}
  },
  computed: {
    Position() {
      return Position
    },

    items: function (): MenuItem[] {
      if (this.graphConfigStorage.isSharedTemplate) {
        return []
      }

      let result = [] as MenuItem[]

      if (this.readOnly) {
        result.push({
          label: 'Редактировать',
          icon: 'pi pi-file-edit',
          command: () => {
            this.readOnly = false
          },
        })
      }

      result.push({
        label: 'Удалить',
        icon: 'pi pi-trash',
        command: () => {
          this.$emit('remove', this.data.uuid, true)
        }
      })

      return result
    },
  },
  components: {
    Button,
    SourceHandle,
    TargetHandle,
    EditableContent,
    TitleNode,
    CommandItem,
    Handle,
  },
  data: function () {
    return {
      readOnly: true,
      isShowAddCommandButton: true,
    }
  },
  methods: {
    uuid() {
      return uuid
    },
  },
})
</script>
