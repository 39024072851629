import {SearchDialogItem} from "./edge";
import {ScenarioTriggerForListItem} from "./scenarioTrigger";
import {plainToInstance, Type} from "class-transformer";
import client from "./client";
import {AxiosRequestConfig, AxiosResponse} from "axios";


export class GroupStatsReportGetForCard
{
    dialog_uuid: string
    active: boolean = false

    @Type(() => SearchDialogItem)
    report_dialog: SearchDialogItem|null = null

    send_report_at_time: string = '20:00'

    captcha_counter_success: boolean = false
    captcha_counter_fail: boolean = false

    filter_counter_abuse: boolean = false
    filter_counter_link: boolean = false

    @Type(() => ScenarioTriggerForListItem)
    triggers: ScenarioTriggerForListItem[] = []
}

export async function updateStatsReport(cmd: GroupStatsReportGetForCard): Promise<AxiosRequestConfig> {
    return client
        .post('/api/v1/dialog/groups/statistics/report/update', cmd)
}

export async function getStatsReport(dialog_uuid: string): Promise<GroupStatsReportGetForCard> {
    return client
        .post('/api/v1/dialog/groups/statistics/report', {dialog_uuid: dialog_uuid})
        .then((response: AxiosResponse) => {
            return plainToInstance(GroupStatsReportGetForCard, response.data);
        })
}