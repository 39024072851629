<template>
  <div>
    <div class="text-start m-2 content-group">
      <div class="mt-2">
        <Dropdown
            :model-value="this.modelValueData.operation"
            @update:model-value="this.handleChangeOperation"
            v-model="this.modelValueData.operation"
            :options="this.getOperationTypes()"
            optionLabel="name"
            option-value="code"
            placeholder="Тип задержки"
        />
      </div>

      <div class="mt-2" v-show="this.modelValueData.operation == DelayNodeType.send_through_time">
        <InputNumber
            placeholder="Введите интервал задержки"
            class="mt-1"
            v-model="this.modelValueData.send_through_days"
        />
        <Dropdown
            :options="this.getSendThroughOptions()"
            optionLabel="name"
            option-value="code"
            class="mt-1"
            v-model="this.modelValueData.send_through_meter"
            scroll-height="500px"
        />
      </div>


      <div class="mt-2" v-show="this.modelValueData.operation == DelayNodeType.send_to_at_time">
        <p class="m-0">Время отправки:</p>
        <TimeInput
            v-model="this.modelValueData.send_at_time"
            class="w-100"
        />
      </div>

      <div class="mt-2" v-show="this.modelValueData.operation == DelayNodeType.specified_datetime">
        <p class="m-0">Дата:</p>
        <DateInput
            v-model="this.modelValueData.specified_date"
        />

        <p class="m-0 mt-2">Время отправки:</p>
        <TimeInput
           
            v-model="this.modelValueData.specified_time"
            :show-bar="true"
        />
      </div>


      <div class="" v-show="this.modelValueData.operation == DelayNodeType.dynamic_interval">
        <Dropdown
            v-model="this.modelValueData.dynamic_context_uuid"
            :options="this.contextOptionsForDelay"
            optionLabel="name"
            option-value="uuid"
            placeholder="Переменная"
            class="mt-1"
        />

        <!--          todo: computed сетер и гетер -->
<!--        <SelectButton-->
<!--            v-model="this.modelValueData.dynamic_offset"-->
<!--            class="mt-1"-->
<!--            :options="['+', '-']"-->
<!--           -->
<!--        />-->
        <Dropdown
            v-model="this.modelValueData.dynamic_time_unit_enum"
            :options="this.getDynamicUnitOptions()"
            optionLabel="name"
            option-value="code"
            placeholder="Размерность"
        />
      </div>

      <div class="mt-2">
        <InputSwitch
            v-model="this.modelValueData.print_effect"
            class="me-2 align-middle"
        />
        <span class="align-middle">Эффект печати</span>
      </div>

      <div class="mt-4 mb-2" v-show="this.isShowDaysOfWeek">
        <div class="text-center">
          <div class="d-inline-block me-2">
            <p class="m-0">Пн</p>
            <Checkbox :binary="true" v-model="this.modelValue.day1"/>
          </div>

          <div class="d-inline-block me-2">
            <p class="m-0">Вт</p>
            <Checkbox :binary="true" v-model="this.modelValue.day2"/>
          </div>

          <div class="d-inline-block me-2">
            <p class="m-0">Ср</p>
            <Checkbox :binary="true" v-model="this.modelValue.day3"/>
          </div>

          <div class="d-inline-block me-2">
            <p class="m-0">Чт</p>
            <Checkbox :binary="true" v-model="this.modelValue.day4"/>
          </div>

          <div class="d-inline-block me-2">
            <p class="m-0">Пт</p>
            <Checkbox :binary="true" v-model="this.modelValue.day5"/>
          </div>

          <div class="d-inline-block me-2">
            <p class="m-0">Сб</p>
            <Checkbox :binary="true" v-model="this.modelValue.day6"/>
          </div>

          <div class="d-inline-block me-2">
            <p class="m-0">Вс</p>
            <Checkbox :binary="true" v-model="this.modelValue.day7"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import FilesViewer from "@/components/common/FilesViewer.vue";
import SearchDialogMultiple from "@/components/common/SearchDialogMultiple.vue";
import ButtonGroupPanel from "@/components/view/ButtonGroupPanel.vue";
import RichTextEditor from "@/components/view/RichTextEditor.vue";
import Image from "primevue/image";
import Button from "primevue/button";
import Accordion from "primevue/accordion";
import Checkbox from "primevue/checkbox";
import SelectButton from "primevue/selectbutton";
import FileUpload from "primevue/fileupload";
import AccordionTab from "primevue/accordiontab";
import TimeInput from "@/components/common/TimeInput.vue";
import DateInput from "@/components/common/DateInput.vue";
import InputNumber from "primevue/inputnumber";
import Dropdown from "primevue/dropdown";
import {DelayBlockNode, DelayNodeType, TimeUnit} from "@/api/graph";
import {updateDelayNode} from "@/api/delayNode";
import {useGraphConfigStorage} from "@/stores/GraphConfigStorage";
import InputSwitch from "primevue/inputswitch";

export default defineComponent({
  name: "DelayNodeEdit",
  computed: {
    DelayNodeType() {
      return DelayNodeType
    },

    isShowDaysOfWeek() {
      if (this.modelValueData.operation === null) {
        return false
      }

      return [DelayNodeType.send_to_at_time, DelayNodeType.send_through_time].includes(this.modelValueData.operation)
    },
  },
  components: {
    Dropdown, InputNumber, DateInput, TimeInput,
    FilesViewer,
    SearchDialogMultiple,
    ButtonGroupPanel,
    RichTextEditor,
    Image,
    Button,
    Accordion,
    Checkbox,
    SelectButton,
    FileUpload,
    AccordionTab,
    InputSwitch,
  },
  props: {
    modelValue: {
      required: true,
      type: Object as PropType<DelayBlockNode>,
    },
  },
  inject: ['scenario_uuid', 'contextOptionsForDelay'],
  emits: [
    'update:modelValue',
  ],
  data: function () {
    return {
      modelValueData: this.modelValue,
    }
  },
  watch: {
    modelValueData: {
      handler(newVal, oldVal) {
        this.$emit('update:modelValue', newVal)
      },
      deep: true,
    },
    modelValue: function (val) {
      this.modelValueData = val
    }
  },
  setup() {
    const graphConfigStorage = useGraphConfigStorage()
    return {graphConfigStorage}
  },
  methods: {
    getOperationTypes: function () {
      return [
        {
          name: 'Определённая дата',
          code: DelayNodeType.specified_datetime,
        },
        {
          name: 'Определённое время',
          code: DelayNodeType.send_to_at_time,
        },
        {
          name: 'Рассчитать задержку от переменной',
          code: DelayNodeType.dynamic_interval,
        },
        {
          name: 'Отправить через',
          code: DelayNodeType.send_through_time,
        }
      ]
    },

    getSendThroughOptions: function () {
      return [
        {
          name: 'Дней',
          code: TimeUnit.day,
        },
        {
          name: 'Часов',
          code: TimeUnit.hour,
        },
        {
          name: 'Минут',
          code: TimeUnit.minute,
        },
        {
          name: 'Секунд',
          code: TimeUnit.second,
        },
      ]
    },

    getDynamicUnitOptions: function () {
      return this.getSendThroughOptions()
    },
    handleChangeOperation: function (event: DelayNodeType) {
      switch (event) {
        case DelayNodeType.send_to_at_time:
          this.modelValueData.changeTypeSentAtTime()
          break;

        case DelayNodeType.specified_datetime:
          this.modelValueData.changeTypeSentSpecifiedDate()
          break;

        case DelayNodeType.send_through_time:
          this.modelValueData.changeTypeSentThrough()
          break;
      }
    },
  }
})
</script>