<template>
  <div class="">
    <div>
      <div>
        <div>
          <div>
            <div class="p-3">
              <Toolbar>
                <template #start>
                  <h5 class="m-0">{{this.card.name}}</h5>
                </template>

                <template #end>
                  <span class="ms-2 me-2 text-nowrap">Начиная с</span>
                  <DateInput v-model="this.queryStats.from"/>
                  <span class="ms-2 me-2 text-nowrap">Заканчивая по</span>
                  <DateInput v-model="this.queryStats.to"/>
                </template>
              </Toolbar>

              <p class="m-0">Количество подписавшихся: {{this.statsResponse.subscribed.count}}</p>
              <p class="m-0">Количество отписавшихся: {{this.statsResponse.unsubscribed.count}}</p>
            </div>

            <div class="mt-4 d-flex justify-content-center">
              <Chart type="line" ref="subscribedChart" :data="this.chartDataGraph" :options="this.chartOptionsGraph" style="height: 500px; width: 95%"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="m-1 mt-2 p-2 rounded bg-white">
      <h6 class="fw-bold">Подписавшийся</h6>
      <DataTable :value="this.statsResponse.subscribed.subscribers">
        <Column field="name" header="Имя"/>
        <Column field="datetime" header="Дата"/>
      </DataTable>
    </div>

    <div class="m-1 mt-2 p-2 rounded bg-white">
      <h6 class="fw-bold">Отписавшийся</h6>
      <DataTable :value="this.statsResponse.unsubscribed.subscribers">
        <Column field="name" header="Имя"/>
        <Column field="datetime" header="Дата"/>
      </DataTable>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import Chart from "primevue/chart";
import DateInput from "@/components/common/DateInput.vue";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Toolbar from "primevue/toolbar";
import {
  getGroupsForCard,
  getStatistics,
  GroupForCard,
  GroupsListItemResult,
  QueryGetStatistics,
  StatisticsResult
} from "@/api/group";
import {BreadcrumbItem} from "@/api/breadcrumbs";

export default defineComponent({
  name: "GroupStatsSubscription",
  components: {Toolbar, DataTable, Column, DateInput, Chart},
  props: {
    scenario_uuid: {
      required: true,
      type: String,
    },
    dialog_uuid: {
      required: true,
      type: String,
    },
  },
  data: function () {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');

    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    let currentDate = new Date();

    // Вычитаем 7 дней из текущей даты
    let dateSevenDaysAgo = new Date(currentDate);
    dateSevenDaysAgo.setDate(dateSevenDaysAgo.getDate() - 7);

    return {
      itemsTab: [
        { name: 'Подписки' },
        { name: 'Ежедневная статистика'  },
        { name: 'Капча' }
      ],

      breadcrumbs: [],

      card: new GroupForCard,
      queryStats: new QueryGetStatistics(this.dialog_uuid, dateSevenDaysAgo, currentDate),

      selectedCity: null,
      cities: [
        {name: 'New York', code: 'NY'},
        {name: 'Rome', code: 'RM'},
        {name: 'London', code: 'LDN'},
        {name: 'Istanbul', code: 'IST'},
        {name: 'Paris', code: 'PRS'}
      ],

      items: [] as GroupsListItemResult[],
      selectedItem: [],

      chartDataGraph: {
        labels: [],
        datasets: []
      },

      statsResponse: new StatisticsResult(),

      chartOptionsGraph: {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
          legend: {
            labels: {
              color: textColor,
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder
            }
          },
          y: {
            ticks: {
              color: textColorSecondary
            },
            grid: {
              color: surfaceBorder
            }
          }
        }
      },


      chartData: {
        labels: ['A', 'B', 'C'],
        datasets: [
          {
            data: [540, 325, 702],
            backgroundColor: [documentStyle.getPropertyValue('--cyan-500'), documentStyle.getPropertyValue('--orange-500'), documentStyle.getPropertyValue('--gray-500')],
            hoverBackgroundColor: [documentStyle.getPropertyValue('--cyan-400'), documentStyle.getPropertyValue('--orange-400'), documentStyle.getPropertyValue('--gray-400')]
          }
        ]
      },
      chartOptions: {
        plugins: {
          legend: {
            labels: {
              usePointStyle: true,
              color: textColor
            }
          }
        }
      },
    }
  },
  created() {
    this.loadStats()

    getGroupsForCard(this.dialog_uuid).then((res) => {
      this.card = res

      this.breadcrumbs = [
        new BreadcrumbItem('Telegram группы', 'bot_groups', 'bot_groups', {scenario_uuid: this.scenario_uuid}),
        new BreadcrumbItem(res.name, '', 'group_card', {scenario_uuid: this.scenario_uuid, dialog_uuid: this.dialog_uuid}),
        new BreadcrumbItem('Статистика'),
      ]
    })

    window.addEventListener("resize", this.refreshChart);
  },
  unmounted() {
    window.removeEventListener("resize", this.refreshChart);
  },
  methods: {
    loadStats: function () {
      getStatistics(this.queryStats).then((r) => {
        this.statsResponse = r
        this.chartDataGraph = {
          labels: r.labels,
          datasets: [
            {
              label: 'Подписки',
              data: r.subscribed.datasets,
              fill: false,
              // borderColor: documentStyle.getPropertyValue('--cyan-500'),
              tension: 0.4
            },
            {
              label: 'Отписки',
              data: r.unsubscribed.datasets,
              fill: false,
              // borderColor: documentStyle.getPropertyValue('--gray-500'),
              tension: 0.4
            }
          ]
        }
      })
    },

    refreshChart: function () {
      this.$refs.graph?.reinit()
    },

    setChartData() {
      const documentStyle = getComputedStyle(document.documentElement);

      return {
        // todo: принимать эти параметр с бека (который будет менять их в зависимости от типа просматриваемой статистики)
        labels: ['1 января', '2', '3', '4', '5 января', '1 января', '2', '3', '4', '5 января', '1 января', '2', '3', '4', '5 января', 'June', 'July'],
        datasets: [
          {
            label: 'Подписки',
            backgroundColor: documentStyle.getPropertyValue('--cyan-500'),
            borderColor: documentStyle.getPropertyValue('--cyan-500'),
            data: [65, 59, 80, 81, 56, 55, 40],
          },
          {
            label: 'Отписки',
            backgroundColor: documentStyle.getPropertyValue('--gray-500'),
            borderColor: documentStyle.getPropertyValue('--gray-500'),
            data: [28, 48, 40, 19, 86, 27, 90]
          },
          {
            label: 'Кикнутые',
            backgroundColor: documentStyle.getPropertyValue('--cyan-500'),
            borderColor: documentStyle.getPropertyValue('--cyan-500'),
            data: [1, 45, 2, 50, 60, 70, 40],
          },
          {
            label: 'Забанненные',
            backgroundColor: documentStyle.getPropertyValue('--cyan-500'),
            borderColor: documentStyle.getPropertyValue('--cyan-500'),
            data: [45, 60, 70, 40, 60, 45, 40],
          },
        ]
      };
    },
    setChartOptions() {
      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue('--text-color');
      const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
      const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

      // https://www.chartjs.org/docs/latest/configuration/tooltip.html
      // https://www.chartjs.org/docs/latest/general/fonts.html
      return {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
          legend: {
            labels: {
              color: textColor,
              font: {
                size: 15,
              }
            }
          },
          title: {
            display: true,
            text: 'Custom Chart Title'
          },
          tooltip: {
            titleColor: '#e20d0d'
          }
        },
        scales: {
          x: {
            ticks: {
              color: textColorSecondary,
              font: {
                weight: 800,
                size: 17,
              },
              callback: function (value, index, ticks) {
                return '$' + value;
              },
              display: function (index) {
                return true
              }
            },
            grid: {
              display: false,
              drawBorder: false
            }
          },
          y: {
            ticks: {
              color: textColorSecondary
            },
            grid: {
              color: surfaceBorder,
              drawBorder: false
            },
          }
        }
      };
    }
  },
  watch: {
    queryStats: {
      handler(newVal, oldVal) {
        this.loadStats()
      },
      deep: true,
    },
  }
})
</script>