<template>
  <div class="">
    <div v-for="(subscriber, subscriberIndex) in this.subscribers" class="content-group">
      <div class="d-flex justify-content-between flex-wrap">
        <div>
          <div v-if="subscriber.link_to_telegram">
            <a :href="subscriber.link_to_telegram" target="_blank">{{ subscriber.name }}</a>
          </div>
          <div v-else>
            <p>{{subscriber.name}}</p>
          </div>
        </div>

        <div>
          <Button
              label="Просмотр профиля"
              class="rounded"
              severity="secondary"
              outlined
              @click="showSubscriberCard(subscriber)"
              :loading="this.selectedSubscriberUuidLoading == subscriber.uuid"
          />

          <slot name="buttons_panel" :data="subscriber" :index="subscriberIndex"/>
        </div>
      </div>
    </div>

    <!--   Модальное окно просмотра карточки подписчика (todo: менять URL, чтобы иметь возможность шарить сабскрайбера)  -->
    <Dialog v-model:visible="this.dialogSubscriber.isVisible" header="Подписчик" :style="{ width: '75vw' }" maximizable modal :contentStyle="{ height: '500px' }" :draggable="false">
      <p>{{this.dialogSubscriber.subscriber.name}}</p>

      <!--      <p>Тут фотография</p>-->
      <!--      <p>Первое сообщение (дата):</p>-->
      <!--      <p>Последнее сообщение (дата):</p>-->
      <!--      <p>Количество сообщений</p>-->
      <!--      <p>Статистика кол-ва сообщений по месяцам (а нужна ли вообще)</p>-->
      <!--      <p>Бан: (отображать если забанен) + причину бана (автоматически, или администратором, если админом, то кем конкретно)</p>-->

      <!--      <p>Переменные (таблица)</p>-->
      <!--      <p>События (таблица)</p>-->
      <!--      <p>Отправка хуков (надо ли?)</p>-->

      <DataTable :value="this.dialogSubscriber.subscriber.contexts" scrollable class="custom-border mt-3" tableStyle="min-width: 50rem">
        <template #header>
          <p class="m-0">Персональные переменные</p>
        </template>

        <Column field="code" header="Код переменной"/>
        <Column field="value" header="Значение переменной"/>
      </DataTable>

      <DataTable :value="this.dialogSubscriber.subscriber.tags" scrollable class="custom-border mt-3" tableStyle="min-width: 50rem">
        <Column field="name" header="Теги"/>
      </DataTable>

      <template #footer>
        <Button
            label="Ok"
            class="rounded"
            icon="pi pi-check"
            severity="contrast"
            @click="this.closeDialogSubscriber"
        />
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import {getForCardSubscriber, getForListSubscribers, SubscriberForCard, SubscriberForListItem} from "@/api/subscriber";
import {defineComponent, PropType} from "vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import {fetchScenarioList, Scenario} from "@/api/scenario";
import MultiSelect from 'primevue/multiselect';

export default defineComponent({
  name: 'SubscribersTable',
  components: {
    MultiSelect,
    DataTable,
    Column,
    Button,
    Dialog,
  },
  props: {
    list: {
      required: true,
      type: Array as PropType<SubscriberForListItem[]>
    },
    scenario_uuid: {
      required: true,
      type: String,
    }
  },
  data: function () {
    return {
      dialogVisible: false,
      dialogSubscriber: {
        isVisible: false as boolean,
        subscriber: null as SubscriberForCard|null,
        dialogUuid: null as string|null,
      },
      subscribers: this.list,
      selectedSubscriberUuidLoading: null as null|string,
    }
  },
  methods: {
    showSubscriberCard: function(subscriber: SubscriberForListItem) {
      this.selectedSubscriberUuidLoading = subscriber.uuid

      getForCardSubscriber(subscriber.uuid, this.scenario_uuid)
          .then((subscriberForCard: SubscriberForCard) => {
            this.dialogSubscriber.subscriber = subscriberForCard
            this.dialogSubscriber.isVisible = true
          })
          .finally(() => {
            this.selectedSubscriberUuidLoading = null
          })
    },
    closeDialogSubscriber: function () {
      this.dialogSubscriber.isVisible = false
      this.dialogSubscriber.subscriber = null
    },
  },
  emits: [
      'list:update',
  ],
  watch: {
    list: function (val) {
      this.subscribers = val
    },
    subscribers: function (val) {
      this.$emit('list:update', val)
    }
  }
})
</script>
