<template>
  <div class="m-2 pb-4">
    <Breadcrumbs :items="this.breadcrumbs"/>

    <div>
      <h5 class="fw-bold mt-2 ms-2">Периодические сообщения</h5>
    </div>

    <HelpInfo>
      Используются для публикации сообщений с указанным периодом, например правил сообщества.
    </HelpInfo>

    <div>
      <div class="d-flex justify-content-end mt-2 mb-2">
        <Button
            class="rounded"
            icon="pi pi-plus"
            label="Добавить сообщение"
            severity="contrast"
            @click="this.onAddNewMessage"
        />
      </div>

      <TabView class="rounded" :active-index="this.activeIndex" @update:active-index="($event) => { this.activeIndex = $event }">
        <TabPanel :header="'Сообщение #' + (index + 1)" v-for="(card, index) in this.card.messages" :key="card.uuid">
          <div class="d-flex justify-content-end">
            <Button
                @click="() => {
                  if (this.activeIndex > 0) {
                    this.activeIndex = activeIndex - 1
                  }
                  this.card.messages.splice(index, 1)
                }"
                label="Удалить сообщение"
                icon="pi pi-trash"
                severity="danger"
                class="rounded"
                outlined
            />
          </div>

          <PeriodicMessage
              :errors="this.errors[index] ?? []"
              :value="this.card.messages[index]"
              :scenario-uuid="this.scenario_uuid"
              @value-update="($event) => { this.card.messages[index] = $event }"
          />
        </TabPanel>
      </TabView>
    </div>

    <div class="mt-4 d-flex justify-content-end">
      <Button
          label="Сохранить"
          severity="contrast"
          class="rounded"
          :loading="this.updateLoading"
          @click="() => {
            this.updateLoading = true
            this.errors = []

            updatePeriodicMessage(this.card)
              .catch((error: ErrorResponse) => {
                this.errors = error.getValidationErrors()
              })
              .finally(() => {
                this.updateLoading = false
              });
        }"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import AutoComplete from "primevue/autocomplete";
import Button from "primevue/button";
import InputSwitch from "primevue/inputswitch";
import RichTextEditor from "@/components/view/RichTextEditor.vue";
import InputNumber from "primevue/inputnumber";
import Dropdown from "primevue/dropdown";
import {TimeUnit} from "@/api/graph";
import HelpInfo from "@/components/view/HelpInfo.vue";
import TimeInput from "@/components/common/TimeInput.vue";
import {
  FilterTime,
  getPeriodicMessage, PeriodicMessageResult,
  PeriodicMessageText,
  updatePeriodicMessage,
  UpdatePeriodicMessageCmd
} from "@/api/periodicMessage";
import {ErrorResponse} from "@/api/common";
import PreviewOption from "@/components/common/PreviewOption.vue";
import Breadcrumbs from "@/components/common/Breadcrumbs.vue";
import {BreadcrumbItem} from "@/api/breadcrumbs";
import ButtonGroupPanel from "@/components/view/ButtonGroupPanel.vue";
import {ButtonGroupType} from "@/api/buttonGroupPanel";
import Panel from "primevue/panel";
import FileUpload, {FileUploadUploaderEvent} from "primevue/fileupload";
import FilesViewer from "@/components/common/FilesViewer.vue";
import {FileOwner, uploadFile} from "@/api/file";
import TabMenu from "primevue/tabmenu";
import TabPanel from "primevue/tabpanel";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import TabView from "primevue/tabview";
import PeriodicMessage from "@/components/view/GroupComponents/PeriodicMsg/PeriodicMessage.vue";

export default defineComponent({
  name: "PeriodicMessages",
  components: {
    PeriodicMessage,
    DataTable, Column, TabPanel,
    TabMenu,
    FilesViewer,
    FileUpload,
    ButtonGroupPanel,
    Breadcrumbs,
    PreviewOption,
    TimeInput,
    HelpInfo,
    Dropdown,
    InputNumber,
    RichTextEditor,
    InputSwitch,
    Button,
    AutoComplete,
    Panel,
    TabView,
  },
  props: {
    dialog_uuid: {
      required: true,
      type: String,
    },
    scenario_uuid: {
      required: true,
      type: String,
    },
  },
  data: function () {
    return {
      errors: [],
      updateLoading: false,
      breadcrumbs: [],
      card: new PeriodicMessageResult(),
      activeIndex: 0,
    }
  },
  methods: {
    updatePeriodicMessage,
    onAddNewMessage: function () {
      this.card.messages.push(new UpdatePeriodicMessageCmd())
    },
  },
  computed: {
    PeriodicMessageText() {
      return PeriodicMessageText
    },
    ButtonGroupType() {
      return ButtonGroupType
    },
    ErrorResponse() {
      return ErrorResponse
    },
  },
  created() {
    getPeriodicMessage(this.dialog_uuid).then(res => {
      this.card = res

      this.breadcrumbs = [
        new BreadcrumbItem('Telegram группы', 'bot_groups', 'bot_groups', {scenario_uuid: this.scenario_uuid}),
        new BreadcrumbItem(res.name, '', 'group_card', {
          scenario_uuid: this.scenario_uuid,
          dialog_uuid: this.dialog_uuid
        }),
        new BreadcrumbItem('Периодические сообщения'),
      ]
    })
  }
})
</script>