<template>
  <div class="bg-white">
    <IntegrationForm
      v-if="this.card !== null"
      :value="this.card"
      :scenario_uuid="this.scenario_uuid"
    >
      <template #footer>
        <Button
            label="Обновить справочные данные"
            icon="pi pi-refresh"
            class="rounded"
            :loading="this.isRefreshingDictionaryData"
            @click="() => {
              this.isRefreshingDictionaryData = true

              pullDictionaryDataForIntegration(this.integration_uuid)
                .finally(() => { this.isRefreshingDictionaryData = false })
            }"
        />
      </template>
    </IntegrationForm>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import {
  CreateIntegration,
  getIntegrationForCard,
  pullDictionaryDataForIntegration
} from "@/api/integration/integration";
import IntegrationForm from "@/components/view/Integration/IntegrationForm.vue";
import Button from "primevue/button";

export default defineComponent({
  name: "UpdateIntegration",
  methods: {pullDictionaryDataForIntegration},
  components: {
    IntegrationForm,
    Button,
  },
  props: {
    scenario_uuid: {
      type: String,
      required: true,
    },
    integration_uuid: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      card: null as CreateIntegration|null,
      isRefreshingDictionaryData: false,
    }
  },
  created() {
    getIntegrationForCard(this.integration_uuid)
        .then((r) => {
          this.card = r.data
        })
  }
})
</script>