import {AxiosRequestConfig, AxiosResponse} from "axios";
import client from "../client";
import {plainToInstance, Type} from "class-transformer";

export enum IntegrationType
{
    bitrix24 = 'bitrix24',
}

export abstract class CreateIntegration
{
    uuid: string|null = null
    name: string = ''
    scenario_uuid: string

    @Type(() => IntegrationType)
    type: IntegrationType

    protected constructor(scenario_uuid: string) {
        this.scenario_uuid = scenario_uuid;
    }
}

export class Bitrix24IntegrationCommand extends CreateIntegration
{
    token: string = ''

    constructor(scenario_uuid: string) {
        super(scenario_uuid);
        this.type = IntegrationType.bitrix24
    }
}

export function createIntegration(command: CreateIntegration): Promise<AxiosRequestConfig> {
    return client.post('/api/v1/integrations/create', command)
}

export function updateIntegration(command: CreateIntegration): Promise<AxiosRequestConfig> {
    return client.post('/api/v1/integrations/update', command)
}

export function removeIntegration(integration_uuid: string): Promise<AxiosRequestConfig> {
    return client.post('/api/v1/integrations/remove', {integration_uuid: integration_uuid})
}

export class IntegrationItem
{
    uuid: string
    name: string
    type_print: string
}

export class SimpleListItem
{
    uuid: string
    name: string
}

export class ExtendedBitrix24IntegrationItem extends IntegrationItem
{
    @Type(() => SimpleListItem)
    deal_types: SimpleListItem[] = []

    @Type(() => SimpleListItem)
    deal_stages: SimpleListItem[] = []

    @Type(() => SimpleListItem)
    user_fields: SimpleListItem[] = []
}

export class GetForListResult
{
    @Type(() => IntegrationItem)
    items: IntegrationItem[] = []
}

export function integrationGetForList(scenario_uuid: string): Promise<GetForListResult> {
    return client
        .post('/api/v1/integrations/get-for-list', {scenario_uuid: scenario_uuid})
        .then((response: AxiosResponse) => {
            return plainToInstance(GetForListResult, response.data);
        })
}

export class IntegrationForCard
{
    @Type(() => {}, {
        discriminator: {
            property: 'type',
            subTypes: [
                { value: Bitrix24IntegrationCommand, name: IntegrationType.bitrix24 },
            ]
        }
    })
    data: CreateIntegration
}

export function getIntegrationForCard(uuid: string): Promise<IntegrationForCard> {
    return client
        .post('/api/v1/integrations/get-for-card', {uuid: uuid})
        .then((response: AxiosResponse) => {
            return plainToInstance(IntegrationForCard, response.data);
        })
}

export function pullDictionaryDataForIntegration(integration_uuid: string): Promise<AxiosRequestConfig> {
    return client.post('/api/v1/integrations/dictionary-data/pull', {integration_uuid: integration_uuid})
}