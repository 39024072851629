<template>
  <div>
    <div class="mb-0">
      <InputSwitch
          v-model="this.card.active"
          class="align-middle me-1"
      />
      <span class="align-middle">Активность</span>
    </div>

    <div class="mt-1">
      <InputSwitch
          v-model="this.card.copy"
          class="align-middle me-1"
      />
      <span class="align-middle">Копировать пост без указания авторства (не указывается "Переслано от")</span>
    </div>

    <div class="mt-2">
      <p class="m-0 required">Название</p>
      <InputText v-model="this.card.name"/>
      <p v-if="this.errors['name']" class="m-0 p-error">{{ this.errors['name'] }}</p>
    </div>

    <div class="mt-2">
      <p class="mb-0">Откуда производить пересылку:</p>

      <DialogTagPanel
          v-model="this.card.sources"
          :dialog-types="[DialogType.channel]"
          :scenario-uuid="this.scenario_uuid"
          @model-value:update-value="($event) => { this.card.sources = $event }"
      />
      <p v-if="this.errors['sources']" class="m-0 p-error">{{ this.errors['sources'] }}</p>
    </div>

    <div class="mt-2">
      <p class="mb-0">Куда производить пересылку:</p>

      <DialogTagPanel
          v-model="this.card.targets"
          :dialog-types="[DialogType.channel, DialogType.group]"
          :scenario-uuid="this.scenario_uuid"
          @model-value:update-value="($event) => { this.card.targets = $event }"
      />
      <p v-if="this.errors['targets']" class="m-0 p-error">{{ this.errors['targets'] }}</p>
    </div>

    <div class="d-flex justify-content-end mt-2">
      <Button
          type="button"
          class="rounded"
          label="Сохранить"
          severity="contrast"
          :loading="this.isLoadingSave"
          @click="() => {
            this.isLoadingSave = true

            const promise = this.card instanceof UpdateForwardDialogCmd ?
              updateForwarderDialogs(this.card) :
              createForwarder(this.card);

            promise
              .then(() => { this.$emit('was-saved') })
              .catch((error: ErrorResponse) => { this.errors = error.getValidationErrors() })
              .finally(() => { this.isLoadingSave = false })
          }"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import DialogTagPanel from "@/components/view/Forward/DialogTagPanel.vue";
import {DialogType} from "@/api/dialog";
import {CreateForwardCmd, createForwarder, UpdateForwardDialogCmd, updateForwarderDialogs} from "@/api/forwarder";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import InputText from "primevue/inputtext";
import InputSwitch from "primevue/inputswitch";
import {ErrorResponse} from "@/api/common";

export default defineComponent({
  name: "ForwarderForm",
  methods: {createForwarder, updateForwarderDialogs},
  computed: {
    ErrorResponse() {
      return ErrorResponse
    },
    UpdateForwardDialogCmd() {
      return UpdateForwardDialogCmd
    },
    DialogType() {
      return DialogType
    }
  },
  components: {
    DialogTagPanel,
    Button,
    InputText,
    Checkbox,
    InputSwitch,
  },
  props: {
    scenario_uuid: {
      type: String,
      required: true,
    },
    value: {
      type: Object as PropType<CreateForwardCmd>
    }
  },
  data: function () {
    return {
      card: this.value,
      isLoadingSave: false,
      errors: [],
    }
  },
  watch: {
    value: function (val) {
      this.card = val
    },

    card: function (val) {
      this.$emit('update:value', val)
    }
  },
  emits: [
    'update:value',
    'was-saved',
  ],
})
</script>