<template>
  <div class="gridmap" style="overflow-y: auto; height: 100vh;">
    <div class="p-2 pb-4">
      <Breadcrumbs :items="this.breadcrumbs" class="bg-white"/>

<!--      <div class="d-flex justify-content-between m-2">-->
<!--        <h5 class="fw-bold mt-2 ms-2">{{ this.title }}</h5>-->
<!--        <a :href="this.card.link_to_telegram">-->
<!--          <Button-->
<!--              icon="pi pi-telegram"-->
<!--              :label="this.card.name"-->
<!--              class="rounded"-->
<!--              severity="info"-->
<!--          />-->
<!--        </a>-->
<!--      </div>-->

      <div v-if="this.card instanceof WelcomeTriggerGetForCard" class="bg-white rounded mt-2 p-2">
        <div class="mt-2">
          <InputSwitch class="me-2 align-middle" v-model="this.card.captcha.active"/>
          <span class="align-middle">Капча</span>
        </div>

        <Divider/>

        <div class="mt-2 d-flex justify-content-between">
          <div class="">
            <p class="m-0">Тип капчи</p>
            <Dropdown
                v-model="this.card.captcha.type"
                :options="[
                {
                  name: 'Эмодзи',
                  value: CapthaType.buttonIAmNotBot,
                },
                // {
                //   name: 'Должен выбрать одну из кнопок',
                //   value: CapthaType.selectOneRightButton,
                // },
                {
                  name: 'Капча с картинки',
                  value: CapthaType.chooseTextFromPicture,
                },
                {
                  name: 'Написал что угодно в чат',
                  value: CapthaType.sendAnything,
                },
                {
                  name: 'Проверка подписки на каналы',
                  value: CapthaType.subscribedOnChannel,
                },
                // {
                //   name: 'Последовательность нажатий на капче',
                //   value: '',
                // },
            ]"
                option-label="name"
                option-value="value"
                @update:model-value="this.updateCaptchaType"
            />
          </div>

          <SearchContext
              class="mt-auto"
              :context-options="this.contextOptions"
              @selected-context="($event) => { this.card.captcha.text += $event }"
          />
        </div>

        <PreviewOption class="mt-2" v-model="this.card.captcha.preview_option"/>

        <div v-show="this.card.captcha instanceof CaptchaSubscribedOnChannel" class="mt-2">
          <p class="m-0 required">Выберите каналы подписку на которые должен иметь пользователь</p>
          <DialogTagPanel
              :dialog-types="[DialogType.channel]"
              :scenario-uuid="this.scenario_uuid"
              v-model="this.card.captcha.channels"
              @model-value:update-value="($event) => { this.card.captcha.channels = $event }"
          />
        </div>

        <RichTextEditor
            ref="editor"
            v-model="this.card.captcha.text"
            class="mt-2"
            placeholder="Текст капчи"
        />
        <p class="p-error">{{ this.errors['captcha.text'] }}</p>

        <div class="mt-2">
          <p class="m-0">Сколько времени выдается на прохождение капчи</p>
          <Period v-model="this.card.captcha.time_to_answer" :show-all-units="true"/>
        </div>

        <div class="mt-2">
          <p class="m-0">Что делать если капча не пройдена</p>

          <FilterAction
              :action="this.card.captcha.action"
              @update:action="($event) => {
              this.card.captcha.action = $event
            }"
          />
        </div>
      </div>

      <div class="custom-border bg-white rounded mt-2 p-2">
        <div class="d-flex justify-content-between">
          <div class="mt-2">
            <InputSwitch class="me-2 align-middle" v-model="this.card.active"/>
            <span class="align-middle">Активность {{this.card instanceof WelcomeTriggerGetForCard ? 'приветствия' : 'прощания'}}</span>
          </div>

          <SearchContext
              class="mt-auto"
              :context-options="this.contextOptions"
              @selected-context="($event) => { this.card.text += $event }"
          />
        </div>

        <RichTextEditor
            ref="editor"
            v-model="this.card.text"
            class="mt-2"
            placeholder="Текст приветствия..."
        />
        <p class="p-error">{{ this.errors['text'] }}</p>


        <div class="mt-2">
          <Checkbox :binary="true" class="middle-align me-2" v-model="this.card.remove_prev_message"/>
          <span class="middle-align">Удалять предыдущее сообщение</span>
        </div>

        <div class="mt-1">
          <div>
            <Checkbox :binary="true" class="middle-align me-2" v-model="this.removeMessageAfter"/>
            <span class="middle-align">Удалять сообщение через</span>
          </div>

          <div v-if="this.removeMessageAfter" class="mt-2">
            <Period v-model="this.card.remove_after_period" :show-all-units="true"/>
          </div>
        </div>

        <Divider/>

        <div class="mt-4">
          <FilesViewer v-model="this.card.files"/>

          <FileUpload
              class="mt-2"
              name="file[]"
              :showUploadButton="false"
              :multiple="true"
              choose-label="Выберите файлы"
              :show-cancel-button="false"
              mode="basic"
              :auto="true"
              :custom-upload="true"
              @uploader="this.handleUploader"
          />
        </div>

        <Divider/>

        <ButtonGroupPanel v-model="this.card.buttons" :button-types="[ButtonGroupType.link]"/>

        <Panel header="Расширенные настройки" class="mt-4 mb-2" :collapsed="true" toggleable :pt="{ header: { style: {justifyContent: 'start'} } }">
          <PreviewOption v-model="this.card.preview_option"/>

          <div>
            <div class="mt-2">
              <InputSwitch class="align-middle me-2" v-model="this.card.protect_content"/>
              <span class="align-middle">Защитить контент от копирования/пересылки</span>
            </div>

            <div class="mt-2">
              <InputSwitch class="align-middle me-2" v-model="this.card.disable_notification"/>
              <span class="align-middle">Бесшумная отправка</span>
            </div>
          </div>
        </Panel>
      </div>

      <div class="mt-4 d-flex justify-content-end">
        <Button
            label="Сохранить"
            severity="secondary"
            class="rounded"
            :loading="this.updateLoading"
            @click="this.save"
        />
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import {defineComponent} from 'vue'
import Dropdown from "primevue/dropdown";
import InputSwitch from "primevue/inputswitch";
import {TimeUnit} from "@/api/graph";
import InputText from "primevue/inputtext";
import RichTextEditor from "@/components/view/RichTextEditor.vue";
import Button from "primevue/button";
import AutoComplete from "primevue/autocomplete";
import {
  CaptchaButtonIAmNotBot, CaptchaChooseTextFromPicture,
  CaptchaSendAnything,
  CaptchaSubscribedOnChannel,
  CapthaType,
  GoodbyeTriggerGetForCard,
  goodbyeTriggerGetForCard,
  TriggerWelcomeType,
  updateGoodbyeTrigger,
  updateWelcomeTrigger,
  WelcomeTriggerGetForCard,
  welcomeTriggerGetForCard
} from "@/api/welcomeTrigger";
import {ErrorResponse} from "@/api/common";
import {ContextItemForBuilder, fetchContextListForBuilder} from "@/api/context";
import draggable from "vuedraggable";
import Image from "primevue/image";
import FileUpload, {FileUploadUploaderEvent} from "primevue/fileupload";
import Divider from "primevue/divider";
import Menu from "primevue/menu";
import ButtonGroupPanel from "@/components/view/ButtonGroupPanel.vue";
import {FileOwner, uploadFile} from "@/api/file";
import FilesViewer from "@/components/common/FilesViewer.vue";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Panel from "primevue/panel";
import PreviewOption from "@/components/common/PreviewOption.vue";
import {ButtonGroupType} from "@/api/buttonGroupPanel";
import Breadcrumbs from "@/components/common/Breadcrumbs.vue";
import {BreadcrumbItem} from "@/api/breadcrumbs";
import Period from "@/components/common/Period.vue";
import HelpInfo from "@/components/view/HelpInfo.vue";
import InputNumber from "primevue/inputnumber";
import FilterAction from "@/components/view/GroupComponents/FilterAction.vue";
import DialogTagPanel from "@/components/view/Forward/DialogTagPanel.vue";
import {DialogType} from "@/api/dialog";
import SearchContext from "@/components/common/SearchContext.vue";
import Checkbox from "primevue/checkbox";
import {Period as PeriodDto} from "@/api/periodicMessage";

export default defineComponent({
  name: "WelcomeForm",
  computed: {
    DialogType() {
      return DialogType
    },
    CaptchaChooseTextFromPicture() {
      return CaptchaChooseTextFromPicture
    },
    CaptchaButtonIAmNotBot() {
      return CaptchaButtonIAmNotBot
    },
    CaptchaSendAnything() {
      return CaptchaSendAnything
    },
    CaptchaSubscribedOnChannel() {
      return CaptchaSubscribedOnChannel
    },
    CapthaType() {
      return CapthaType
    },
    WelcomeTriggerGetForCard() {
      return WelcomeTriggerGetForCard
    },
    TriggerWelcomeType() {
      return TriggerWelcomeType
    },
    ButtonGroupType() {
      return ButtonGroupType
    },
    FileUploadUploaderEvent() {
      return FileUploadUploaderEvent
    },
    ErrorResponse() {
      return ErrorResponse
    },
    TimeUnit() {
      return TimeUnit
    },

    title: function () {
      return this.type === TriggerWelcomeType.welcome ? 'Приветствие участников' : 'Прощание с участниками'
    },

    removeMessageAfter: {
      set: function (val) {
        if (val) {
          this.card.remove_after_period = new PeriodDto(10, TimeUnit.minute)
        } else {
          this.card.remove_after_period = null
        }
      },
      get: function () {
        return this.card.remove_after_period !== null
      }
    }
  },
  components: {
    Checkbox,
    SearchContext,
    DialogTagPanel,
    FilterAction,
    HelpInfo,
    Period,
    Breadcrumbs,
    PreviewOption,
    Panel,
    FilesViewer,
    ButtonGroupPanel,
    Menu,
    Divider,
    FileUpload,
    Image,
    draggable,
    RichTextEditor,
    InputSwitch,
    Dropdown,
    InputText,
    Button,
    AutoComplete,
    Accordion,
    AccordionTab,
    InputNumber,
  },
  props: {
    scenario_uuid: {
      required: true,
      type: String,
    },
    dialog_uuid: {
      required: true,
      type: String,
    },
    type: {
      required: true,
      type: String,
    }
  },
  data: function () {
    const card = this.type === TriggerWelcomeType.welcome ?
        new WelcomeTriggerGetForCard(this.dialog_uuid) :
        new GoodbyeTriggerGetForCard(this.dialog_uuid);

    return {
      card: card,
      updateLoading: false,
      errors: [],
      searchContext: '',
      searchContextOptions: [] as ContextItemForBuilder[],
      contextOptions: [] as ContextItemForBuilder[],
      breadcrumbs: [],
    }
  },
  methods: {
    uploadFile,
    updateWelcomeTrigger,
    searchOptions: function (searchOptionsEvent) {
      this.searchContextOptions = this.contextOptions.filter((item: ContextItemForBuilder) => {
        return item.name.toLowerCase().startsWith(searchOptionsEvent.query.toLowerCase())
      })
    },

    updateCaptchaType: function (event) {
      const oldActive = this.card.captcha.active
      const oldPreviewOption = this.card.captcha.preview_option

      switch(event) {
        case CapthaType.subscribedOnChannel:
          this.card.captcha = new CaptchaSubscribedOnChannel(this.card.captcha.text)
          break

        case CapthaType.sendAnything:
          this.card.captcha = new CaptchaSendAnything(this.card.captcha.text)
          break

        case CapthaType.buttonIAmNotBot:
          this.card.captcha = new CaptchaButtonIAmNotBot(this.card.captcha.text)
          break

        case CapthaType.chooseTextFromPicture:
          this.card.captcha = new CaptchaChooseTextFromPicture(this.card.captcha.text)
          break
      }

      this.card.captcha.active = oldActive
      this.card.captcha.preview_option = oldPreviewOption
    },

    save: function () {
      this.updateLoading = true
      this.errors = []

      switch (this.type) {
        case TriggerWelcomeType.welcome:
          updateWelcomeTrigger(this.card)
              .catch((error: ErrorResponse) => {
                this.errors = error.getValidationErrors()
              })
              .finally(() => {
                this.updateLoading = false
              });
          break

        case TriggerWelcomeType.goodbye:
          updateGoodbyeTrigger(this.card)
              .catch((error: ErrorResponse) => {
                this.errors = error.getValidationErrors()
              })
              .finally(() => {
                this.updateLoading = false
              });
          break
      }
    },
    
    handleUploader: function (event: FileUploadUploaderEvent) {
      const files = event.files instanceof Array ? event.files : [event.files]

      const mustSentFiles = files.filter((item: File) => {
        if (item.uuid) {
          return false
        }
        return true
      })

      let fileOwner = null
      switch (this.type) {
        case TriggerWelcomeType.welcome:
          fileOwner = FileOwner.welcomeTrigger
          break;

        case TriggerWelcomeType.goodbye:
          fileOwner = FileOwner.goodbyeTrigger
          break;
      }

      uploadFile(this.scenario_uuid, fileOwner, mustSentFiles).then((res) => {
        mustSentFiles.forEach((item: File, index: number) => {
          item.uuid = res.items[index].uuid
          this.card.files.push(res.items[index])
        })
      })
    }
  },
  created() {
    switch (this.type) {
      case TriggerWelcomeType.welcome:
        welcomeTriggerGetForCard(this.dialog_uuid).then(res => {
          this.card = res

          this.breadcrumbs = [
            new BreadcrumbItem('Telegram группы', 'bot_groups', 'bot_groups', {scenario_uuid: this.scenario_uuid}),
            new BreadcrumbItem(res.name, '', 'group_card', {scenario_uuid: this.scenario_uuid, dialog_uuid: this.dialog_uuid}),
            new BreadcrumbItem('Приветствие'),
          ]
        })
        break;

      case TriggerWelcomeType.goodbye:
        goodbyeTriggerGetForCard(this.dialog_uuid).then(res => {
          this.card = res

          this.breadcrumbs = [
            new BreadcrumbItem('Telegram группы', 'bot_groups', 'bot_groups', {scenario_uuid: this.scenario_uuid}),
            new BreadcrumbItem(res.name, '', 'group_card', {scenario_uuid: this.scenario_uuid, dialog_uuid: this.dialog_uuid}),
            new BreadcrumbItem('Прощание'),
          ]
        })
        break;
    }

    fetchContextListForBuilder(this.scenario_uuid).then(res => {
      this.contextOptions = res.global_custom_variables
          .concat(res.global_system_variables)
          .concat(res.person_custom_variables)
          .concat(res.person_system_variables)
    })
  },
})
</script>