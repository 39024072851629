<template>
  <div>
    <Toolbar>
      <template #start>
        <h5 class="m-0">{{this.card.name}}</h5>
      </template>

      <template #end>
        <span class="ms-2 me-2 text-nowrap">Начиная с</span>
        <DateInput v-model="this.queryStats.from"/>
        <span class="ms-2 me-2 text-nowrap">Заканчивая по</span>
        <DateInput v-model="this.queryStats.to"/>
      </template>
    </Toolbar>

    <div class="mt-4 d-flex justify-content-center">
      <Chart type="line" ref="subscribedChart" :data="this.chartDataGraph" :options="this.chartOptionsGraph" style="height: 500px; width: 95%"/>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import Chart from "primevue/chart";
import {getStatisticsMessages} from "@/api/statisticsMessages";
import {getGroupsForCard, GroupForCard, QueryGetStatistics} from "@/api/group";
import DateInput from "@/components/common/DateInput.vue";
import Toolbar from "primevue/toolbar";

export default defineComponent({
  name: "GroupStatsMessages",
  components: {
    DateInput,
    Chart,
    Toolbar,
  },
  props: {
    scenario_uuid: {
      required: true,
      type: String,
    },
    dialog_uuid: {
      required: true,
      type: String,
    },
  },
  data: function () {
    const documentStyle = getComputedStyle(document.documentElement);
    let currentDate = new Date();

    // Вычитаем 7 дней из текущей даты
    let dateSevenDaysAgo = new Date(currentDate);
    dateSevenDaysAgo.setDate(dateSevenDaysAgo.getDate() - 7);

    return {
      card: new GroupForCard,
      queryStats: new QueryGetStatistics(this.dialog_uuid, dateSevenDaysAgo, currentDate),

      chartOptionsGraph: {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
          legend: {
            labels: {
              color: documentStyle.getPropertyValue('--text-color'),
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: documentStyle.getPropertyValue('--text-color-secondary'),
            },
            grid: {
              color: documentStyle.getPropertyValue('--surface-border')
            }
          },
          y: {
            ticks: {
              color: documentStyle.getPropertyValue('--text-color-secondary')
            },
            grid: {
              color: documentStyle.getPropertyValue('--surface-border')
            }
          }
        }
      },
      chartDataGraph: {
        labels: [],
        datasets: []
      },

      chartData: {
        datasets: [
          {
            data: [540, 325, 702],
            backgroundColor: [documentStyle.getPropertyValue('--cyan-500'), documentStyle.getPropertyValue('--orange-500'), documentStyle.getPropertyValue('--gray-500')],
            hoverBackgroundColor: [documentStyle.getPropertyValue('--cyan-400'), documentStyle.getPropertyValue('--orange-400'), documentStyle.getPropertyValue('--gray-400')]
          }
        ]
      },
      chartOptions: {
        plugins: {
          legend: {
            labels: {
              usePointStyle: true,
            }
          }
        }
      },
    }
  },
  created() {
    getGroupsForCard(this.dialog_uuid)
        .then((res) => {
          this.card = res
        })

    this.loadStats()
  },
  methods: {
    loadStats: function () {
      getStatisticsMessages(this.queryStats)
          .then((r) => {
            this.chartDataGraph = {
              labels: r.labels,
              datasets: [
                {
                  label: 'Сообщения',
                  data: r.datasets,
                  fill: false,
                  tension: 0.4
                },
              ]
            }
          })
    }
  },
  watch: {
    queryStats: {
      handler(newVal, oldVal) {
        this.loadStats()
      },
      deep: true,
    },
  },
})
</script>