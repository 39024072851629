<template>
  <div class="bg-light">
    <p>Команда: <span class="fw-bold text-muted">{{ this.item.getCommandInfo().syntax }}</span></p>

    <HelpInfo>{{ this.item.getCommandInfo().description }}</HelpInfo>

    <div class="mt-2">
      <div>
        <InputSwitch
            v-model="this.item.is_active"
            class="align-middle me-1"
            @update:model-value="($event) => {
              if ($event === false) {
                this.item.is_visible = false
              }
            }"
        />
        <span class="align-middle">Активность</span>
      </div>

      <div class="mt-1">
        <InputSwitch
            v-model="this.item.is_visible"
            class="align-middle me-1"
            :disabled="!this.item.is_active"
        />
        <span class="align-middle">Показывать команду в выпадающем списке команд</span>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import {defineComponent, PropType} from 'vue'
import {AbstractAdminCommand, BanCommand, KickCommand, ReportCommand, WarnCommand} from "@/api/commandsSettings";
import InputSwitch from "primevue/inputswitch";
import HelpInfo from "@/components/view/HelpInfo.vue";

export default defineComponent({
  name: "BotCommandItem",
  components: {
    HelpInfo,
    InputSwitch
  },
  props: {
    item: {
      required: true,
      type: Object as PropType<AbstractAdminCommand>,
    }
  },
  data: function () {
    return {
      card: this.item,
    }
  },
  emits: [
      'update:item'
  ],
  watch: {
    item: function (val) {
      this.card = val
    },

    card: function (val) {
      this.$emit('update:item', val)
    },
  },
})
</script>