import client from "./client";
import {AxiosResponse} from "axios";
import {plainToInstance} from "class-transformer";
import {QueryGetStatistics, StatisticsResult} from "./group";


export class StatisticsMessages
{
    labels: string[] = []
    datasets: string[] = []
}

export async function getStatisticsMessages(query: QueryGetStatistics): Promise<StatisticsMessages>
{
    return await client
        .post('/api/v1/dialog/groups/statistics-messages', query)
        .then((response: AxiosResponse) => {
            return plainToInstance(StatisticsMessages, response.data);
        })
}