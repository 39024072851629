<template>
  <div class="p-2">
    <Stepper v-model:activeStep="active">
      <StepperPanel>
        <template #header="{ index, clickCallback }">
          <div
              class="border-round border-2 w-3rem h-3rem inline-flex align-items-center justify-content-center p-3"
              :class="{ 'bg-dark fw-bold border-primary text-white rounded ': index <= active, 'surface-border': index > active }"
          >
            <span>Сегментация</span>
          </div>
        </template>

        <template #content="{ nextCallback }">
          <div class="ms-5 me-5">
            <div>
              <div class="content-group">
                <h5 class="text-center">Условия для сегментации подписчиков</h5>

                <div v-for="(trigger, triggerIndex) in this.segmentationSubscribersQuery.triggers">
                  <TriggerItem
                      class="custom-border rounded"
                      :context-options="this.graphConfigStorage.getMailingTriggerContextOptions"
                      :key="trigger.uuid"
                      :is-edit-mode="true"
                      v-model="this.segmentationSubscribersQuery.triggers[triggerIndex]"
                      @pressed-button-remove="() => {
                        this.segmentationSubscribersQuery.triggers.splice(triggerIndex, 1)
                      }"
                      :errors="ErrorResponse.getValidationErrorsNested(this.errors, 'triggers[' + triggerIndex + ']')"
                      :trigger-options="this.graphConfigStorage.getMailingTriggerTargetTypes"
                  />

                  <p class="text-center m-2 fw-bold" v-show="triggerIndex !== (this.segmentationSubscribersQuery.triggers.length - 1)">И</p>
                </div>

                <div class="d-flex justify-content-center">
                  <Button
                      label="Добавить условие"
                      severity="secondary"
                      class="rounded"
                      icon="pi pi-plus"
                      @click="() => {
                        this.segmentationSubscribersQuery.triggers.push(new Trigger)
                      }"
                  />
                </div>
              </div>
            </div>

            <Divider/>

            <div class="d-flex justify-content-between">
              <Button
                label="Обновить список"
                icon="pi pi-refresh"
                severity="secondary"
                class="rounded"
                :loading="this.segmentationLoading"
                @click="() => {
                  this.segmentationLoading = true

                  getSegmentationSubscribers(this.segmentationSubscribersQuery)
                    .then((r) => {
                      this.mailingModel.subscribers = r.subscribers
                    })
                    .finally(() => { this.segmentationLoading = false })
                }"
              />

              <Button
                  label="Следующий шаг"
                  icon="pi pi-arrow-right"
                  iconPos="right"
                  severity="secondary"
                  class="rounded"
                  @click="nextCallback"
                  :disabled="this.mailingModel.subscribers.length === 0"
              />
            </div>

            <Divider/>

            <div class="">
              <SubscribersTable
                  :scenario_uuid="this.scenario_uuid"
                  :list="this.mailingModel.subscribers"
                  @list:update="($event) => { this.mailingModel.subscribers = $event }"
              >
                <template #buttons_panel="{data, index}">
                  <Button
                      icon="pi pi-trash"
                      severity="danger"
                      outlined
                      class="rounded ms-1"
                      @click="() => {
                        this.mailingModel.subscribers.splice(index, 1)
                      }"
                  />
                </template>
              </SubscribersTable>
            </div>

          </div>
        </template>
      </StepperPanel>

      <StepperPanel>
        <template #header="{ index, clickCallback }">
          <div
              class="border-round border-2 w-3rem h-3rem inline-flex align-items-center justify-content-center p-3"
              :class="{ 'bg-dark fw-bold border-primary text-white rounded ': index <= active, 'surface-border': index > active }"
          >
            <span>Контент</span>
          </div>
        </template>

        <template #content="{ prevCallback, nextCallback }">
          <div>
            <div class="mt-2">
              <SearchContext
                  :context-options="[]"
                  @selected-context="($event) => { this.mailingModel.text += $event }"
              />
            </div>

            <RichTextEditor
                class="mt-2"
                v-model="this.mailingModel.text"
            />

            <!--   Дополнительные настройки   -->
            <div class="mt-4">
              <Accordion>
                <AccordionTab header="Расширенные настройки">

                  <PreviewOption v-model="this.mailingModel.preview_option"/>

                  <div class="mt-1">
                    <Checkbox
                        :binary="true"
                        class="align-middle me-2"
                        v-model="this.mailingModel.protected"
                    />
                    <span class="align-middle">Запретить копировать</span>
                  </div>
                </AccordionTab>
              </Accordion>
            </div>
          </div>
          <div class="d-flex pt-4 justify-content-between">
            <Button label="Предыдущий шаг" severity="secondary" icon="pi pi-arrow-left" class="rounded" @click="prevCallback" />
            <Button label="Следующий шаг" icon="pi pi-arrow-right" severity="secondary" iconPos="right" class="rounded" @click="nextCallback" />
          </div>
        </template>
      </StepperPanel>

      <StepperPanel>
        <template #header="{ index, clickCallback }">
          <div
              class="border-round border-2 w-3rem h-3rem inline-flex align-items-center justify-content-center p-3"
              :class="{ 'bg-dark fw-bold border-primary text-white rounded ': index <= active, 'surface-border': index > active }"
          >
            <span>Настройки публикации</span>
          </div>
        </template>

        <template #content="{ prevCallback, nextCallback }">
          <div>
            <p class="m-0 required">Название рассылки</p>
            <InputText v-model="this.mailingModel.name"/>
          </div>

          <div class="mt-2">
            <p class="m-0 required">Дата проведения рассылки</p>
            <DateInput
              v-model="this.mailingModel.sent_at"
              :show-time="true"
            />
          </div>

          <div class="d-flex pt-4 justify-content-between">
            <Button label="Предыдущий шаг" severity="secondary" icon="pi pi-arrow-left" class="rounded" @click="prevCallback" />
            <Button
                label="Сохранить"
                icon="pi pi-arrow-right"
                iconPos="right"
                severity="secondary"
                class="rounded"
                :loading="this.isLoadingSave"
                @click="() => {
                  this.isLoadingSave = true

                  const promise = this.mailing instanceof MailingUpdate ?
                    updateMailing(this.mailing) :
                    createMailing(this.mailing)

                  promise
                    .then(() => {
                      this.$emit('saved')
                    })
                    .finally(() => {
                      this.isLoadingSave = false
                    })
                }"
            />
          </div>
        </template>
      </StepperPanel>
    </Stepper>
  </div>
</template>


<script lang="ts">
import {defineComponent, PropType, ref} from 'vue'
import Stepper from "primevue/stepper";
import StepperPanel from "primevue/stepperpanel";
import Button from "primevue/button";
import TriggerItem from "@/components/view/Bots/Trigger/TriggerItem.vue";
import {TriggerGroupItem} from "@/api/scenarioTrigger";
import {ErrorResponse} from "@/api/common";
import {useGraphConfigStorage} from "@/stores/GraphConfigStorage";
import {Trigger} from "@/api/graph";
import RichTextEditor from "@/components/view/RichTextEditor.vue";
import PreviewOption from "@/components/common/PreviewOption.vue";
import DialogTagPanel from "@/components/view/Forward/DialogTagPanel.vue";
import Checkbox from "primevue/checkbox";
import SearchContext from "@/components/common/SearchContext.vue";
import FilesViewer from "@/components/common/FilesViewer.vue";
import FileUpload, {FileUploadUploaderEvent} from "primevue/fileupload";
import DateInput from "@/components/common/DateInput.vue";
import SubscribersList from "@/components/view/Subscriber/SubscribersList.vue";
import Divider from "primevue/divider";
import SubscribersTable from "@/components/view/Subscriber/SubscribersTable.vue";
import {
  createMailing,
  getSegmentationSubscribers,
  MailingCreate,
  MailingUpdate,
  SegmentationQuery, updateMailing
} from "@/api/mailing";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import InputText from "primevue/inputtext";

export default defineComponent({
  name: "MailingForm",
  methods: {
    updateMailing,
    createMailing,
    getSegmentationSubscribers,
  },
  computed: {
    MailingUpdate() {
      return MailingUpdate
    },
    Trigger() {
      return Trigger
    },
    ErrorResponse() {
      return ErrorResponse
    },
    TriggerGroupItem() {
      return TriggerGroupItem
    }
  },
  emits: [
      'saved',
      'mailing:update',
  ],
  components: {
    SubscribersTable,
    DateInput,
    DialogTagPanel, PreviewOption,
    RichTextEditor,
    TriggerItem,
    Stepper,
    StepperPanel,
    Button,
    Checkbox,
    SearchContext,
    FilesViewer,
    FileUpload,
    SubscribersList,
    Divider,
    Accordion,
    AccordionTab,
    InputText,
  },
  props: {
    scenario_uuid: {
      type: String,
      required: true,
    },
    mailing: {
      required: true,
      type: Object as PropType<MailingCreate>,
    },
  },
  setup() {
    const graphConfigStorage = useGraphConfigStorage()

    return {graphConfigStorage}
  },
  data: function () {
    return {
      active: 0,

      errors: [],

      segmentationLoading: false,
      segmentationSubscribersQuery: new SegmentationQuery(this.scenario_uuid),
      mailingModel: this.mailing,

      isLoadingSave: false,
    }
  },
  watch: {
    'mailing': function (val) {
      this.mailingModel = val
    },

    'mailingModel': function (val) {
      this.$emit('mailing:update', val)
    }
  }
})
</script>