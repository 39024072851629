import client from "./client";
import {AxiosResponse} from "axios";
import {plainToClass, plainToInstance} from "class-transformer";
import {UploadRes} from "./post";

export enum FileOwner
{
    post = 'post',
    constructor = 'constructor',
    trigger = 'trigger',
    welcomeTrigger = 'welcome_trigger',
    goodbyeTrigger = 'goodbye_trigger',
    forwarder = 'forwarder',
    botCommands = 'bot_command',
    messagePeriodic = 'message_periodic',
}

export function uploadFile(scenarioUuid: string, fileOwner: FileOwner, files: File[]): Promise<UploadRes>
{
    const formData = new FormData()

    for (const file of files) {
        formData.append('files[]', file)
    }

    // Большие файлы могут грузиться до 60 сек
    return client
        .postForm('/api/v1/file/' + scenarioUuid + '/' + fileOwner + '/upload', formData, {timeout: 60 * 1000})
        .then((response: AxiosResponse) => {
            return plainToInstance(UploadRes, response.data);
        })
}