import { createWebHistory, createRouter } from "vue-router";
import BotList from "@/components/view/Bots/BotList.vue";
import NotFound from "@/components/view/NotFound.vue";
import ContextList from "@/components/view/Context/ContextList.vue";
import SubscribersList from "@/components/view/Subscriber/SubscribersList.vue";
import HowUse from "@/components/view/Documentation/HowUse.vue";
import DocsGetStarted from "@/components/view/Documentation/DocsGetStarted.vue";
import DocsDelayPublish from "@/components/view/Documentation/DocsDelayPublish.vue";
import DocsForwardPost from "@/components/view/Documentation/DocsForwardPost.vue";
import DocsBuilder from "@/components/view/Documentation/DocsBuilder.vue";
import DocsBuilderMessage from "@/components/view/Documentation/DocsBuilderMessage.vue";
import DocsBuilderTrigger from "@/components/view/Documentation/DocsBuilderTrigger.vue";
import DocsBuilderCommand from "@/components/view/Documentation/DocsBuilderCommand.vue";
import DocsBuilderDelay from "@/components/view/Documentation/DocsBuilderDelay.vue";
import DocsBuilderQuestion from "@/components/view/Documentation/DocsBuilderQuestion.vue";
import DocsTriggers from "@/components/view/Documentation/DocsTriggers.vue";
import DocsTags from "@/components/view/Documentation/DocsTags.vue";
import DocsContext from "@/components/view/Documentation/DocsContext.vue";
import DocsDictionary from "@/components/view/Documentation/DocsDictionary.vue";
import DocsHowAddBot from "@/components/view/Documentation/DocsHowAddBot.vue";
import DocsFeedbackBot from "@/components/view/Documentation/DocsFeedbackBot.vue"
import DialogList from "../components/view/Dialog/DialogList.vue";
import BotBuilder from "@/components/view/Bots/BotBuilder.vue";
import SignIn from "@/components/view/Auth/SignIn.vue";
import Layout from "@/components/view/Layout/Layout.vue";
import BotUsers from "@/components/view/Bots/BotUsers.vue";
import HomePage from "../components/view/IndexPage/HomePage.vue";
import EventCalendar from "@/components/view/Calendar/EventCalendar.vue";
import BotLayout from "@/components/view/Bot/BotLayout.vue";
import AboutBot from "@/components/view/Bots/AboutBot.vue";
import ChangeLog from "@/components/view/ChangeLog/ChangeLog.vue";
import TagsList from "@/components/view/Tags/TagsList.vue";
import Forwarder from "../components/view/Forward/ForwarderCard.vue";
import ForwarderList from "../components/view/Forward/ForwarderList.vue";
import ContentPlanCalendar from "../components/view/ContentPlan/ContentPlanCalendar.vue";
import PostList from "../components/view/ContentPlan/PostsList.vue";
import PostsCalendar from "../components/view/ContentPlan/PostsCalendar.vue";
import PostCard from "../components/view/ContentPlan/PostCard.vue";
import TriggersList from "../components/view/Triggers/TriggersList.vue";
import TriggerForCard from "../components/view/Triggers/TriggerForCard.vue";
import DictionaryForList from "../components/view/Dictionaries/DictionaryForList.vue";
import DictionaryForCard from "../components/view/Dictionaries/DictionaryForCard.vue";
import GroupStatistics from "../components/view/GroupChat/GroupStatistics.vue";
import GroupStatsSubscription from "../components/view/GroupChat/GroupStatsSubscription.vue";
import GroupStatsMessages from "../components/view/GroupChat/GroupStatsMessages.vue";
import GroupStatsReport from "../components/view/GroupChat/GroupStatsReport.vue";
import GroupChatList from "../components/view/GroupChat/GroupChatList.vue";
import GroupChatCard from "../components/view/GroupChat/GroupChatCard.vue";
import WelcomeCard from "../components/view/GroupComponents/WelcomeGoodbyeTrigger/WelcomeCard.vue";
import GoodbyeCard from "../components/view/GroupComponents/WelcomeGoodbyeTrigger/GoodbyeCard.vue";
import CommandsSettings from "../components/view/GroupComponents/BotCommands/CommandsSettings.vue";
import BotCommandList from "../components/view/BotCommand/BotCommandList.vue";
import WorkingHours from "../components/view/GroupComponents/WorkingHours.vue";
import FilterMessages from "../components/view/GroupComponents/FilterMessages.vue";
import PeriodicMessages from "../components/view/GroupComponents/PeriodicMsg/PeriodicMessages.vue";
import InviteLink from "../components/view/GroupComponents/InviteLink.vue";
import AutoApproveJoinRequest from "../components/view/GroupComponents/AutoApproveJoinRequest.vue";
import QueueList from "../components/view/Queue/QueueList.vue";
import ServiceGroup from "../components/view/GroupComponents/ServiceGroup.vue";
import FeedbackBot from "@/components/view/Feedback/FeedbackBot.vue";
import Profile from "@/components/view/Profile/Profile.vue";
import UseShareLink from "@/components/view/UseShareLink.vue";
import MailingList from "@/components/view/Mailing/MailingList.vue";
import MailingCreate from "@/components/view/Mailing/MailingCreate.vue";
import MailingEdit from "@/components/view/Mailing/MailingEdit.vue";
import IntegrationGetForList from "@/components/view/Integration/IntegrationGetForList.vue";
import CreateIntegration from "@/components/view/Integration/CreateIntegration.vue";
import UpdateIntegration from "@/components/view/Integration/UpdateIntegration.vue";
import FaceControlList from "@/components/view/GroupComponents/FaceControl/FaceControlList.vue";

const routes = [
    {
        name: 'home_page',
        path: '/',
        component: HomePage,
    },
    {
        name: 'share_bot',
        path: '/share-bot/:share_link_uuid',
        component: UseShareLink,
        props: true,
    },
    {
        path: '/docs',
        component: HowUse,
        meta: {
            title: 'Документация',
        },
        children: [
            {
                name: 'doc_get_started',
                path: 'get-started',
                meta: {
                    title: 'Как начать',
                },
                component: DocsGetStarted,
            },
            {
                name: 'doc_delay_publish',
                path: 'delay-publish',
                meta: {
                    title: 'Постинг',
                },
                component: DocsDelayPublish,
            },
            {
                name: 'doc_forwarder',
                path: 'forwarder',
                meta: {
                    title: 'Документация перенаправление постов',
                },
                component: DocsForwardPost,
            },
            {
                name: 'doc_dictionary',
                path: 'dictionary',
                meta: {
                    title: 'Документация справочники',
                },
                component: DocsDictionary,
            },
            {
                name: 'doc_how_to_add_bot',
                path: 'add-bot',
                meta: {
                    title: 'Как добавить бота',
                },
                component: DocsHowAddBot,
            },
            {
                name: 'doc_tags',
                path: 'tags',
                meta: {
                    title: 'Документация теги',
                },
                component: DocsTags,
            },
            {
                name: 'doc_feedback_bot',
                path: 'feedback-bot',
                meta: {
                    title: 'Бот обратной связи',
                },
                component: DocsFeedbackBot,
            },
            {
                name: 'doc_builder',
                path: 'builder',
                meta: {
                    title: 'Документация конструктор',
                },
                component: DocsBuilder,
                children: [
                    {
                        name: 'doc_builder_message',
                        path: 'message',
                        meta: {
                            title: 'Блок "Сообщение"',
                        },
                        component: DocsBuilderMessage,
                    },
                    {
                        name: 'doc_builder_trigger',
                        path: 'trigger',
                        meta: {
                            title: 'Блок "Условие"',
                        },
                        component: DocsBuilderTrigger,
                    },
                    {
                        name: 'doc_builder_command',
                        path: 'command',
                        meta: {
                            title: 'Блок "Действие"',
                        },
                        component: DocsBuilderCommand,
                    },
                    {
                        name: 'doc_builder_delay',
                        path: 'delay',
                        meta: {
                            title: 'Блок "Задержка"',
                        },
                        component: DocsBuilderDelay,
                    },
                    {
                        name: 'doc_builder_question',
                        path: 'question',
                        meta: {
                            title: 'Блок "Вопрос"',
                        },
                        component: DocsBuilderQuestion,
                    },
                ]
            },
            {
                name: 'doc_triggers',
                path: 'triggers',
                meta: {
                    title: 'Документация триггеры',
                },
                component: DocsTriggers,
            },
            {
                name: 'doc_context',
                path: 'context',
                meta: {
                    title: 'Документация переменные',
                },
                component: DocsContext,
            },
        ],
    },
    {
        path: "/",
        component: Layout,
        children: [
            {
                name: 'bots_list',
                path: 'bots',
                component: BotList,
                meta: {
                    title: 'Список ботов',
                },
            },
            {
                path: 'changelog',
                name: 'changelog',
                component: ChangeLog,
            },
            {
                path: 'profile',
                name: 'profile',
                component: Profile,
            },
            {
                name: 'bot',
                path: 'bot/:scenario_uuid',
                component: BotLayout,
                props: true,
                children: [
                    {
                        name: 'subscribers',
                        path: 'subscribers',
                        component: SubscribersList,
                        props: true,
                        meta: {
                            title: 'База пользователей',
                        },
                    },
                    {
                        name: 'mailing',
                        path: 'mailing',
                        component: MailingList,
                        props: true,
                        meta: {
                            title: 'Рассылки',
                        },
                    },
                    {
                        name: 'integrations',
                        path: 'integrations',
                        component: IntegrationGetForList,
                        props: true,
                        meta: {
                            title: 'Интеграции',
                        },
                    },
                    {
                        name: 'create_integration',
                        path: 'integration/create/:integration_type',
                        component: CreateIntegration,
                        props: true,
                        meta: {
                            title: 'Создание интеграции',
                        },
                    },
                    {
                        name: 'update_integration',
                        path: 'integration/update/:integration_uuid',
                        component: UpdateIntegration,
                        props: true,
                        meta: {
                            title: 'Обновление интеграции',
                        },
                    },
                    {
                        name: 'mailing_create',
                        path: 'mailing/create',
                        component: MailingCreate,
                        props: true,
                        meta: {
                            title: 'Создание рассылки',
                        },
                    },
                    {
                        name: 'mailing_edit',
                        path: 'mailing/edit/:mailing_uuid',
                        component: MailingEdit,
                        props: true,
                        meta: {
                            title: 'Редактирование рассылки',
                        },
                    },
                    {
                        name: 'feedback',
                        path: 'feedback',
                        component: FeedbackBot,
                        props: true,
                        meta: {
                            title: 'Бот обратной связи',
                        },
                    },
                    {
                        name: 'dialogs',
                        path: 'dialogs',
                        component: DialogList,
                        props: true,
                        meta: {
                            title: 'Диалоги',
                        },
                    },
                    {
                        name: 'about_bot',
                        path: 'settings',
                        component: AboutBot,
                        props: true,
                        meta: {
                            title: 'Настройка бота',
                        },
                    },
                    {
                        name: 'bot_builder',
                        path: 'builder',
                        component: BotBuilder,
                        props: true,
                        meta: {
                            title: 'Сценарий бота',
                        },
                    },
                    {
                        name: 'bot_groups',
                        path: 'groups',
                        component: GroupChatList,
                        props: true,
                        meta: {
                            title: 'Группы',
                        },
                    },
                    {
                        name: 'group_card',
                        path: 'group/:dialog_uuid',
                        component: GroupChatCard,
                        props: true,
                        meta: {
                            title: 'Группа',
                        },
                    },
                    {
                        name: 'group_statistics',
                        path: 'statistics/:dialog_uuid',
                        component: GroupStatistics,
                        props: true,
                        meta: {
                            title: 'Статистика',
                        },
                        children: [
                            {
                                name: 'group_statistics_subscription',
                                path: 'subscription',
                                component: GroupStatsSubscription,
                                props: true,
                                meta: {
                                    title: 'Статистика подписок',
                                },
                            },
                            {
                                name: 'group_statistics_messages',
                                path: 'messages',
                                component: GroupStatsMessages,
                                props: true,
                                meta: {
                                    title: 'Статистика сообщений',
                                },
                            },
                            {
                                name: 'group_statistics_report',
                                path: 'report',
                                component: GroupStatsReport,
                                props: true,
                                meta: {
                                    title: 'Отчеты',
                                },
                            }
                        ]
                    },
                    {
                        name: 'auto_approve_join_request',
                        path: 'auto-approve/:dialog_uuid',
                        component: AutoApproveJoinRequest,
                        props: true,
                        meta: {
                            title: 'Авто прием заявок на вступление',
                        },
                    },
                    {
                        name: 'group_work_hours',
                        path: 'work_hours/:dialog_uuid',
                        component: WorkingHours,
                        props: true,
                        meta: {
                            title: 'Часы работы',
                        },
                    },
                    {
                        name: 'invite_link',
                        path: 'invite-link/:dialog_uuid',
                        component: InviteLink,
                        props: true,
                        meta: {
                            title: 'Заявки на вступление',
                        },
                    },
                    {
                        name: 'group_filter_messages',
                        path: 'filter/:dialog_uuid',
                        component: FilterMessages,
                        props: true,
                        meta: {
                            title: 'Фильтр сообщений',
                        },
                    },
                    {
                        name: 'group_periodic_messages',
                        path: 'periodic-messages/:dialog_uuid',
                        component: PeriodicMessages,
                        props: true,
                        meta: {
                            title: 'Периодические сообщения',
                        },
                    },
                    {
                        name: 'service_group',
                        path: 'service-group/:dialog_uuid',
                        component: ServiceGroup,
                        props: true,
                        meta: {
                            title: 'Сервисная группа',
                        },
                    },
                    {
                        name: 'group_working_hours',
                        path: 'working-hours/:dialog_uuid',
                        component: WorkingHours,
                        props: true,
                        meta: {
                            title: 'Часы работы',
                        },
                    },
                    {
                        name: 'face_control',
                        path: 'face_control/:dialog_uuid',
                        component: FaceControlList,
                        props: true,
                        meta: {
                            title: 'Face control',
                        },
                    },
                    {
                        name: 'bot_users',
                        path: 'users',
                        component: BotUsers,
                        props: true,
                    },
                    {
                        name: 'context_list',
                        path: 'contexts',
                        component: ContextList,
                        props: true,
                        meta: {
                            title: 'Переменные',
                        },
                    },
                    {
                        name: 'calendar',
                        path: 'calendar',
                        component: EventCalendar,
                        props: true,
                        meta: {
                            title: 'Календарь',
                        },
                    },
                    {
                        name: 'tags_list',
                        path: 'tags',
                        component: TagsList,
                        props: true,
                        meta: {
                            title: 'Теги',
                        },
                    },
                    {
                        name: 'posting',
                        path: 'posting/:forwarder_uuid',
                        component: Forwarder,
                        props: true,
                        meta: {
                            title: 'Перенаправление постов',
                        },
                    },
                    {
                        name: 'content_plan',
                        path: 'posts',
                        component: ContentPlanCalendar,
                        props: true,
                        children: [
                            {
                                name: 'posts',
                                path: 'list',
                                props: true,
                                component: PostList,
                                meta: {
                                    title: 'Постинг',
                                },
                            },
                            {
                                name: 'posts_calendar',
                                path: 'calendar',
                                props: true,
                                component: PostsCalendar,
                                meta: {
                                    title: 'Календарь рассылок',
                                },
                            },
                            {
                                name: 'forwarder_list',
                                path: 'posting',
                                component: ForwarderList,
                                props: true,
                                meta: {
                                    title: 'Репостинг',
                                },
                            },
                            {
                                name: 'queue_list',
                                path: 'queue',
                                component: QueueList,
                                props: true,
                                meta: {
                                    title: 'Очереди публикаций',
                                },
                            },
                        ],
                    },
                    {
                        name: 'post_card',
                        path: 'post/:post_uuid',
                        props: true,
                        component: PostCard,
                        meta: {
                            title: 'Редактирование поста',
                        },
                    },
                    {
                        name: 'triggers',
                        path: 'triggers/:dialog_uuid',
                        component: TriggersList,
                        props: true,
                    },
                    {
                        name: 'welcome_card',
                        path: 'welcome/:dialog_uuid',
                        component: WelcomeCard,
                        props: true,
                        meta: {
                            title: 'Приветствие в группах',
                        },
                    },
                    {
                        name: 'bot_commands',
                        path: 'commands',
                        component: BotCommandList,
                        props: true,
                        meta: {
                            title: 'Команды бота',
                        },
                    },
                    {
                        name: 'goodbye_card',
                        path: 'goodbye/:dialog_uuid',
                        component: GoodbyeCard,
                        props: true,
                        meta: {
                            title: 'Прощание в группах',
                        },
                    },
                    {
                        name: 'commands_settings',
                        path: 'warning/:dialog_uuid',
                        component: CommandsSettings,
                        props: true,
                        meta: {
                            title: 'Система предупреждений',
                        },
                    },
                    {
                        name: 'trigger_for_card',
                        path: 'trigger/:uuid',
                        component: TriggerForCard,
                        props: true,
                    },
                    {
                        name: 'dictionary_for_list',
                        path: 'dictionaries',
                        component: DictionaryForList,
                        props: true,
                    },
                    {
                        name: 'dictionary_for_card',
                        path: 'dictionaries/:uuid',
                        component: DictionaryForCard,
                        props: true,
                    },
                ]
            }
        ]
    },
    {
        name: 'auth',
        path: "/auth",
        component: SignIn,
        meta: {
            title: 'Авторизация',
        }
    },
    {
        path: "/:pathMatch(.*)*",
        component: NotFound,
        meta: {
            title: 'Страница не найдена',
        }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.title) {
        document.title = `${to.meta.title}`;
    } else {
        document.title = 'Конструктор телеграм ботов';
    }
    next();
})

export default router;
