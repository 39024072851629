<template>
  <div class="">
    <DataTable
        :value="this.customers"
        paginator
        :rows="10"
        dataKey="uuid"
    >
      <template #header>
        <div class="d-flex justify-content-between">
          <div>
            <IconField iconPosition="left" class="d-inline-block me-2">
              <InputIcon>
                <i class="pi pi-search align-top" />
              </InputIcon>
              <InputText v-model="filters['global'].value" placeholder="Поиск по названию" class="align-middle" />
            </IconField>

            <Button
                icon="pi pi-filter-slash"
                class="rounded"
                label="Сбросить фильтр"
                outlined
                @click="clearFilter()"
            />
          </div>

          <div>
            <router-link :to="{name: 'mailing_create', params: {scenario_uuid: this.scenario_uuid}}">
              <Button
                  icon="pi pi-plus"
                  class="rounded"
                  label="Добавить рассылку"
                  severity="contrast"
              />
            </router-link>
          </div>
        </div>
      </template>
      <template #empty> Рассылки не найдены. </template>

      <Column field="name" header="Название" :sortable="true" style="min-width: 14rem"/>
      <Column header="Статус" :sortable="true" field="is_archive">
        <template #body="{ data }">
          <Tag :value="data.is_archive ? 'Архив' : 'Ожидает отправки'" :severity="data.is_archive ? 'secondary' : 'success'" />
        </template>
      </Column>

      <Column field="sent_at" header="Дата публикации" :sortable="true" style="min-width: 10rem"/>
      <Column header="Количество получателей" field="subscribers_count" :sortable="true" :filterMenuStyle="{ width: '14rem' }" style="min-width: 12rem"/>

      <Column :exportable="false" style="min-width:8rem">
        <template #body="slotProps">
          <div class="d-flex justify-content-end" v-if="!slotProps.data.is_archive">
            <router-link :to="{name: 'mailing_edit', params: {scenario_uuid: this.scenario_uuid, mailing_uuid: slotProps.data.uuid}}">
              <Button
                  label="Редактировать"
                  icon="pi pi-cog"
                  class="rounded"
                  severity="secondary"
                  outlined
              />
            </router-link>

            <Button
                icon="pi pi-trash"
                class="rounded ms-2"
                severity="danger"
                outlined
                :loading="this.isLoadingRemoveMailingUuid == slotProps.data.uuid"
                @click="() => {
                  this.isLoadingRemoveMailingUuid = slotProps.data.uuid

                  removeMailing(slotProps.data.uuid)
                    .finally(() => { this.isLoadingRemoveMailingUuid = false })
                    .then(() => {
                      this.customers.splice(slotProps.index, 1)
                    })
                }"
            />
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import TabMenu from "primevue/tabmenu";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ProgressBar from "primevue/progressbar";
import Slider from "primevue/slider";
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import InputNumber from "primevue/inputnumber";
import Tag from "primevue/tag";
import MultiSelect from "primevue/multiselect";
import InputText from "primevue/inputtext";
import IconField from "primevue/iconfield";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import InputIcon from "primevue/inputicon";
import {getMailingForList, removeMailing} from "@/api/mailing";

export default defineComponent({
  name: "MailingList",
  components: {
    TabMenu,
    Button,
    DataTable,
    Column,
    ProgressBar,
    Slider,
    InputNumber,
    Tag,
    MultiSelect,
    IconField,
    InputText,
    Dropdown,
    Calendar,
    InputIcon,
  },
  props: {
    scenario_uuid: {
      type: String,
      required: true,
    },
  },
  methods: {
    removeMailing,
    getSeverity(status) {
      switch (status) {
        case 'unqualified':
          return 'danger';

        case 'qualified':
          return 'success';

        case 'new':
          return 'info';

        case 'negotiation':
          return 'warning';

        case 'renewal':
          return null;
      }
    },

    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'country.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        balance: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        activity: { value: [0, 100], matchMode: FilterMatchMode.BETWEEN },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
      };
    },

    clearFilter() {
      this.initFilters()
    },

    formatDate(value) {
      return value.toLocaleDateString('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
    },
  },
  data: function () {
    return {
      itemsTab: [
        { name: 'Черновик' },
        { name: 'Активные' },
        { name: 'Отправленные' },
      ],
      activeTabIndex: 0,

      customers: [],
      selectedCustomers: null,
      filters: [],
      representatives: [
        { name: 'Черновик' },
        { name: 'Ожидает публикации' },
        { name: 'Публикация' },
        { name: 'Архив' },
      ],
      statuses: ['unqualified', 'qualified', 'new', 'negotiation', 'renewal', 'proposal'],
      isLoadingRemoveMailingUuid: null,
    }
  },
  created() {
    this.initFilters();

    getMailingForList(this.scenario_uuid)
        .then((r) => {
          this.customers = r.items
        })
  }
})
</script>