<template>
  <div>
    <div class="content-trigger-group">
      <CommandItem
          v-for="(element, index) in this.modelValueData.commands"
          :key="element.uuid"
          :command_group_uuid="this.modelValueData.uuid"
          v-model="this.modelValueData.commands[index]"
          @pressed-button-remove="this.modelValueData.commands.splice(index, 1);"
          @completed-filled-command-item="() => {
            this.$emit('update:modelValue', this.modelValueData)
          }"
          :context-options="this.contextOptionsForCommands"
          :command-options="this.graphConfigStorage.getCommandTypes"
          :read-only="false"
          :read-context-options="[]"
          :is-edit-mode="true"
      />


      <div class="text-center">
        <Button
            label="Добавить действие"
            severity="secondary"
            class="mt-2 rounded"
            @click="($event) => { this.$refs.menu.toggle($event); }"
        />

        <Menu
            ref="menu"
            id="overlay_menu"
            :base-z-index="10001"
            :model="this.getMenuContextOptions()"
            :popup="true"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, inject, PropType} from 'vue'
import Checkbox from "primevue/checkbox";
import ButtonGroupPanel from "@/components/view/ButtonGroupPanel.vue";
import FileUpload from "primevue/fileupload";
import RichTextEditor from "@/components/view/RichTextEditor.vue";
import AccordionTab from "primevue/accordiontab";
import FilesViewer from "@/components/common/FilesViewer.vue";
import Accordion from "primevue/accordion";
import Button from "primevue/button";
import CommandItem from "@/components/view/Bots/Command/CommandItem.vue";
import {Command, CommandGroupNode, CommandType} from "@/api/graph";
import {uuid} from "vue-uuid";
import {useGraphConfigStorage} from "@/stores/GraphConfigStorage";
import Menu from "primevue/menu";
import {MenuItem, MenuItemCommandEvent} from "primevue/menuitem";
import Dropdown from "primevue/dropdown";
import Dialog from "primevue/dialog";

export default defineComponent({
  name: "CommandGroupNodeEdit",
  components: {
    Dialog,
    Dropdown,
    Menu,
    CommandItem, Button,
    Accordion, 
    FilesViewer, 
    AccordionTab, 
    RichTextEditor, 
    FileUpload, 
    ButtonGroupPanel, 
    Checkbox,
  },
  props: {
    modelValue: {
      required: true,
      type: Object as PropType<CommandGroupNode>,
    },
  },
  inject: ['scenario_uuid', 'contextOptionsForCommands'],
  emits: [
    'update:modelValue',
  ],
  data: function () {
    return {
      modelValueData: this.modelValue,
    }
  },
  setup() {
    const graphConfigStorage = useGraphConfigStorage()
    const contextOptionsForCommands = inject('contextOptionsForCommands', [])

    return {graphConfigStorage, contextOptionsForCommands}
  },
  watch: {
    modelValueData: {
      handler(newVal, oldVal) {
        this.$emit('update:modelValue', newVal)
      },
      deep: true,
    },
    modelValue: function (val) {
      this.modelValueData = val
    }
  },
  methods: {
    uuid() {
      return uuid
    },

    onClickCreateButton: function (event: MenuItemCommandEvent) {
      const eventType = event.item as {code: CommandType}

      switch (eventType.code) {
        case CommandType.setContext:
          this.modelValueData.commands.push(Command.createSetContext())
          break

        case CommandType.appendContext:
          this.modelValueData.commands.push(Command.createAppendContext())
          break

        case CommandType.forwardToDialog:
          this.modelValueData.commands.push(Command.createForwardToDialog())
          break

        case CommandType.unpinAllMessages:
          this.modelValueData.commands.push(Command.createUnpinAllMessages())
          break

        case CommandType.addTagToSubscriber:
          this.modelValueData.commands.push(Command.createAddTagToSubscriber())
          break

        case CommandType.removeTagFromSubscriber:
          this.modelValueData.commands.push(Command.createRemoveTagFromSubscriber())
          break

        case CommandType.pinMessage:
          this.modelValueData.commands.push(Command.createPinMessage())
          break

        case CommandType.removeMessage:
          this.modelValueData.commands.push(Command.createRemoveMessage())
          break

        case CommandType.markNeedAttention:
          this.modelValueData.commands.push(Command.createNeedAttention())
          break

        case CommandType.sendWebHook:
          this.modelValueData.commands.push(Command.createSendWebHook())
          break

        case CommandType.bitrix24CreateDeal:
          this.modelValueData.commands.push(Command.createBitrix24CreateDeal())
          break
      }
    },

    getMenuContextOptions: function () {
      const result: MenuItem[] = []

      this.graphConfigStorage.getCommandTypes.forEach((item => {
        result.push({
          label: item.name,
          code: item.value,
          command: this.onClickCreateButton
        })
      }))

      return result
    }
  },
  computed: {
    CommandType() {
      return CommandType
    },
  }
})
</script>