<template>
  <div class="p-2 gridmap" style="overflow-y: auto; height: 100vh;">
    <Breadcrumbs :items="this.breadcrumbs" class="bg-white"/>

    <div class="mt-4">
      <div class="bg-primary-subtle p-2 rounded">
        <p class="m-0"><span class="pi pi-question-circle me-2"/>Запрещает отправку сообщений в указанном временном промежутке для не админов</p>
      </div>

      <div class="bg-white mt-4 p-3 rounded">
        <h5 class="fw-bold">Режим работы чата</h5>

        <div class="">
          <InputSwitch class="align-middle me-2" v-model="this.card.active"/>
          <span class="m-0 align-middle">Активность</span>
        </div>

        <div class="mt-3">
          <p class="m-0 required">С какого времени блокировать возможность общаться в чате</p>
          <TimeInput class="w-25" v-model="this.card.start_at"/>
        </div>

        <div class="mt-3">
          <p class="m-0 required">По какое время блокировать возможность общаться в чате</p>
          <TimeInput class="w-25" v-model="this.card.finish_at"/>
        </div>

        <div class="mt-3">
          <div class="d-flex justify-content-between mb-1">
            <p class="m-0 mt-auto">Сообщение при открытии чата</p>

            <SearchContextOption
                :context-options="this.card.open_chat_context_options"
                @selected-context="($event) => { this.card.message_open_chat += $event }"
            />
          </div>

          <RichTextEditor v-model="this.card.message_open_chat"/>
        </div>

        <div class="mt-3">
          <div class="d-flex justify-content-between mb-1">
            <p class="m-0 mt-auto">Сообщение при закрытии чата</p>
            <SearchContextOption
                :context-options="this.card.close_chat_context_options"
                @selected-context="($event) => { this.card.message_close_chat += $event }"
            />
          </div>

          <RichTextEditor v-model="this.card.message_close_chat"/>
        </div>

        <div class="mt-3">
          <div class="">
            <InputSwitch class="align-middle me-2" v-model="this.card.remove_close_message"/>
            <span class="m-0 align-middle">Удалять сообщение об закрытии чата, при открытии</span>
          </div>

          <div class="mt-1">
            <InputSwitch class="align-middle me-2" v-model="this.card.remove_open_message_if_have_message"/>
            <span class="m-0 align-middle">Удалять сообщение об открытии чата если кто-то написал</span>
          </div>

          <div class="mt-1">
            <div>
              <InputSwitch class="align-middle me-2" v-model="this.removeOpenMessageAfter"/>
              <span class="m-0 align-middle">Удалять сообщение об открытии чата через время</span>
            </div>

            <Period
                v-if="this.removeOpenMessageAfter"
                v-model="this.card.remove_open_message_after"
                :show-all-units="true"
                class="mt-1"
            />
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end mt-3">
        <Button
          label="Сохранить"
          :loading="this.isLoadingSave"
          class="rounded"
          severity="secondary"
          @click="() => {
            this.isLoadingSave = true

            updateWorkHours(this.card)
              .finally(() => { this.isLoadingSave = false })
          }"
        />
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import {defineComponent} from 'vue'
import Dropdown from "primevue/dropdown";
import RichTextEditor from "@/components/view/RichTextEditor.vue";
import InputSwitch from "primevue/inputswitch";
import InputNumber from "primevue/inputnumber";
import TimeInput from "@/components/common/TimeInput.vue";
import Textarea from "primevue/textarea";
import {getWorkHours, updateWorkHours, WorkHoursGetForCard, WorkHoursUpdate} from "@/api/workHours";
import Button from "primevue/button";
import Breadcrumbs from "@/components/common/Breadcrumbs.vue";
import {BreadcrumbItem} from "@/api/breadcrumbs";
import {Period as PeriodDto} from "@/api/periodicMessage";
import {TimeUnit} from "@/api/graph";
import Period from "@/components/common/Period.vue";
import SearchContext from "@/components/common/SearchContext.vue";
import SearchContextOption from "@/components/common/SearchContextOption.vue";

export default defineComponent({
  name: "WorkHours",
  methods: {updateWorkHours},
  components: {
    SearchContextOption,
    SearchContext,
    Period,
    Breadcrumbs,
    TimeInput,
    RichTextEditor,
    InputNumber,
    InputSwitch,
    Dropdown,
    Textarea,
    Button,
  },
  data: function () {
    return {
      card: new WorkHoursGetForCard(),
      isLoadingSave: false,
      breadcrumbs: [],
    }
  },
  props: {
    scenario_uuid: {
      required: true,
      type: String,
    },
    dialog_uuid: {
      required: true,
      type: String,
    },
  },
  created() {
    getWorkHours(this.dialog_uuid)
        .then((res) => {
          this.card = res

          this.breadcrumbs = [
            new BreadcrumbItem('Telegram группы', 'bot_groups', 'bot_groups', {scenario_uuid: this.scenario_uuid}),
            new BreadcrumbItem(res.dialog_name, '', 'group_card', {scenario_uuid: this.scenario_uuid, dialog_uuid: this.dialog_uuid}),
            new BreadcrumbItem('Часы работы'),
          ]
        })
  },
  computed: {
    removeOpenMessageAfter: {
      set: function (val: boolean) {
        if (val) {
          this.card.remove_open_message_after = new PeriodDto(30, TimeUnit.minute)
        } else {
          this.card.remove_open_message_after = null
        }
      },
      get: function (): boolean {
        return this.card.remove_open_message_after !== null
      },
    }
  }
})
</script>