<template>
  <div>
    <div class="rounded-pill d-inline-block p-1 me-1 mb-1" style="background-color: #d7d9d9" v-for="(word, index) in this.list">
      <div class="d-inline-block ps-1">
        <span v-show="word.type === ExcludeSubscriberType.by_id">id: </span>
        <span v-show="word.type === ExcludeSubscriberType.by_username">username: </span>
        <div
            contenteditable="true"
            class="d-inline-block p-2 ps-3 pe-3"
            @input="this.handleEditExcludeSubscriber(index, $event)"
        >{{word.value}}</div>
      </div>

      <button
          class="bg-transparent border-0 pi pi-times-circle text-danger"
          @click="() => {
            this.list.splice(index, 1)
          }"
      />
    </div>

    <div class="mt-2">
      <Button
          icon="pi pi-plus"
          severity="contrast"
          label="Добавить"
          class="rounded align-middle"
          @click="this.toggle"
      />
      <Menu
          ref="menu"
          :popup="true"
          :model="this.excludeTypes"
      />
    </div>
  </div>
</template>


<script lang="ts">
import {defineComponent, PropType} from 'vue'
import Button from "primevue/button";
import Menu from "primevue/menu";
import {
  ExcludeSubscriber,
  ExcludeSubscriberType,
} from "@/api/subscriber";

export default defineComponent({
  name: "SubscribersList",
  computed: {
    ExcludeSubscriberType() {
      return ExcludeSubscriberType
    },
    excludeTypes: function () {
      return [
        {
          label: 'По id',
          icon: 'pi pi-user',
          command: () => this.handleAddExcludeSubscriber(ExcludeSubscriberType.by_id),
        },
        {
          label: 'По username',
          icon: 'pi pi-at',
          command: () => this.handleAddExcludeSubscriber(ExcludeSubscriberType.by_username),
        }
      ]
    },
  },
  components: {
    Menu,
    Button,
  },
  props: {
    subscribers: {
      type: Array as PropType<ExcludeSubscriber[]>,
      required: true,
    },
  },
  data: function () {
    return {
      list: this.subscribers,
    }
  },
  watch: {
    subscribers: function (val: ExcludeSubscriber[]) {
      this.list = val
    },

    list: function (val: ExcludeSubscriber[]) {
      this.$emit('subscribers:update', val)
    }
  },
  methods: {
    toggle: function (event) {
      this.$refs.menu.toggle(event);
    },

    handleAddExcludeSubscriber: function (excludeSubscriberType: ExcludeSubscriberType): void {
      this.list.push(new ExcludeSubscriber(excludeSubscriberType))
    },

    handleEditExcludeSubscriber: function (index: number, event: InputEvent): void {
      this.list[index].value = event.target.innerText
    }
  },
  emits: [
    'subscribers:update',
  ],
})
</script>