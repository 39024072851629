import client from "./client";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import {plainToInstance} from "class-transformer";
import {Period} from "./periodicMessage";
import {ContextOption} from "./groupSettings";


export class WorkHoursUpdate
{
    active: boolean = false

    start_at: string
    finish_at: string

    remove_open_message_if_have_message: boolean = false
    remove_open_message_after: Period|null = null
    message_open_chat: string|null = null

    message_close_chat: string|null = null
    remove_close_message: boolean = false
}

export class WorkHoursGetForCard extends WorkHoursUpdate
{
    dialog_name: string

    open_chat_context_options: ContextOption[] = []
    close_chat_context_options: ContextOption[] = []
}

export async function updateWorkHours(cmd: WorkHoursUpdate): Promise<AxiosRequestConfig>
{
    return await client
        .post('/api/v1/dialog/group/work-hours/update', cmd)
}

export async function getWorkHours(dialogUuid: string): Promise<WorkHoursGetForCard>
{
    return await client
        .post('/api/v1/dialog/group/work-hours/get-for-card', {dialog_uuid: dialogUuid})
        .then((response: AxiosResponse) => {
            return plainToInstance(WorkHoursGetForCard, response.data);
        })
}