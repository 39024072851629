<template>
  <div class="d-flex bd-highlight expanded_sidebar">
    <LeftSideBar v-model="this.sideBarMenus" :collapsed-menu-default="false"/>

    <div class="flex-grow-1 bd-highlight">
      <div>
        <div class="d-inline-block m-3" >
          <a href="/" style="text-decoration: none; color: inherit">
              <img src="/icon.png" alt="Иконка">
              <span class="ms-3 fw-bold">Ninja-bot</span>
          </a>
        </div>

        <div class="float-end m-3">
          <router-link :to="{name: 'auth'}">
            <Button
                class="rounded"
                label="Авторизация"
                severity="secondary"
            />
          </router-link>
        </div>
      </div>

      <div class="doc_content_margin">
        <router-view/>
      </div>

      <!--        <div class="d-flex justify-content-end pb-3 me-3">-->
      <!--          <Button-->
      <!--              label="Назад"-->
      <!--              severity="secondary"-->
      <!--              class="m-1 rounded"-->
      <!--          />-->

      <!--          <Button-->
      <!--              label="Далее"-->
      <!--              severity="success"-->
      <!--              class="m-1 rounded"-->
      <!--          />-->
      <!--        </div>-->
    </div>
  </div>
</template>

<script lang="ts">
import Image from "primevue/image";
import LeftSideBar from "@/components/view/SideBar/LeftSideBar.vue";
import {SideBarMenu} from "@/components/view/SideBar/sidebar";
import Button from "primevue/button";

export default {
  name: 'HowUse',
  components: {LeftSideBar, Image, Button},
  props: {
    section: {
      type: [String, null],
      required: false,
      default: null,
    }
  },
  data: function () {
    const menu = []

    // todo: сделать вложенное меню
    /*
        Каналы
          - отложенный постинг
          - перенаправление постов

        Группы
          - команды
          - приветствие
          - прощание
          - фильтр сообщений
          - статистика
          - триггеры

        Конструктор
          - описание функционала
          - описание блоков - сообщение, вопрос и т.д
     */

    menu.push(new SideBarMenu('О сервисе', 'doc_get_started', null, 'bx bx-run', []))
    menu.push(new SideBarMenu('Добавление бота', 'doc_how_to_add_bot', null, 'bx bx-bot', []))
    menu.push(new SideBarMenu('Постинг', 'doc_delay_publish', null, 'bx bx-text', []))
    menu.push(new SideBarMenu('Перенаправление постов', 'doc_forwarder', null, 'bx bx-fast-forward-circle', []))
    menu.push(new SideBarMenu('Триггеры', 'doc_triggers', null, 'bx bx-check-double', []))
    menu.push(new SideBarMenu('Переменные', 'doc_context', null, 'bx bx-memory-card', []))
    menu.push(new SideBarMenu('Теги', 'doc_tags', null, 'bx bx-purchase-tag', []))
    menu.push(new SideBarMenu('Бот обратной связи', 'doc_feedback_bot', null, 'pi pi-phone', []))
    // menu.push(new SideBarMenu('Справочники', 'doc_dictionary', null, 'bx bx-book', []))
    menu.push(new SideBarMenu('Конструктор', 'doc_builder', null, 'bx bx-git-merge', [
        new SideBarMenu('Блок - сообщение', 'doc_builder_message', null, 'bx bx-message-dots', []),
        new SideBarMenu('Блок - вопрос', 'doc_builder_question', null, 'pi pi-question', []),
        new SideBarMenu('Блок - действие', 'doc_builder_command', null, 'bx bx-wrench', []),
        new SideBarMenu('Блок - условие', 'doc_builder_trigger', null, 'bx bx-git-compare', []),
        new SideBarMenu('Блок - задержка', 'doc_builder_delay', null, 'bx bx-timer', []),
    ]))

    return {
      sideBarMenus: menu,
    }
  },
}
</script>

<style scoped>
.doc_content_margin {
  margin: 2em;
}

/* Устроства с маленьким экраном */
@media (max-width: 400px) {
  .doc_content_margin {
    margin: 10px;
  }
}
</style>
