import {FilterWarning} from "./groupSettings";
import {plainToInstance, Type} from "class-transformer";
import client from "./client";
import {AxiosRequestConfig, AxiosResponse} from "axios";


export enum AdminCommand
{
    kick = 'kick',
    ban = 'ban',
    warn = 'warn',
    msg = 'msg',
    mute = 'mute',
    mute_media = 'mute_media',
    report = 'report',
}

export class CommandInfo
{
    syntax: string
    description: string

    constructor(syntax: string, description: string) {
        this.syntax = syntax;
        this.description = description;
    }
}

export abstract class AbstractAdminCommand
{
    abstract type: AdminCommand

    syntax: string
    description: string
    is_active: boolean = false
    is_visible: boolean = false

    getCommandInfo(): CommandInfo
    {
        return new CommandInfo(this.syntax, this.description)
    }
}

export class KickCommand extends AbstractAdminCommand
{
    type: AdminCommand.kick
}

export class MsgCommand extends AbstractAdminCommand
{
    type: AdminCommand.msg
}

export class BanCommand extends AbstractAdminCommand
{
    type: AdminCommand.ban
}

export class WarnCommand extends AbstractAdminCommand
{
    type: AdminCommand.warn
    filter: FilterWarning
}

export class ReportCommand extends AbstractAdminCommand
{
    type: AdminCommand.report
}


export class CommandsSettingsUpdate
{
    @Type(() => AbstractAdminCommand)
    user_commands: AbstractAdminCommand[] = []

    @Type(() => AbstractAdminCommand)
    admin_commands: AbstractAdminCommand[] = []
}

export class CommandsSettingsGetForCard extends CommandsSettingsUpdate
{
    dialog_uuid: string
    dialog_name: string
}

export function getCommandsSettingsCard(dialogUuid: string): Promise<CommandsSettingsGetForCard>
{
    return client
        .post('/api/v1/dialog/group/settings/commands/get', {dialog_uuid: dialogUuid})
        .then((response: AxiosResponse) => {
            return plainToInstance(CommandsSettingsGetForCard, response.data);
        })
}

export function updateCommandsSettingsCard(cmd: CommandsSettingsGetForCard): Promise<AxiosRequestConfig>
{
    return client
        .post('/api/v1/dialog/group/settings/commands/update', cmd)
}