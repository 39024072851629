<template>
  <div class="bg-white pb-3">
    <div class="p-2 custom-border">
      <h2 class="m-1 fw-bold">Настройка бота</h2>
    </div>

    <div class="m-4 rounded custom-border">
      <div style="background-color: #f3f4fa" class="custom-border">
        <div class="d-flex justify-content-end m-2">
          <a :href="this.scenario.link_to_telegram" target="_blank">
            <Button
                label="Перейти в бота"
                icon="pi pi-telegram"
                severity="info"
                class="rounded"
            />
          </a>
        </div>
      </div>

      <div class="custom-border p-3 ">
        <div class="d-inline-block" style="width: 40em">
          <h5>Название</h5>
        </div>
        <div class="d-inline-block">
          <InputText v-model="this.scenario.name"/>
        </div>
      </div>

      <div class="custom-border p-3 ">
        <div class="d-inline-block" style="width: 40em; vertical-align: top;">
          <h5 class="">Описание</h5>
        </div>
        <div class="d-inline-block">
          <Textarea rows="5" cols="25" auto-resize v-model="this.scenario.description" style="width: 40em"/>
        </div>
      </div>

      <div class="custom-border p-3 ">
        <div class="d-inline-block" style="width: 40em">
          <h5>Токен</h5>
          <div class="text-muted">Токен полученный при создании бота в Telegram через BotFather</div>
        </div>
        <div class="d-inline-block">
          <InputText v-model="this.scenario.token" :disabled="true" style="width: 40em"/>
          <button type="button" class="border-0 p-0 bg-transparent ms-1">
            <p class="pi pi-copy" @click="this.copyBotToken"/>
          </button>
        </div>
      </div>

      <div class="custom-border p-3 ">
        <div class="d-inline-block" style="width: 40em">
          <h5 class="d-inline">Активность</h5>
          <div class="text-muted">Отключенный бот не будет реагировать на сообщения пользователей</div>
        </div>
        <div class="d-inline-block">
          <InputSwitch v-model="this.scenario.is_active" class="me-4 align-middle"/>
          <span class="p-error align-middle">
            {{this.scenario.deactivated_reason_print}}
          </span>
        </div>
      </div>

      <div class="custom-border p-3 ">
        <div class="d-inline-block" style="width: 40em">
          <h5>Часовой пояс</h5>
        </div>
        <div class="d-inline-block">
          <Dropdown
              v-model="this.scenario.time_zone"
              option-label="label"
              option-value="value"
              :options="getTimeZones()"
              class="mb-2"
          />
        </div>
      </div>

      <div class="custom-border p-3 ">
        <div class="d-inline-block" style="width: 40em">
          <h5>Режим работы бота</h5>
        </div>
        <div class="d-inline-block">
          <Dropdown
              v-model="this.scenario.mode"
              option-label="label"
              option-value="value"
              :options="[
                  {
                    label: 'Проигрывание сценария',
                    value:  ScenarioMode.scenario,
                  },
                  {
                    label: 'Бот обратной связи',
                    value: ScenarioMode.feedback,
                  }
              ]"
              class="mb-2"
          />
        </div>
      </div>

      <div class="custom-border p-3 ">
        <h5>Пользователи имеющие доступ к настройкам бота</h5>

        <div class="w-50">
          <DataTable
              :value="this.scenario.members"
              data-key="uuid"
              :pt="{thead: {hidden: true}}"
              table-style="min-width: 1rem"
          >
            <Column field="name"/>
            <Column field="is_owner">
              <template #body="slotProps">
                <Tag :value="slotProps.data.is_owner ? 'Владелец' : 'Модератор'" :severity="slotProps.data.is_owner ? 'success' : 'info'" />
              </template>
            </Column>
            <Column>
              <template #body="slotProps">
                <Button
                    v-if="this.scenario.is_owner_bot"
                    icon="pi pi-trash"
                    outlined
                    class="rounded-circle"
                    severity="danger"
                    v-tooltip="'Забрать доступ к боту'"
                    @click="() => {
                      this.scenario.members.splice(slotProps.index, 1)
                    }"
                />
              </template>
            </Column>
          </DataTable>

          <div class="mt-4">
            <InputGroup>
              <Button
                  label="Выдать доступ к боту"
                  icon="pi pi-share-alt"
                  severity="secondary"
                  class="rounded-start"
                  @click="this.onClickCreateSharedLink"
                  :disabled="this.scenario.is_owner_bot === false"
              />
              <InputText :disabled="true" :model-value="this.createdShareLinkUrl"/>
            </InputGroup>

            <p class="text-muted mt-2 mb-0">
              Отправьте сгенерированную ссылку пользователю которому должен предоставиться доступ к боту,
              время жизни ссылки - 5 минут, одна ссылка - один пользователь.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="m-4 d-flex justify-content-end">
      <Button
          class="me-2 rounded"
          @click="this.isShowDialogRemoveScenario = true"
          severity="danger"
          label="Удалить"
      />
      <Button
          class="me-2 rounded"
          @click="this.onClickEditOrSave"
          label="Сохранить"
          :loading="this.isLoading"
          severity="contrast"
      />
    </div>
  </div>

  <Dialog v-model:visible="this.isShowDialogRemoveScenario" header="Вы уверены что хотите удалить бота?" modal
          :draggable="false">
    <p>Бот не подлежит восстановлению</p>

    <template #footer>
      <Button
          label="Я уверен, удалить"
          severity="danger"
          @click="this.removeBot"
      />
    </template>
  </Dialog>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import InputSwitch from "primevue/inputswitch";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import {
  createScenarioShareLink,
  getScenarioForCard,
  getTimeZones,
  removeScenario,
  ScenarioForCard, ScenarioMember,
  ScenarioMode,
  updateBot
} from "@/api/scenario";
import Textarea from "primevue/textarea";
import Dropdown from "primevue/dropdown";
import {info} from "@/api/ToastService";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Tag from "primevue/tag";
import InputNumber from "primevue/inputnumber";
import InputGroup from "primevue/inputgroup";

export default defineComponent({
  name: 'AboutBot',
  props: {
    scenario_uuid: {
      required: true,
      type: String,
    },
  },
  data: function () {
    return {
      isShowDialogRemoveScenario: false as boolean,
      scenario: {} as ScenarioForCard,
      isLoading: false,

      createdShareLinkUrl: null as string|null,
    }
  },
  components: {
    Button,
    Dialog,
    InputSwitch,
    InputText,
    Textarea,
    Dropdown,
    DataTable,
    Column,
    InputNumber,
    Tag,
    InputGroup,
  },
  methods: {
    info,
    createScenarioShareLink,
    getTimeZones,
    removeBot: function () {
      removeScenario(this.scenario_uuid).then(() => {
        // @ts-ignore
        this.$router.push({name: 'bots_list'})
      })
    },

    onClickEditOrSave: function () {
      this.isLoading = true
      updateBot(
          this.scenario_uuid,
          this.scenario.name,
          this.scenario.token,
          this.scenario.description,
          this.scenario.is_active,
          this.scenario.time_zone,
          this.scenario.mode,
          this.scenario.members,
      )
          .finally(() => {
            this.isLoading = false
          });
    },

    // В будущем заюзать какую нибудь красивую обертку над кнопками для copy
    copyBotLink: function () {
      navigator.clipboard.writeText(this.scenario.link_to_telegram).then(function () {
        info('Ссылка на бот скопирована в буфер обмена', '')
      })
    },

    copyBotToken: function () {
      navigator.clipboard.writeText(this.scenario.token).then(function () {
        info('Токен бота скопирован в буфер обмена', '')
      })
    },

    onClickCreateSharedLink: function () {
      createScenarioShareLink(this.scenario_uuid).then((r) => {
        this.createdShareLinkUrl = r.url

        navigator.clipboard.writeText(r.url).then(function () {
          info('Ссылка доступа скопирована в буфер обмена')
        })
      })
    },
  },
  mounted() {
    getScenarioForCard(this.scenario_uuid).then((scenario: ScenarioForCard) => {
      this.scenario = scenario
    })
  },
  computed: {
    ScenarioMode() {
      return ScenarioMode
    },
    tutorialSteps: function () {
    //     vsm--item > forward_list
    },

  }
})
</script>