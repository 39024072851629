<template>
  <div class="p-2 vh-100 gridmap" style="overflow-y: auto">
    <Breadcrumbs :items="this.breadcrumbs" class="bg-white"/>

    <div class="mt-4">
      <router-link :to="{name: feature.route_name, params: feature.route_params}" class="text-decoration-none text-black" v-for="feature in this.features">
        <div class="m-4 custom-border rounded p-3 bg-white">
          <div>
            <span :class="feature.icon" class="me-3"/>
            <h5 class="d-inline">{{ feature.name }}</h5>
          </div>
          <p class="m-1 text-muted" :class="{'fs-5': feature.bold, 'fw-bold': feature.bold}">{{ feature.description }}</p>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import Breadcrumbs from "@/components/common/Breadcrumbs.vue";
import {BreadcrumbItem} from "@/api/breadcrumbs";
import {getGroupsForCard, GroupForCard} from "@/api/group";

export default defineComponent({
  name: "GroupChatCard",
  components: {
    Breadcrumbs
  },
  props: {
    scenario_uuid: {
      required: true,
      type: String,
    },
    dialog_uuid: {
      required: true,
      type: String,
    },
  },
  data: function () {
    return {
      breadcrumbs: [],

      features: [
        {
          bold: false,
          name: 'Приветственное сообщение',
          icon: 'pi pi-sign-in',
          description: 'Настройте приветственное сообщение новых участников группы',
          route_name: 'welcome_card',
          route_params: {scenario_uuid: this.scenario_uuid, dialog_uuid: this.dialog_uuid},
        },
        {
          bold: false,
          name: 'Прощальное сообщение',
          icon: 'pi pi-sign-out',
          description: 'Задайте текст прощания с пользователем покинувшем вашу группу',
          route_name: 'goodbye_card',
          route_params: {scenario_uuid: this.scenario_uuid, dialog_uuid: this.dialog_uuid},
        },
        {
          bold: false,
          name: 'Фильтры сообщений',
          icon: 'bx bx-block',
          description: 'Позволяет удалять и наказывать за мат, ссылки, пересланные сообщения из других чатов или ссылки на чужие Telegram каналы/группы',
          route_name: 'group_filter_messages',
          route_params: {scenario_uuid: this.scenario_uuid, dialog_uuid: this.dialog_uuid},
        },
          // todo: вернуть когда добавлю алиасы для команд
        // {
        //   bold: false,
        //   name: 'Команды управления ботом',
        //   icon: '/free.svg',
        //   description: 'Команды через которые бот может выгонять людей из группы, банить или запрещать писать участнику в чат',
        //   route_name: 'welcome_card',
        //   route_params: {scenario_uuid: this.scenario_uuid, dialog_uuid: this.dialog_uuid},
        // },
        {
          bold: false,
          name: 'Триггеры',
          icon: 'bx bx-check-shield',
          description: 'Позволяют задать условия при срабатывании которых бот выполнит определенное действие',
          route_name: 'triggers',
          route_params: {scenario_uuid: this.scenario_uuid, dialog_uuid: this.dialog_uuid},
        },
        // {
        //   bold: false,
        //   name: 'Проверка участников',
        //   icon: 'pi pi-shield',
        //   description: 'Проверка пользователей на наличие ссылок в описании профиля, запрещенных слов',
        //   route_name: 'face_control',
        //   route_params: {scenario_uuid: this.scenario_uuid, dialog_uuid: this.dialog_uuid},
        // },
        {
          bold: false,
          name: 'Периодические сообщения',
          icon: 'bx bx-calendar-event',
          description: 'Задайте цикличность с которой бот будет публиковать в группе сообщения, например правила сообщества',
          route_name: 'group_periodic_messages',
          route_params: {scenario_uuid: this.scenario_uuid, dialog_uuid: this.dialog_uuid},
        },
        {
          bold: false,
          name: 'Заявки на вступление',
          icon: 'pi pi-check',
          description: 'Бот будет автоматически принимать все заявки на вступление в группу',
          route_name: 'auto_approve_join_request',
          route_params: {scenario_uuid: this.scenario_uuid, dialog_uuid: this.dialog_uuid},
        },
        {
          bold: false,
          name: 'Сервисная группа',
          icon: 'pi pi-history',
          description: 'Логирование действий производимых ботом',
          route_name: 'service_group',
          route_params: {scenario_uuid: this.scenario_uuid, dialog_uuid: this.dialog_uuid},
        },
        {
          bold: false,
          name: 'Статистика',
          icon: 'pi pi-chart-bar',
          description: 'Аналитика подписок/отписок, срабатывание триггеров, анализ аудитории',
          route_name: 'group_statistics_subscription',
          route_params: {scenario_uuid: this.scenario_uuid, dialog_uuid: this.dialog_uuid},
        },
        {
          bold: false,
          name: 'Часы работы',
          icon: 'pi pi-chart-pie',
          description: 'Запрет писать сообщения в указанный временной диапазон',
          route_name: 'group_work_hours',
          route_params: {scenario_uuid: this.scenario_uuid, dialog_uuid: this.dialog_uuid},
        },
        {
          bold: false,
          name: 'Команды управления ботом',
          icon: 'pi pi-sign-in',
          description: 'Настройка команд ручной модерации /ban, /kick, /warn и т.д',
          route_name: 'commands_settings',
          route_params: {scenario_uuid: this.scenario_uuid, dialog_uuid: this.dialog_uuid},
        },
      ]
    }
  },
  created: function () {
    getGroupsForCard(this.dialog_uuid).then((res) => {
      this.breadcrumbs = [
        new BreadcrumbItem('Telegram группы', 'bot_groups', 'bot_groups', {scenario_uuid: this.scenario_uuid}),
        new BreadcrumbItem(res.name, 'bot_groups', 'bot_groups', {scenario_uuid: this.scenario_uuid, dialog_uuid: this.dialog_uuid}),
      ]
    })
  },
})
</script>