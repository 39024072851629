<template>
  <div class="p-2 gridmap" style="overflow-y: auto; height: 100vh;">
    <Breadcrumbs :items="this.breadcrumbs" class="bg-white"/>

    <HelpInfo :route-doc="null" class="mt-3">
      Здесь отображаются Telegram группы в которых присутствует бот, если группа не отображается - напишите в ней что нибудь, бот увидит сообщение и отобразит группу.
    </HelpInfo>

    <div class="mt-4 bg-white p-2 rounded">
      <div v-for="(group, groupIndex) in this.items" :key="group.uuid" class="content-group">
        <div>
          <img v-if="group.icon_image" :src="group.icon_image" height="60px" class="rounded-circle me-2" alt=""/>
          <h6 class="d-inline-block fw-bold">{{group.name}}</h6>
        </div>

        <div class="mt-1">
          <p class="text-muted m-0">Триггеров: {{group.triggers_count}}</p>
        </div>

        <div class="d-flex justify-content-end flex-wrap">
          <router-link :to="{name: 'group_card', params: {
                  scenario_uuid: this.scenario_uuid,
                  dialog_uuid: group.uuid,
                }
              }"
          >
            <Button
                icon="pi pi-pencil"
                label="Открыть"
                severity="secondary"
                outlined
                class="m-1 rounded"
            />
          </router-link>

          <Button
              icon="pi pi-sign-out"
              label="Выйти"
              :loading="this.leaveDialogUuid === group.uuid"
              severity="warning"
              outlined
              class="m-1 rounded"
              @click="() => {
                    this.leaveDialogUuid = group.uuid

                    leaveGroup(group.uuid)
                      .then(() => { this.items.splice(groupIndex, 1) })
                      .finally(() => { this.leaveDialogUuid = null })
                  }"
          />
        </div>
      </div>

      <p v-if="this.items.length === 0" class="text-muted">Список пуст</p>
    </div>
  </div>
</template>


<script lang="ts">
import {defineComponent, inject} from 'vue'
import Tag from "primevue/tag";
import HelpInfo from "@/components/view/HelpInfo.vue";
import TriggerForm from "@/components/view/Triggers/TriggerForm.vue";
import Dialog from "primevue/dialog";
import Column from "primevue/column";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import {getGroupsForList, GroupsListItemResult, leaveGroup} from "@/api/group";
import Breadcrumb from "primevue/breadcrumb";
import Breadcrumbs from "@/components/common/Breadcrumbs.vue";
import {BreadcrumbItem} from "@/api/breadcrumbs";

export default defineComponent({
  name: "GroupChatList",
  methods: {leaveGroup},
  components: {
    Breadcrumbs,
    DataTable,
    Button,
    Column,
    Dialog,
    TriggerForm,
    HelpInfo,
    Tag,
    Breadcrumb,
  },
  props: {
    scenario_uuid: {
      required: true,
      type: String,
    }
  },
  data: function () {
    return {
      leaveDialogUuid: null as string|null,
      items: [] as GroupsListItemResult[],
      breadcrumbs: [
        new BreadcrumbItem('Telegram группы', 'bot_groups'),
      ],
    }
  },
  created() {
    getGroupsForList(this.scenario_uuid).then((res) => {
      this.items = res.items
    })
  },
})
</script>