import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "p-2 vh-100 gridmap",
  style: {"overflow-y":"auto"}
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "mt-2 p-2 bg-light rounded" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "mt-2 p-2 bg-light rounded" }
const _hoisted_6 = { class: "d-flex justify-content-end m-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!
  const _component_TabMenu = _resolveComponent("TabMenu")!
  const _component_BotCommandItem = _resolveComponent("BotCommandItem")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Breadcrumbs, {
      items: this.breadcrumbs,
      class: "bg-white"
    }, null, 8, ["items"]),
    _createVNode(_component_TabMenu, {
      model: this.itemsTab,
      class: "mt-4 bg-white rounded",
      pt: {action: { class: 'text-decoration-none', style: {color: 'black'} }},
      "active-index": this.activeTabIndex,
      onTabChange: _cache[0] || (_cache[0] = ($event) => { this.activeTabIndex = $event.index })
    }, null, 8, ["model", "active-index"]),
    (this.activeTabIndex === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.card.user_commands, (command, index) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_BotCommandItem, {
                item: this.card.user_commands[index],
                "onUpdate:item": ($event) => { this.card.user_commands[index] = $event }
              }, null, 8, ["item", "onUpdate:item"])
            ]))
          }), 256))
        ]))
      : _createCommentVNode("", true),
    (this.activeTabIndex === 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.card.admin_commands, (command, index) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_BotCommandItem, {
                item: this.card.admin_commands[index],
                "onUpdate:item": ($event) => { this.card.admin_commands[index] = $event }
              }, null, 8, ["item", "onUpdate:item"])
            ]))
          }), 256))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_Button, {
        label: "Сохранить",
        severity: "secondary",
        class: "rounded",
        loading: this.isLoadingSave,
        onClick: _cache[1] || (_cache[1] = () => {
          this.isLoadingSave = true

          _ctx.updateCommandsSettingsCard(this.card)
            .finally(() => { this.isLoadingSave = false })
        })
      }, null, 8, ["loading"])
    ])
  ]))
}