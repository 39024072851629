<template>
  <div class="gridmap vh-100 p-2" style="overflow-y: auto">
    <Breadcrumbs :items="this.breadcrumbs" class="bg-white"/>

    <div>
      <DataTable
          :value="this.list"
          dataKey="uuid"
          :paginator="false"
          :rows="10"
          :rowsPerPageOptions="[5,10,25]"
          class="mt-4 custom-border rounded bg-white"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
      >
        <template #empty>
          Список пуст
        </template>

        <template #header>
          <div class="row justify-content-between">
            <div class="col-auto d-flex align-items-center">
              <h5 class="m-0">Список проверок</h5>
            </div>
            <div class="col-auto">
              <Button
                  class="rounded"
                  label="Добавить проверку"
                  icon="pi pi-plus"
                  @click="this.isOpenDialogCreateTrigger = true"
                  severity="contrast"
              />
            </div>
          </div>
        </template>
        <Column field="name" header="Название" style="min-width:16rem"/>
        <Column field="active" header="Статус" style="min-width:12rem">
          <template #body="slotProps">
            <Tag :value="slotProps.data.active ? 'Активен' : 'Не активен'" :severity="slotProps.data.active ? 'success' : 'danger'" />
          </template>
        </Column>
        <Column field="priority" header="Приоритет срабатывания"  style="min-width:12rem">
          <template #header>
            <span class="pi pi-question-circle me-2" v-tooltip="'Сначала проверяются триггеры с наивысшим приоритетом'"/>
          </template>
        </Column>
        <Column :exportable="false" style="min-width:8rem">
          <template #body="slotProps">
            <div class="d-flex justify-content-end">
              <router-link :to="{name: 'trigger_for_card', params: {scenario_uuid: this.scenario_uuid, uuid: slotProps.data.uuid}}">
                <Button icon="pi pi-pencil" label="Открыть" severity="secondary"  outlined class="me-2 rounded"/>
              </router-link>

              <Button icon="pi pi-trash" label="Удалить" outlined rounded severity="danger" class="rounded" @click="() => {this.selectRemoveScenarioTrigger = slotProps.data}" />
            </div>
          </template>
        </Column>
      </DataTable>

    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import Breadcrumbs from "@/components/common/Breadcrumbs.vue";
import {getDialogJoinRequestSettings} from "@/api/dialogJoinRequestSettings";
import {BreadcrumbItem} from "@/api/breadcrumbs";
import DialogTagPanel from "@/components/view/Forward/DialogTagPanel.vue";
import TriggerItem from "@/components/view/Bots/Trigger/TriggerItem.vue";
import CommandItem from "@/components/view/Bots/Command/CommandItem.vue";
import draggable from "vuedraggable";
import Divider from "primevue/divider";
import Button from "primevue/button";
import Tag from "primevue/tag";
import DataTable from "primevue/datatable";
import Column from "primevue/column";

export default defineComponent({
  name: "FaceControlList",
  components: {
    draggable,
    CommandItem,
    TriggerItem,
    DialogTagPanel,
    Breadcrumbs,
    Divider,
    Button,
    Tag,
    DataTable,
    Column,
  },
  props: {
    scenario_uuid: {
      required: true,
      type: String,
    },
    dialog_uuid: {
      required: true,
      type: String,
    },
  },
  data: function () {
    return {
      breadcrumbs: [],
      requestCreateTrigger: null,
      selectRemoveScenarioTrigger: null as string|null,

      list: [
        {
          uuid: '5c574416-7e95-44d8-a829-5c84b738d025',
          name: 'test',
          active: true,
          priority: 10,
        }
      ],
    }
  },
  created() {
    getDialogJoinRequestSettings(this.dialog_uuid).then((res) => {
      this.card = res

      this.breadcrumbs = [
        new BreadcrumbItem('Telegram группы', 'bot_groups', 'bot_groups', {scenario_uuid: this.scenario_uuid}),
        new BreadcrumbItem(res.name, '', 'group_card', {scenario_uuid: this.scenario_uuid, dialog_uuid: this.dialog_uuid}),
        new BreadcrumbItem('Проверки участников'),
      ]
    })
  },
  computed: {
    isOpenDialogCreateTrigger: {
      set: function (val: boolean) {
          if (val) {
            // todo
            // this.requestCreateTrigger =
          } else {
            this.requestCreateTrigger = null
          }
      },
      get: function (): boolean {
        return this.requestCreateTrigger !== null
      },
    },
  }
})
</script>