<template>
  <div>
    <HelpInfo class="p-2">
      <a href="https://apidocs.bitrix24.ru/local-integrations/local-webhooks.html" target="_blank">Как получить токен для входящего хука</a>
    </HelpInfo>

    <IntegrationForm
      :value="this.card"
      :scenario_uuid="this.scenario_uuid"
      @was-saved="() => {
        this.$router.push({name: 'integrations'})
      }"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import {CreateIntegration, IntegrationType, Bitrix24IntegrationCommand} from "@/api/integration/integration";
import IntegrationForm from "@/components/view/Integration/IntegrationForm.vue";
import HelpInfo from "@/components/view/HelpInfo.vue";

export default defineComponent({
  name: "CreateIntegration",
  components: {HelpInfo, IntegrationForm},
  props: {
    scenario_uuid: {
      type: String,
      required: true,
    },
    integration_type: {
      type: Object as PropType<IntegrationType>,
      required: true,
    },
  },
  data: function () {
    let value

    switch (this.integration_type) {
      case IntegrationType.bitrix24:
        value = new Bitrix24IntegrationCommand(this.scenario_uuid)
        break

      default:
        throw new Error('Обратитесь в тех.поддержку')
    }

    return {
      card: value as CreateIntegration,
    }
  }
})
</script>