<template>
  <div class="bg-primary-subtle m-1 p-1">
    <div class="d-flex justify-content-end align-items-end">
      <button
          type="button"
          class="pi pi-trash border-0 bg-transparent text-danger m-2"
          v-if="this.isEditMode"
          @click="$emit('pressed-button-remove')"
      />
    </div>

    <Dropdown
        :options="this.commandOptions"
        class="w-100"
        option-label="name"
        option-value="value"
        placeholder="Тип действия"
        :disabled="this.readOnly || this.command.type !== null"
        v-model="this.command.type"
        scroll-height="500px"
    />

    <!--    WEB HOOK -->
    <div v-if="this.command.type === CommandType.sendWebHook">
      <div style="display: flex">
        <Dropdown
            v-model="this.command.value.web_hook.method"
            :options="[{name: 'POST'}, {name: 'GET'}, {name: 'PUT'}, {name: 'DELETE'}]"
            :hide-selected=false
            :close-on-select=true
            placeholder="HTTP метод"
            option-label="name"
            option-value="name"
            :disabled="this.readOnly"
        />

        <InputText
            placeholder="URL"
            type="text"
            v-model="this.command.value.web_hook.url"
            :disabled="this.readOnly"
            class="d-inline-block flex-grow-1"
        />
      </div>


      <div v-show="this.command.value.web_hook.method && this.command.value.web_hook.method !== 'GET'">
        <p class="fw-bold mt-4 mb-0">Тело запроса:</p>

        <Textarea
            placeholder="Тело запроса"
            type="text"
            v-model="this.command.value.web_hook.body"
            :disabled="this.readOnly"
            :auto-resize="true"
            class="w-100"
        />
      </div>


<!--      <div class="mt-4">-->
<!--        <p class="m-0 p-0 fw-bold" v-if="!(this.readOnly && this.command.value.web_hook.headers.length === 0)">HTTP заголовки</p>-->

<!--        <div v-for="(header, index) in this.command.value.web_hook.headers" >-->
<!--          <InputText-->
<!--              placeholder="Название заголовка" v-model="header.name" :disabled="this.readOnly"-->
<!--          />-->
<!--          <InputText-->
<!--              placeholder="Значение заголовка" v-model="header.name" :disabled="this.readOnly"-->
<!--          />-->
<!--          <button-->
<!--              class="pi pi-trash border-0 bg-transparent text-danger"-->
<!--              :disabled="this.readOnly"-->
<!--              @click="this.command.value.web_hook.headers.splice(index, 1)"-->
<!--          />-->
<!--        </div>-->

<!--        <Button-->
<!--            icon="pi pi-plus"-->
<!--            class="rounded m-2"-->
<!--            severity="secondary"-->
<!--            label="Добавить"-->
<!--            v-show="!this.readOnly"-->
<!--            @click="this.command.value.web_hook.headers.push(new HttpHeader())"-->
<!--        />-->
<!--      </div>-->

      <div>
        <p class="fw-bold mt-4 mb-0">Запись ответа сервера в переменную (json-path)</p>


        <div v-for="(parseResponse, index) in this.command.value.web_hook.parse_response" style="display: flex;">
          <Dropdown
              v-model="parseResponse.context_dictionary_uuid"
              :options="this.contextOptions"
              placeholder="Переменная"
              option-label="name"
              option-value="uuid"
              :disabled="this.readOnly"
              scroll-height="700px"
              empty-message="Добавьте пользовательскую переменную"
              class="flex-grow-1"
          />

          <InputText
            v-model="parseResponse.json_path_expression"
            :disabled="this.readOnly"
            class="flex-grow-1"
          />

          <button
              class="pi pi-trash border-0 bg-transparent text-danger"
              :disabled="this.readOnly"
              @click="this.command.value.web_hook.parse_response.splice(index, 1)"
          />
        </div>

        <Button
            icon="pi pi-plus"
            class="m-2 rounded"
            label="Добавить"
            severity="secondary"
            v-show="!this.readOnly"
            @click="this.command.value.web_hook.parse_response.push(new ParseResponse())"
        />
      </div>
    </div>

    <!--  CONTEXT  -->
    <div v-if="this.command.type === CommandType.appendContext || this.command.type === CommandType.setContext">
      <Dropdown
          v-if="this.command.value && this.command.value.context"
          v-model="this.command.value.context.code"
          :options="this.contextOptions"
          placeholder="Переменная"
          option-label="name"
          option-value="uuid"
          :disabled="this.readOnly"
          scroll-height="700px"
          class="w-100"
          empty-message="Добавьте пользовательскую переменную"
      />
    </div>

    <!-- TODO: На уровне бека предусмотреть выходы на лимиты типа -->
    <div v-if="this.command.value && this.command.value.context && this.command.value.context.code">
      <InputText
          v-if="this.command.type === CommandType.setContext"
          v-model="this.command.value.context.value"
          class="w-100"
          placeholder="Новое значение переменной"
          :disabled="this.readOnly"
      />

      <InputNumber
          v-if="this.command.type === CommandType.appendContext"
          v-model="this.command.value.context.append"
          class="w-100"
          placeholder="Число которое будет добавлено к переменной"
          :disabled="this.readOnly"
      />
    </div>

    <!--  SEND MAIL  -->
    <div v-if="this.command.type === CommandType.sendMail">
      <input
          class="form-control"
          placeholder="Кому"
          type="text"
          v-model="this.command.value.mail.to"
          :disabled="this.readOnly"
      >

      <input
          class="form-control"
          placeholder="Текст сообщения"
          type="text"
          v-model="this.command.value.mail.content"
          :disabled="this.readOnly"
      >
    </div>

    <div v-if="this.command.type === CommandType.forwardToDialog">
      <SearchDialogMultiple
          v-model="this.command.value.forward_to_dialogs"
          :disabled="this.readOnly"
          dropdown
          class="w-100"
      />
    </div>

    <div v-if="this.command.type === CommandType.addTagToSubscriber">
      <SearchTag
          v-model="this.command.value.add_tag_to_subscriber"
          :disabled="this.readOnly"
          dropdown
          class="w-100"
      />
    </div>

    <div v-if="this.command.type === CommandType.removeTagFromSubscriber">
      <SearchTag
          v-model="this.command.value.remove_tag_from_subscriber"
          :disabled="this.readOnly"
          dropdown
          class="w-100"
      />
    </div>

    <div v-if="this.command.type === CommandType.sendMessage">
      <SearchContext
          :context-options="this.readContextOptions"
          @selected-context="($event) => { this.modelValue.value.send_message.text += $event }"
      />

      <RichTextEditor v-model="this.modelValue.value.send_message.text" class="mt-3"/>

      <div>
        <Checkbox :binary="true" class="align-middle me-2" v-model="this.command.value.send_message.reply_to_origin_message"/>
        <span class="align-middle">Отправить в ответ на сообщение</span>
      </div>

      <div class="mb-2">
        <Checkbox
            :binary="true"
            v-model="this.sendMessageToSameDialog"
            class="me-2"
            @update:model-value="($event) => {
              if ($event) {
                this.command.value.send_message.dialogs = []
              }
            }"
        />
        <span>Отправить сообщение в тот же диалог</span>
      </div>

      <SearchDialogMultiple
          v-show="this.sendMessageToSameDialog === false"
          v-model="this.command.value.send_message.dialogs"
          :disabled="this.readOnly"
          dropdown
          class="w-100"
          placeholder="Куда отправить сообщение"
      />

      <PreviewOption class="mt-2 mb-2" v-model="this.command.value.send_message.preview_option"/>

      <div>
        <div>
          <Checkbox
              :binary="true"
              v-model="this.deleteMessageAfterPeriod"
              class="me-2"
          />
          <span>Удалить сообщение через</span>
        </div>

        <Period
            v-if="this.deleteMessageAfterPeriod"
            class="mt-2"
            :show-all-units="true"
            v-model="this.command.value.send_message.delete_after_period"
        />

        <div>
          <Checkbox
              :binary="true"
              v-model="this.command.value.send_message.disable_notification"
              class="me-2"
          />
          <span>Бесшумная отправка</span>
        </div>
      </div>
    </div>

    <div v-if="this.command.type === CommandType.muteSubscriber">
      <Period
        :show-all-units="true"
        v-model="this.command.value.mute_subscriber.period"
      />
    </div>

    <div v-if="this.command.type === CommandType.bitrix24CreateDeal">
      <Dropdown
          v-model="this.command.value.bitrix24_create_deal.integration_uuid"
          option-label="name"
          option-value="uuid"
          :options="this.graphConfigStorage.getIntegrations.bitrix24"
          :disabled="this.readOnly"
          placeholder="Выберите подключение"
          class="w-100"
          @change="() => {
            this.command.value.bitrix24_create_deal.deal_type_uuid = null
            this.command.value.bitrix24_create_deal.deal_stage_uuid = null
          }"
      />

      <InputText
          v-model="this.command.value.bitrix24_create_deal.name"
          class="w-100"
          placeholder="Название сделки"
          :disabled="this.readOnly"
      />

      <div v-if="this.selectedBitrix24Integration !== null">
        <Dropdown
            v-model="this.command.value.bitrix24_create_deal.deal_type_uuid"
            option-label="name"
            option-value="uuid"
            :options="this.selectedBitrix24Integration.deal_types"
            :disabled="this.readOnly"
            placeholder="Выберите тип сделки"
            class="w-100"
        />

        <Dropdown
            v-model="this.command.value.bitrix24_create_deal.deal_stage_uuid"
            option-label="name"
            option-value="uuid"
            :options="this.selectedBitrix24Integration.deal_stages"
            :disabled="this.readOnly"
            placeholder="Выберите стадию сделки"
            class="w-100"
        />

        <div>
          <p class="fw-bold mt-2 mb-0">Пользовательские поля</p>


          <div v-for="(userField, index) in this.command.value.bitrix24_create_deal.user_fields" class="d-flex flex-wrap">
            <Dropdown
                v-model="userField.context_dictionary_uuid"
                :options="this.contextOptions"
                placeholder="Переменная"
                option-label="name"
                option-value="uuid"
                :disabled="this.readOnly"
                scroll-height="700px"
                empty-message="Добавьте пользовательскую переменную"
                class="flex-grow-1"
            />

            <Dropdown
                v-model="userField.bitrix24_user_field_uuid"
                :options="this.selectedBitrix24Integration.user_fields"
                placeholder="Битрикс поле"
                option-label="name"
                option-value="uuid"
                :disabled="this.readOnly"
                scroll-height="700px"
                empty-message="Добавьте пользовательскую переменную"
                class="flex-grow-1"
            />

            <button
                class="pi pi-trash border-0 bg-transparent text-danger"
                :disabled="this.readOnly"
                @click="this.command.value.bitrix24_create_deal.user_fields.splice(index, 1)"
            />
          </div>

          <Button
              icon="pi pi-plus"
              class="m-2 rounded"
              label="Добавить"
              severity="secondary"
              v-show="!this.readOnly"
              @click="this.command.value.bitrix24_create_deal.user_fields.push(new Bitrix24CreateDealMapUserField())"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  Bitrix24CreateDealItem, Bitrix24CreateDealMapUserField,
  ButtonType,
  Command,
  CommandType,
  CommandValue,
  CommandWebHookItem,
  ContextCommandItem,
  GraphConfigCommandType,
  HttpHeader,
  MuteSubscriberItem,
  ParseResponse,
  SendMessageValue,
  TimeUnit
} from "@/api/graph";
import {SendMailItem} from "@/api/command";
import {defineComponent, PropType} from "vue";
import Dropdown, {DropdownChangeEvent} from "primevue/dropdown";
import {ContextItemForBuilder, ContextType} from "@/api/context";
import InputNumber from "primevue/inputnumber";
import InputText from "primevue/inputtext";
import SearchDialogMultiple from "@/components/common/SearchDialogMultiple.vue";
import SearchTag from "@/components/common/SearchTag.vue";
import Textarea from "primevue/textarea";
import AutoComplete from "primevue/autocomplete";
import Button from "primevue/button";
import {useGraphConfigStorage} from "@/stores/GraphConfigStorage";
import RichTextEditor from "@/components/view/RichTextEditor.vue";
import Checkbox from "primevue/checkbox";
import ButtonGroupPanel from "@/components/view/ButtonGroupPanel.vue";
import {ButtonGroupType} from "@/api/buttonGroupPanel";
import PreviewOption from "@/components/common/PreviewOption.vue";
import SearchContext from "@/components/common/SearchContext.vue";
import {Period as PeriodDto} from "@/api/periodicMessage";
import Period from "@/components/common/Period.vue";
import {ExtendedBitrix24IntegrationItem} from "@/api/integration/integration";

export default defineComponent({
  name: 'CommandItem',
  components: {
    Period,
    SearchContext,
    PreviewOption,
    ButtonGroupPanel,
    RichTextEditor,
    Button,
    SearchTag,
    SearchDialogMultiple,
    Dropdown,
    InputNumber,
    InputText,
    Textarea,
    AutoComplete,
    Checkbox,
  },
  emits: [
      'pressed-button-remove',
      'completed-filled-command-item',
  ],
  props: {
    isEditMode: {
      type: Boolean,
      required: true,
    },
    uuid: {
      type: String,
      required: false,
    },
    modelValue: {
      type: Object as PropType<Command>,
      required: true,
    },
    readOnly: {
      type: Boolean,
      required: true,
    },
    contextOptions: {
      required: true,
      type: Array as PropType<ContextItemForBuilder[]>,
    },
    readContextOptions: {
      required: true,
      type: Array as PropType<ContextItemForBuilder[]>,
    },
    commandOptions: {
      required: true,
      type: Array as PropType<GraphConfigCommandType[]>,
    }
  },
  inject: [
      'scenario_uuid',
  ],
  data: function () {
    return {
      command: this.modelValue as Command,

      sendMessageToSameDialog: this.modelValue.value?.send_message?.dialogs.length === 0,
    }
  },
  computed: {
    Bitrix24CreateDealMapUserField() {
      return Bitrix24CreateDealMapUserField
    },
    selectedBitrix24Integration: function (): ExtendedBitrix24IntegrationItem|null {
      let result = null

      this.graphConfigStorage.getIntegrations.bitrix24.forEach((bitrix24Integration) => {
        if (bitrix24Integration.uuid === this.command.value.bitrix24_create_deal.integration_uuid) {
          result = bitrix24Integration
        }
      })

      return result
    },

    ButtonGroupType() {
      return ButtonGroupType
    },
    ButtonType() {
      return ButtonType
    },
    ParseResponse() {
      return ParseResponse
    },
    ContextType() {
      return ContextType
    },
    HttpHeader() {
      return HttpHeader
    },
    CommandType() {
      return CommandType
    },
    deleteMessageAfterPeriod: {
      get: function () {
        return this.command.value.send_message.delete_after_period !== null
      },
      set: function (val) {
        if (val) {
          this.command.value.send_message.delete_after_period = new PeriodDto(5, TimeUnit.minute)
        } else {
          this.command.value.send_message.delete_after_period = null
        }
      },
    },

    isShowButtonCreate: function () {
      if (this.command.type === CommandType.sendWebHook) {
        return this.command.value.web_hook!.url && this.command.value.web_hook!.method;
      }

      if (this.command.type === CommandType.sendMail) {
        return this.command.value.mail!.to && this.command.value.mail!.content
      }

      if (
          this.command.type === CommandType.appendContext &&
          this.command!.value.context.append !== null
      ) {
        return true
      }

      if (
          this.command.type === CommandType.setContext &&
          !this.command!.value.context.code
      ) {
        return true
      }

      if (
          this.command.type === CommandType.forwardToDialog &&
          this.command.value.forward_to_dialogs.length !== 0
      ) {
        return true
      }

      if (
          this.command.type === CommandType.markNeedAttention
      ) {
        return true
      }

      if (
          this.command.type === CommandType.removeMessage
      ) {
        return true
      }

      if (
          this.command.type === CommandType.pinMessage
      ) {
        return true
      }

      if (
          this.command.type === CommandType.unpinAllMessages
      ) {
        return true
      }

      if (
          this.command.type === CommandType.addTagToSubscriber &&
          this.command.value.add_tag_to_subscriber !== null
      ) {
        return true
      }

      if (
          this.command.type === CommandType.removeTagFromSubscriber &&
          this.command.value.remove_tag_from_subscriber !== null
      ) {
        return true
      }

      return false
    },
  },
  watch: {
    isShowButtonCreate: function () {
      this.$emit('completed-filled-command-item')
    },
  },
  setup() {
    const graphConfigStorage = useGraphConfigStorage()
    return {graphConfigStorage}
  },
})
</script>