<template>
  <div>
    <MailingForm
        :scenario_uuid="this.scenario_uuid"
        :mailing="this.mailing"
        @mailing:update="($event) => { this.mailing = $event }"
        @saved="() => {
          this.$router.push({name: 'mailing'})
        }"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import MailingForm from "@/components/view/Mailing/MailingForm.vue";
import {createMailing, MailingCreate} from "@/api/mailing";

export default defineComponent({
  name: "MailingCreate",
  methods: {createMailing},
  components: {MailingForm},
  props: {
    scenario_uuid: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      mailing: new MailingCreate(this.scenario_uuid),
    }
  },
})
</script>