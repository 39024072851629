import {AxiosRequestConfig, AxiosResponse} from "axios";
import {Edge, GraphConfigResult, GraphResult, NodePosition} from "@/api/graph";
import {plainToClass, plainToInstance, Type} from "class-transformer";
import client from "./client";
import {DialogType} from "./dialog";
import {AddTagToSubscriber} from "./command";

export function createEdge(command: Edge): Promise<Edge> {
    // @ts-ignore
    return client
        .post('/api/v1/edge/create', command)
        .then((response: AxiosResponse) => {
            return plainToClass(Edge, response.data);
        });
}

export function removeElements(scenarioUuid: string, edgeUuids: string[], nodesUuids: string[]): Promise<GraphResult> {
    const cmd = {
        scenario_uuid: scenarioUuid,
        edge_uuids: edgeUuids,
        node_uuids: nodesUuids,
    }

    // @ts-ignore
    return client
        .post('/api/v1/edge/graph/remove-elements', cmd)
        .then((response: AxiosResponse) => {
            // Конвертирование тела ответа в DTO
            return plainToClass(GraphResult, response.data);
        })
}

export function updateNodePositionAndName(
    nodeUuid: string,
    position: NodePosition,
    name: string,
): Promise<AxiosRequestConfig> {
    return client
        .post('/api/v1/edge/node/update', {
            uuid: nodeUuid,
            position: position,
            name: name,
        })
}

export class SearchTagRes
{
    tags: AddTagToSubscriber[] = []
}

export class SearchDialogRes
{
    @Type(() => SearchDialogItem)
    dialogs: SearchDialogItem[] = []
}

export class DialogTopic
{
    uuid: string
    name: string
}

export class SearchDialogItem
{
    uuid: string
    name: string
    icon_image: string|null = null
    type_print: string

    selected_topic_uuid: string|null = null

    @Type(() => DialogTopic)
    topics: DialogTopic[] = []
}

export function searchDialogByName(search_name: string, scenario_uuid: string, dialogTypes: DialogType[]): Promise<SearchDialogRes>
{
    // @ts-ignore
    return client
        .post('/api/v1/edge/search-dialog', {
            search_name: search_name,
            scenario_uuid: scenario_uuid,
            dialog_types: dialogTypes,
        })
        .then((response: AxiosResponse) => {
            // Конвертирование тела ответа в DTO
            return plainToInstance(SearchDialogRes, response.data);
        })
}


export class SearchSubscriberItem
{
    uuid: string
    name: string
}

export class SearchSubscriberRes
{
    items: SearchSubscriberItem[] = []
}

export function searchSubscriberByName(search_name: string, scenario_uuid: string): Promise<SearchSubscriberRes>
{
    // @ts-ignore
    return client
        .post('/api/v1/edge/search-subscriber', {
            search_name: search_name,
            scenario_uuid: scenario_uuid,
        })
        .then((response: AxiosResponse) => {
            // Конвертирование тела ответа в DTO
            return plainToClass(SearchSubscriberRes, response.data);
        })
}

export function searchTagsByName(search_name: string, scenario_uuid: string): Promise<SearchTagRes>
{
    // @ts-ignore
    return client
        .post('/api/v1/edge/search-tag', {
            search_name: search_name,
            scenario_uuid: scenario_uuid,
        })
        .then((response: AxiosResponse) => {
            // Конвертирование тела ответа в DTO
            return plainToClass(SearchTagRes, response.data);
        })
}

export class GetConfigQuery
{
    scenario_uuid: string

    constructor(scenario_uuid: string) {
        this.scenario_uuid = scenario_uuid;
    }
}

export function getConfig(scenarioUuid: string): Promise<GraphConfigResult>
{
    return client
        .post('/api/v1/edge/get-config', new GetConfigQuery(scenarioUuid))
        .then((response: AxiosResponse) => {
            return plainToInstance(GraphConfigResult, response.data);
        })
}