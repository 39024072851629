import client from "./client";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import {plainToInstance, Type} from "class-transformer";
import {SubscriberForListItem} from "./subscriber";
import {PreviewLinkOption} from "./post";
import {Trigger} from "./graph";


export class SegmentationResult
{
    @Type(() => SubscriberForListItem)
    subscribers: SubscriberForListItem[] = []
}

export class SegmentationQuery
{
    scenario_uuid: string

    @Type(() => Trigger)
    triggers: Trigger[] = []

    constructor(scenario_uuid: string) {
        this.scenario_uuid = scenario_uuid;
    }
}

export function getSegmentationSubscribers(query: SegmentationQuery): Promise<SegmentationResult>
{
    return client
        .post('/api/v1/mailing/segmentation-subscribers', query)
        .then((response: AxiosResponse) => {
            return plainToInstance(SegmentationResult, response.data);
        })
}

export class MailingCreate
{
    scenario_uuid: string

    @Type(() => SubscriberForListItem)
    subscribers: SubscriberForListItem[] = []

    files: []
    text: string = ''
    preview_option: PreviewLinkOption = PreviewLinkOption.withoutPreview
    protected: false

    name: string
    sent_at: string

    constructor(scenario_uuid: string) {
        let ahead7days = new Date();
        ahead7days.setDate(ahead7days.getDate() + 7).toString();

        this.sent_at = ahead7days
        this.scenario_uuid = scenario_uuid;
    }
}

export class MailingUpdate extends MailingCreate
{
    uuid: string

    constructor(scenario_uuid: string, uuid: string) {
        super(scenario_uuid);
        this.uuid = uuid;
    }
}

export class ResultMailingItem
{
    uuid: string
    name: string
    sent_at: string
    subscribers_count: number
    is_archive: boolean = false
}

export class ResultMailingGetForList
{
    @Type(() => ResultMailingItem)
    items: ResultMailingItem[] = []
}

export function getMailingForList(scenarioUuid: string): Promise<ResultMailingGetForList>
{
    return client
        .post('/api/v1/mailing/get-for-list', {scenario_uuid: scenarioUuid})
        .then((response: AxiosResponse) => {
            return plainToInstance(ResultMailingGetForList, response.data);
        })
}

export function getMailingForCard(mailingUuid: string): Promise<MailingUpdate>
{
    return client
        .post('/api/v1/mailing/get-for-card', {uuid: mailingUuid})
        .then((response: AxiosResponse) => {
            return plainToInstance(MailingUpdate, response.data);
        })
}

export function createMailing(cmd: MailingCreate): Promise<AxiosRequestConfig>
{
    return client
        .post('/api/v1/mailing/create', cmd)
}

export function updateMailing(cmd: MailingUpdate): Promise<AxiosRequestConfig>
{
    return client
        .post('/api/v1/mailing/update', cmd)
}

export function removeMailing(mailingUuid: string): Promise<AxiosRequestConfig>
{
    return client
        .post('/api/v1/mailing/remove', {uuid: mailingUuid})
}