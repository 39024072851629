import {SourceItemResult} from "./forwarder";
import {AxiosRequestConfig, AxiosResponse, post} from "axios";
import client from "./client";
import {NodeFile, TimeUnit, UploadFileResult} from "./graph";
import {plainToClass, Type} from "class-transformer";
import {uuid} from "vue-uuid";
import {SearchDialogItem, SearchSubscriberItem} from "./edge";
import {ButtonRow} from "./buttonGroupPanel";

export enum PostType
{
    publishToOneOrSeveralChannels = 'publish_to_one_or_several_channels',
    publishToOneAndForwardToSeveralChannels = 'publish_to_one_and_forward_to_several_channels',
}

export class PostPublication
{
    one_time: OneTimePublication|null    = null
    range: RangePublication|null         = null
    iteration: IterationPublication|null = null
    queue: QueuePublication|null         = null
    timezone: string

    constructor(one_time: OneTimePublication | null, range: RangePublication | null, iteration: IterationPublication | null, queue: QueuePublication|null) {
        this.one_time = one_time;
        this.range = range;
        this.iteration = iteration;
        this.queue = queue
        this.timezone = '+03:00' // todo: временный хардкод, надо брать в качестве дефолтного значения пояс из сценария
    }
}

export class OneTimePublication
{
    date: string = ''
}

export class RangePublication
{
    start_at: string
    end_at: string|null = null
    interval_value: number
    interval_unit: TimeUnit

    // Дни недели
    day1: boolean = true;
    day2: boolean = true;
    day3: boolean = true;
    day4: boolean = true;
    day5: boolean = true;
    day6: boolean = true;
    day7: boolean = true;

    constructor() {
        this.start_at = '';
        this.interval_value = 15;
        this.interval_unit = TimeUnit.minute;
    }
}

export class IterationPublication
{
    start_at: string
    interval_value: number
    interval_unit: TimeUnit
    iteration_count: number

    // Дни недели
    day1: boolean = true;
    day2: boolean = true;
    day3: boolean = true;
    day4: boolean = true;
    day5: boolean = true;
    day6: boolean = true;
    day7: boolean = true;

    constructor() {
        this.start_at = ''
        this.interval_value = 15
        this.interval_unit = TimeUnit.minute
        this.iteration_count = 5
    }
}

export class QueuePublication
{
    queue_uuid: string
    queue_name: string

    constructor(queue_uuid: string = '') {
        this.queue_uuid = queue_uuid;
    }
}

export enum PublishType
{
    oneTime = 'one_time',
    range = 'range',
    iteration = 'iteration',
    queue = 'queue',
}

export enum PostFormat
{
    native = 'native',
    markDown = 'markdown_v2',
    html = 'html',
}

export class DeletePostDateTime
{
    datetime: string = ''
}

export class DeletePostTime
{
    time: string = ''

    // Дни недели
    day1: boolean = true;
    day2: boolean = true;
    day3: boolean = true;
    day4: boolean = true;
    day5: boolean = true;
    day6: boolean = true;
    day7: boolean = true;
}

export class DeletePostValue
{
    value: number
    time_unit: TimeUnit = TimeUnit.minute
}

export class DeletePostAfter
{
    after_datetime: DeletePostDateTime|null = null
    after_time: DeletePostTime|null = null
    after_value: DeletePostValue|null = null

    constructor(after_datetime: DeletePostDateTime | null, after_time: DeletePostTime | null, after_value: DeletePostValue | null) {
        this.after_datetime = after_datetime;
        this.after_time = after_time;
        this.after_value = after_value;
    }
}

export enum DeleteType
{
    afterDateTime = 'after_date_time',
    afterTime = 'after_time',
    afterValue = 'after_value',
    none = 'none'
}

export class UnpinAfter extends DeletePostAfter
{
}

export class PinPost
{
    unpin: UnpinAfter|null = null
}

export class PostButtonsRow
{
    items: PostButton[] = [];

    constructor(items: PostButton[]) {
        this.items = items;
    }
}

export class PostButton
{
    uuid: string = ''
    text: string
    url: string

    constructor(text: string) {
        this.text = text;
        this.uuid = uuid.v4();
    }
}

export class NotificationSubscribers
{
    subscribers: SearchSubscriberItem[] = []
    text: string = ''
}

export class CreatePostCmd
{
    name: string = ''
    scenario_uuid: string
    text: string = ''
    preview_option: PreviewLinkOption = PreviewLinkOption.belowText

    type: PostType = PostType.publishToOneOrSeveralChannels

    @Type(() => SourceItemResult)
    sent_to_dialogs: SourceItemResult[] = []

    @Type(() => SourceItemResult)
    forward_post_to_dialogs: SourceItemResult[] = []

    disable_notification: boolean = false

    protect_content: boolean = false

    @Type(() => PinPost)
    pin: PinPost|null = null

    @Type(() => DeletePostAfter)
    delete: DeletePostAfter|null = null

    @Type(() => PostPublication)
    publication: PostPublication

    format: PostFormat

    @Type(() => ButtonRow)
    buttons: ButtonRow[] = []

    @Type(() => NotificationSubscribers)
    notification_subscribers: NotificationSubscribers

    @Type(() => NodeFile)
    files: NodeFile[] = []

    constructor(scenario_uuid: string) {
        this.scenario_uuid = scenario_uuid;
        this.format = PostFormat.html
        this.publication = new PostPublication(new OneTimePublication(), null, null)
        this.notification_subscribers = new NotificationSubscribers()
    }
}

export enum PreviewLinkOption
{
    aboveText = 'above_text',
    belowText = 'below_text',
    withoutPreview = 'without_preview',
}

export class UpdatePostCmd extends CreatePostCmd
{
    uuid: string
}

export function updatePostContent(cmd: UpdatePostCmd): Promise<AxiosRequestConfig>
{
    return client.post('/api/v1/post/update', cmd)
}

export function createPostContent(cmd: CreatePostCmd): Promise<AxiosRequestConfig>
{
    return client.post('/api/v1/post/create', cmd)
}

export class PostsForListResult
{
    items: PostForListItem[] = []
}

export class PostForListItem
{
    uuid: string
    text: string
    name: string
    sent_counter: string
    next_sent_will_be_at: string|null = null
    last_sent_will_be_at: string|null = null
}

export function getPostsForList(scenarioUuid: string): Promise<PostsForListResult>
{
    // @ts-ignore
    return client
        .post('/api/v1/post/get-for-list', {scenario_uuid: scenarioUuid})
        .then((response: AxiosResponse) => {
            return plainToClass(PostsForListResult, response.data);
        })
}

export class PostGetForCard extends UpdatePostCmd
{
}

export function getPostForCard(postUuid: string): Promise<PostGetForCard>
{
    // @ts-ignore
    return client
        .post('/api/v1/post/get-for-card', {post_uuid: postUuid})
        .then((response: AxiosResponse) => {
            return plainToClass(PostGetForCard, response.data);
        })
}

export function deletePostContent(postUuid: string): Promise<AxiosRequestConfig>
{
    return client.post('/api/v1/post/remove', {post_uuid: postUuid})
}

export class PostForCalendar
{
    uuid: string
    name: string
    datetime: string
    post_uuid: string
}

export class PostsForCalendar
{
    items: PostForCalendar[] = []
}

export class GetPostsForCalendarQuery
{
    scenario_uuid: string
    from_at: Date

    constructor(scenario_uuid: string, from_at: Date) {
        this.scenario_uuid = scenario_uuid;
        this.from_at = from_at;
    }
}

export function getPostsForCalendar(query: GetPostsForCalendarQuery): Promise<PostsForCalendar>
{
    return client
        .post('/api/v1/post/get-for-calendar', query)
        .then((response: AxiosResponse) => {
            return plainToClass(PostsForCalendar, response.data);
        })
}

export class SendPreviewPostCmd extends CreatePostCmd
{
    subscriber_uuid: string
}

export function sendPreviewPost(cmd: SendPreviewPostCmd): Promise<AxiosRequestConfig>
{
    return client
        .post('/api/v1/post/preview', cmd);
}

export class UploadRes
{
    items: NodeFile[] = []
}